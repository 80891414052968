import React from 'react'
import { Controller } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { FaCheck, FaPlus } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import DividerSection from '@/components/DividerSection'
import { putDoctorCentres } from '@/services/doctorCentres'
import { INFO_USER_CENTRE_ADMIN_SELECTED } from '@/store/centres'
import { showToast } from '@/utils/toast'
import { FormControlLabel, Grid } from '@mui/material'
import Switch from '@mui/material/Switch'

import s from './styles.module.scss'

const InfoUser = () => {
  const dispatch = useDispatch()
  const { infoUserInCentreAdmin } = useSelector((state) => state?.centres)
  const { control } = useForm()

  const handleSwitchChange = async (doctor, newValue) => {
    const updatedDoctor = {
      ...doctor,
      isDoctorNumberValidated: newValue,
    }

    const updatedDoctorCentres = infoUserInCentreAdmin.doctorCentres.map((doctor) => {
      if (doctor._id === updatedDoctor._id) {
        doctor = updatedDoctor
      }
      return doctor
    })

    const updatedUserCentreInfo = {
      ...infoUserInCentreAdmin,
      doctorCentres: updatedDoctorCentres,
    }

    try {
      await putDoctorCentres(updatedDoctor)
      await dispatch(INFO_USER_CENTRE_ADMIN_SELECTED(updatedUserCentreInfo))
      showToast('Profesional actualizado correctamente', 'success')
    } catch (e) {
      showToast('Se produjo un error', 'error')
    }
  }

  return (
    <div className={s.mainContainer}>
      <div className={s.itemContainer}>
        <DividerSection label={'Cuenta administradora'} />
        <p className={s.detailItem}>
          <span className={s.username}>
            {infoUserInCentreAdmin?.user?.lastname} {infoUserInCentreAdmin?.user?.firstname}
          </span>
          <span> ({infoUserInCentreAdmin?.user?._id})</span>
        </p>
        <p className={s.detailItem}>
          <span>{infoUserInCentreAdmin?.user?.email}</span>
          <span>
            {' '}
            - Tel: {infoUserInCentreAdmin?.user?.mobile ? infoUserInCentreAdmin?.user?.mobile : 'Sin teléfono'}
          </span>
        </p>
        <p className={s.detailItem}>
          Roles:{' '}
          {infoUserInCentreAdmin?.user?.roles?.length ? (
            <span>
              [
              {infoUserInCentreAdmin?.user?.roles.map((rol, i) => (
                <span key={rol}>
                  {rol}
                  {i < infoUserInCentreAdmin?.user?.roles?.length - 1 ? ', ' : ''}
                </span>
              ))}
              ]
            </span>
          ) : (
            '[]'
          )}
        </p>
        <p className={s.detailItem}>
          Centres:{' '}
          {infoUserInCentreAdmin?.user?.centres?.length ? (
            <span>
              [
              {infoUserInCentreAdmin?.user?.centres.map((centre, i) => (
                <span key={centre}>
                  '{centre}' {i < infoUserInCentreAdmin?.user?.centres?.length - 1 ? ', ' : ''}
                </span>
              ))}
              ]
            </span>
          ) : (
            '[]'
          )}
        </p>
        <p className={s.detailItem}>
          doctorCentres:{' '}
          {infoUserInCentreAdmin?.user?.doctorCentres?.length ? (
            <span>
              [
              {infoUserInCentreAdmin?.user?.doctorCentres.map((doctor, i) => (
                <span key={doctor}>
                  '{doctor}' {i < infoUserInCentreAdmin?.user?.doctorCentres?.length - 1 ? ', ' : ''}
                </span>
              ))}
              ]
            </span>
          ) : (
            '[]'
          )}
        </p>
        <p className={s.detailItem}>
          secretaryCentres:{' '}
          {infoUserInCentreAdmin?.user?.secretaryCentres?.length ? (
            <span>
              [
              {infoUserInCentreAdmin?.user?.secretaryCentres.map((secretary, i) => (
                <span key={secretary}>
                  '{secretary}' {i < infoUserInCentreAdmin?.user?.secretaryCentres?.length - 1 ? ', ' : ''}
                </span>
              ))}
              ]
            </span>
          ) : (
            '[]'
          )}
        </p>
      </div>
      <div className={s.itemContainer}>
        <DividerSection label={`Profesionales asociados (${infoUserInCentreAdmin?.doctorCentres?.length})`} />

        {infoUserInCentreAdmin?.doctorCentres?.length > 0 &&
          infoUserInCentreAdmin?.doctorCentres?.map((doctor) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <p key={doctor._id} className={s.detailInsideItem}>
                  <span className={s.contIcon}>
                    {doctor?.userActivation ? <FaCheck className={s.iconTrue} /> : <FaPlus className={s.iconFalse} />}
                  </span>{' '}
                  <span className={s.username}>
                    {doctor?.lastname} {doctor.firstname}
                  </span>
                  <span> - ({doctor._id}) </span>
                  {doctor?.email && <span> - {doctor?.email}</span>}
                  <span> - Cel: {doctor?.mobile ? doctor?.mobile : 'Sin celular'}</span>
                  <span> - Tel: {doctor?.telephone ? doctor?.telephone : 'Sin teléfono'}</span>
                </p>
              </div>
              {!!doctor.doctorNumber && (
                <Grid container className={s.doctorNumberContainer}>
                  <Grid xs={4} className={s.doctorNumberItem}>
                    <b className={s.tabB}>{`Matricula ${doctor?.doctorNumberType}: `}</b> {doctor.doctorNumber}
                    {doctor?.doctorNumberProvince && `(${doctor?.doctorNumberProvince})`}
                  </Grid>

                  <Grid xs={4} className={s.doctorNumberItem}>
                    <b className={s.tabB}>{`Firma: `}</b> {!!doctor?.signImage ? 'SI' : 'NO '}
                  </Grid>

                  <Grid xs={4} className={s.doctorNumberItem}>
                    <Controller
                      name={`doctor_${doctor._id}_isDoctorNumberValidated`}
                      control={control}
                      defaultValue={doctor.isDoctorNumberValidated}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              {...field}
                              checked={field.value}
                              onChange={(e) => {
                                const newValue = e.target.checked
                                field.onChange(newValue)
                                handleSwitchChange(doctor, newValue)
                              }}
                            />
                          }
                          label={field.value ? 'Deshabilitar' : 'habilitar'}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              <DividerSection />
            </div>
          ))}
      </div>

      <div className={s.itemContainer}>
        <DividerSection label={`Asistentes asociados (${infoUserInCentreAdmin?.secretaryCentres?.length})`} />

        {infoUserInCentreAdmin?.secretaryCentres?.length > 0 &&
          infoUserInCentreAdmin?.secretaryCentres?.map((secretary) => (
            <p key={secretary._id} className={s.detailInsideItem}>
              <span className={s.contIcon}>
                {secretary?.userActivation ? <FaCheck className={s.iconTrue} /> : <FaPlus className={s.iconFalse} />}
              </span>{' '}
              <span className={s.username}>
                {secretary?.lastname} {secretary.firstname}
              </span>
              <span> - ({secretary._id}) </span>
              {secretary?.email && <span> - {secretary?.email}</span>}
              <span> - Cel: {secretary?.mobile ? secretary?.mobile : 'Sin celular'}</span>
              <span> - Tel: {secretary?.telephone ? secretary?.telephone : 'Sin teléfono'}</span>
            </p>
          ))}
      </div>

      <div className={s.itemContainer}>
        <DividerSection label={`Agendas asociadas (${infoUserInCentreAdmin?.machines?.length})`} />

        {infoUserInCentreAdmin?.machines?.length > 0 &&
          infoUserInCentreAdmin?.machines?.map((machine) => (
            <p key={machine._id} className={s.detailInsideItem}>
              <span className={s.username}>{machine?.name}</span>
              <span> - ({machine._id}) </span>
            </p>
          ))}
      </div>

      <div className={s.itemContainer}>
        <DividerSection label={`Servicios asociados  (${infoUserInCentreAdmin?.services?.length})`} />

        {infoUserInCentreAdmin?.services?.length > 0 &&
          infoUserInCentreAdmin?.services?.map((service) => (
            <p key={service._id} className={s.detailInsideItem}>
              <span className={s.username}>{service?.name}</span>
              <span> - ({service._id}) </span>
            </p>
          ))}
      </div>
    </div>
  )
}

export default InfoUser
