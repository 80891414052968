import React from 'react'
import { useWatch } from 'react-hook-form'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { CheckboxField } from '@/components/Form/customFields/CheckboxField'
import { SelectField } from '@/components/Form/customFields/SelectField'
import { TextEditorField } from '@/components/Form/customFields/TextEditorField'
import { CaluFormAutoComplete } from '@/components/formComponents/customFields/CaluFormAutoComplete'
import CaluButton from '@/components/styledComponents/CaluButton'
import { scopeAdmin } from '@/helpers/constants'
import { getAllUsers } from '@/store/users/actions'
import { Grid, MenuItem } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, onClose }) => {
  const checkBoxColor = '#f8ac59'

  const allUsers = useSelector((state) => state?.users?.allUsers)

  const watchVisible = useWatch({ name: 'visible' })
  const watchMessage = useWatch({ name: 'message' })
  const watchScope = useWatch({ name: 'scope' })
  const watchUsers = useWatch({ name: 'users' })

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <Grid container spacing={2} className={s.containerForm}>
        <Grid item xs={12} className={s.subTitle}>
          Datos de la notificación
        </Grid>

        <Grid item xs={12}>
          <SelectField label="¿Quién la ve?" name="scope" type="text" fullWidth>
            {scopeAdmin.map((code) => (
              <MenuItem key={code.value} value={code.value}>
                {code.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        {watchScope === 'userOnly' && (
          <Grid item xs={12}>
            <CaluFormAutoComplete
              name="users"
              multiple={true}
              stateredux={allUsers}
              functiontodispatch={getAllUsers}
              inputlabel="Buscar usuarios"
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <div className={s.containerCheckbox}>
            <CheckboxField label="¿Visible?" name="visible" checkBoxColor={checkBoxColor} />
            {watchVisible ? (
              <span className={s.spanCheckboxYes}>
                <i>
                  <AiFillCheckCircle /> Si
                </i>
              </span>
            ) : (
              <span className={s.spanCheckboxNo}>
                <i>
                  <AiFillCloseCircle /> No
                </i>
              </span>
            )}
          </div>
        </Grid>

        <Grid item xs={12} className={s.subTitle2}>
          Mensaje de la notificación
        </Grid>
        <Grid item xs={12}>
          <TextEditorField name={'message'} />
        </Grid>

        <Grid item xs={12} className={s.containerButtonsForm}>
          <CaluButton
            color="primary"
            type="submit"
            size="medium"
            disabled={!watchMessage || (watchScope === 'userOnly' && !watchUsers?.length)}
          >
            Confirmar
          </CaluButton>
        </Grid>
      </Grid>
    </div>
  )
}
