import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import LoaderCircle from '@/components/LoaderCircle'
import useDebounce from '@/hooks/useDebounce'
import {
  deleteNotificationAdded,
  getAdminNotificationsBySearch,
  getUserNotifications,
  resetSelectedNotification,
} from '@/store/notifications/actions'

import MainPopUp from './components/MainPopup'
import NotificationSearchBar from './components/NotificationSearchBar'
import NotificacionsTable from './components/NotificationsTable'
import Layout1 from '../../layouts/Layout1/index'

import s from './styles.module.scss'

const AdminNotificationsPage = () => {
  const dispatch = useDispatch()

  const [openNotificationPopUp, setOpenNotificationPopUp] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selectedValue, setSelectedValue] = useState('patients')
  const [isLoading, setIsLoading] = useState(true)
  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const { notifications, notificationSelected } = useSelector((state) => state.notifications)

  const DELAY_TIME = 300
  const MIN_CHARACTERS = 10
  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)

  useEffect(() => {
    inputSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue, selectedValue])

  const inputSearch = async () => {
    if (inputDebounceValue?.length >= MIN_CHARACTERS) {
      await dispatch(getAdminNotificationsBySearch(inputDebounceValue))
      setIsLoading(false)
    } else {
      await dispatch(getUserNotifications({ role: 'admin', scope: selectedValue, page: 1 }))
      setIsLoading(false)
    }
  }

  const addNewNotification = () => {
    dispatch(resetSelectedNotification())
    setOpenNotificationPopUp(!openNotificationPopUp)
  }

  const deleteNotification = () => {
    dispatch(deleteNotificationAdded(notificationSelected?._id))
    setOpenAlertDelete(false)
  }

  const handleExtraFunction = async () => {
    await dispatch(resetSelectedNotification())
  }

  return (
    <Layout1 navbar>
      <div className={`${s.animatedContainer} animate__animated animate__fadeIn`}>
        <NotificationSearchBar
          addNewNotification={addNewNotification}
          inputSearch={searchText}
          setInputSearch={setSearchText}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
        {isLoading ? (
          <LoaderCircle size={50} />
        ) : notifications?.length === 0 ? (
          <p className={s.noItems}>No hay notificaciones cargadas aún</p>
        ) : (
          <NotificacionsTable
            setOpen={setOpenNotificationPopUp}
            searchText={searchText}
            setOpenAlertDelete={setOpenAlertDelete}
            selectedValue={selectedValue}
          />
        )}
      </div>

      <MainPopUp
        open={openNotificationPopUp}
        setOpen={setOpenNotificationPopUp}
        notificationSelected={notificationSelected}
      />

      <AlertDialogSlide
        open={openAlertDelete}
        setOpen={setOpenAlertDelete}
        textContent={'¿Está seguro que quiere eliminar esta notificación?'}
        textBackButton="Cancelar"
        textConfirmationButton={'Eliminar'}
        onClose={deleteNotification}
        extraFunction={handleExtraFunction}
      />
    </Layout1>
  )
}

export default AdminNotificationsPage
