import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import s from './styles.module.scss'

export default function AccordionQuestions({ faqsData, expanded, onAccordionChange }) {
  return (
    <div className={s.containerQuestions}>
      {!!faqsData.length ? (
        faqsData?.map((data, index) => (
          <Accordion
            key={index}
            expanded={expanded[`panel${index}`] === `panel${index}`}
            onChange={onAccordionChange(`panel${index}`)}
            className={s.mainAccordion}
            sx={{
              '& .MuiAccordionSummary-root': {
                fontWeight: 'bold',
                color: '#4c5efe',
                border: '1px solid #4c5efe',
                borderRadius: '8px',
                marginBottom: '20px',
              },
              '& .MuiAccordionSummary-expandIconWrapper': {
                color: '#4c5efe',
              },
              '& .MuiAccordionDetails-root': {
                position: 'relative',
                overflow: 'hidden',
              },
              boxShadow: 'none',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
              className={s.acordionTitle}
            >
              <div className={s.nameContainer}>
                <div>{data.topic}</div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {data.subTopics.map((subTopic, subIndex) => (
                <div key={subIndex}>
                  <Accordion
                    expanded={expanded[`panel${index}-${subIndex}`] === `panel${index}-${subIndex}`}
                    onChange={onAccordionChange(`panel${index}-${subIndex}`)}
                    sx={{
                      '& .MuiAccordionSummary-root': {
                        fontWeight: 'bold',
                        color: '#0f1333',
                        border: '1px solid #0f1333',
                        borderRadius: '8px',
                        marginBottom: '20px',
                      },
                      '& .MuiAccordionSummary-expandIconWrapper': {
                        color: '#0f1333',
                      },
                      boxShadow: 'none',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-${subIndex}bh-content`}
                      id={`panel${index}-${subIndex}bh-header`}
                      className={s.acordionTitle}
                    >
                      <div className={s.subtopicTitle}>{subTopic.title}</div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={s.subTopicContent}>{subTopic.content}</div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <div className={s.faqNotFound}>
          No encontramos resultados para tu búsqueda. Intenta con otra palabra clave o revisa nuestras preguntas
          frecuentes
        </div>
      )}
    </div>
  )
}
