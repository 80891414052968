import i18next from '@/configs/i18next'

const optionsSelectIsCalu = [
  { value: '-', name: 'Sin mensaje' },
  { value: 'optional-optional', name: 'Mensaje en /agenda opcional; Mensaje a pacientes opcional' },
  { value: 'fixed-optional', name: 'Mensaje en /agenda fijo; Mensaje a pacientes opcional' },
  { value: 'fixed-fixed', name: 'Mensaje en /agenda fijo; Mensaje a pacientes fijo' },
  { value: 'fixed-redirect', name: 'Mensaje fijo en /agenda; Mensaje redirect en Turnos online' },
  { value: 'redirect-redirect', name: 'Redirect a calu desde /agenda; Redirect de pacientes a calu  ' },
]

const countryCodes = [
  { value: 'ARG', name: 'Argentina' },
  { value: 'BEL', name: 'Bélgica' },
  { value: 'MEX', name: 'México' },
  { value: 'ESP', name: 'España' },
  { value: 'CHL', name: 'Chile' },
  { value: 'URY', name: 'Uruguay' },
  { value: 'BRA', name: 'Brasil' },
  { value: 'PRY', name: 'Paraguay' },
  { value: 'PER', name: 'Perú' },
  { value: 'COL', name: 'Colombia' },
  { value: 'GBR', name: 'Reino Unido' },
  { value: 'USA', name: 'Estados Unidos' },
  { value: 'VEN', name: 'Venezuela' },
  { value: 'BOL', name: 'Bolivia' },
  { value: 'NZL', name: 'Nueva Zelanda' },
  { value: 'ECU', name: 'Ecuador' },
  { value: 'HON', name: 'Honduras' },
  { value: 'PAN', name: 'Panama' },
  { value: 'CRI', name: 'Costa Rica' },
  { value: 'IRL', name: 'Irlanda' },
  { value: 'RUS', name: 'Rusia' },
  { value: 'NLD', name: 'Holanda' },
  { value: 'DEU', name: 'Alemania' },
  { value: 'AUS', name: 'Australia' },
  { value: 'DEN', name: 'Dinamarca' },
  { value: 'ISR', name: 'Israel' },
  { value: 'ITA', name: 'Italia' },
  { value: 'SWI', name: 'Suiza' },
  { value: 'ARM', name: 'Armenia' },
  { value: 'CZE', name: 'República Checa' },
  { value: 'FRA', name: 'Francia' },
  { value: 'AND', name: 'Andorra' },
  { value: 'SLV', name: 'El Salvador' },
  { value: 'SWE', name: 'Suecia' },
  { value: 'TUR', name: 'Turquía' },
  { value: 'UKR', name: 'Ucrania' },
  { value: 'POR', name: 'Portugal' },
  { value: 'UAE', name: 'Emiratos Arabes' },
  { value: 'SGP', name: 'Singapur' },
  { value: 'KOR', name: 'Corea del sur' },
]

const scopeAdmin = [
  { value: 'patients', name: 'Administradores, Asistentes, Profesionales y Pacientes' },
  { value: 'doctors', name: 'Administradores, Asistentes y Profesionales' },
  { value: 'centres', name: 'Administradores y Asistentes' },
  { value: 'userOnly', name: 'Usuario en particular' },
]

const typeOfCategories = [
  { value: 'medicine', name: 'Medicina' },
  { value: 'esthetic', name: 'Centro de estética' },
  { value: 'odontology', name: 'Odontología' },
  { value: 'kinesiology', name: 'Kinesiología' },
  { value: 'nutrition', name: 'Nutrición' },
  { value: 'barbershop', name: 'Barbería' },
  { value: 'hairdressing', name: 'Peluquería' },
  { value: 'massages', name: 'Masajes' },
  { value: 'chiropody', name: 'Podología' },
  { value: 'pedicure-manicure', name: 'Manicura y Pedicura' },
  { value: 'spa', name: 'Spa' },
  { value: 'psychology', name: 'Psicología' },
  { value: 'polyclinic', name: 'Policonsultorio' },
  { value: 'alternative-medicine', name: 'Medicina Alternativa' },
  { value: 'other', name: 'Otro' },
]

const typeOfReferrer = [
  { value: 'facebook', name: 'Facebook/Instagram' },
  { value: 'google', name: 'Google' },
  { value: 'webinars', name: 'Webinars' },
  { value: 'emails', name: 'Emails' },
  { value: 'referred', name: 'Referido' },
]

const weekDays = [
  { name: 'Domingo', code: 0 },
  { name: 'Lunes', code: 1 },
  { name: 'Martes', code: 2 },
  { name: 'Miércoles', code: 3 },
  { name: 'Jueves', code: 4 },
  { name: 'Viernes', code: 5 },
  { name: 'Sábado', code: 6 },
]

const turnDurations = [
  { name: '5 minutos', value: 5 },
  { name: '10 minutos', value: 10 },
  { name: '15 minutos', value: 15 },
  { name: '20 minutos', value: 20 },
  { name: '25 minutos', value: 25 },
  { name: '30 minutos', value: 30 },
  { name: '35 minutos', value: 35 },
  { name: '40 minutos', value: 40 },
  { name: '45 minutos', value: 45 },
  { name: '50 minutos', value: 50 },
  { name: '55 minutos', value: 55 },
  { name: '60 minutos', value: 60 },
  { name: '70 minutos', value: 70 },
  { name: '75 minutos', value: 75 },
  { name: '80 minutos', value: 80 },
  { name: '90 minutos', value: 90 },
  { name: '105 minutos', value: 105 },
  { name: '115 minutos', value: 115 },
  { name: '135 minutos', value: 135 },
  { name: '2 horas', value: 120 },
  { name: '3 horas', value: 180 },
  { name: '4 horas', value: 240 },
  { name: '5 horas', value: 300 },
  { name: '6 horas', value: 360 },
  { name: '7 horas', value: 420 },
  { name: '8 horas', value: 480 },
  { name: '9 horas', value: 540 },
  { name: '10 horas', value: 600 },
  { name: '11 horas', value: 660 },
  { name: '12 horas', value: 720 },
  { name: '13 horas', value: 780 },
  { name: '14 horas', value: 840 },
  { name: '15 horas', value: 900 },
  { name: '16 horas', value: 960 },
  { name: '17 horas', value: 1020 },
  { name: '18 horas', value: 1080 },
  { name: '19 horas', value: 1140 },
  { name: '20 horas', value: 1200 },
  { name: '21 horas', value: 1260 },
  { name: '22 horas', value: 1320 },
  { name: '23 horas', value: 1380 },
  { name: '24 horas', value: 1440 },
]

const workTimeFrequencies = [
  { name: 'Semanalmente', code: 0 },
  { name: 'Cada 15 días', code: 1 },
  { name: 'Único día', code: 2 },
]

const paymentMethods = [
  { name: 'Efectivo', value: 'ARScash' },
  { name: 'Tarjeta de crédito', value: 'creditCard' },
  { name: 'Tarjeta de debito', value: 'debitCard' },
  { name: 'Transferencia', value: 'transfer' },
  { name: 'Efectivo USD', value: 'USDcash' },
  { name: 'Paypal', value: 'PayPal' },
  { name: 'Mercado Pago', value: 'MercadoPago' },
  { name: 'TodoPago', value: 'TodoPago' },
  { name: 'Bizum', value: 'Bizum' },
]

const extrasAmount = [
  { name: 'Extra Cantidad Fija', value: 'plusCash' },
  { name: 'Extra Porcentaje', value: 'plusPercentage' },
  { name: 'Descuento Cantidad Fija', value: 'minusCash' },
  { name: 'Descuento Porcentaje', value: 'minusPercentage' },
]

const billingConditions = [
  { name: 'Responsable Monotributo', value: 'responsableMonotributo' },
  { name: 'IVA Responsable Inscripto', value: 'ivaResponsableInscripto' },
  { name: 'IVA Sujeto Exento', value: 'ivaSujetoExento' },
  { name: 'Consumidor Final', value: 'consumidorFinal' },
]

const tax = [
  { name: '21 %', value: '0.21' },
  { name: '10.5 %', value: '0.105' },
  { name: '0', value: '0' },
]

const subscriptionPaymentType = [
  { name: 'Link de pago', value: 'paymentLink' },
  { name: 'Transferencia bancaria', value: 'bankTransfer' },
  // { name: 'Suscripción MercadoPago', value: 'mercadoPagoSubscription' },
  // { name: 'Suscripción Stripe', value: 'stripeSubscription' },
]

const subscriptionStatus = [
  { name: 'Demo', value: 'demo' },
  { name: 'Activo', value: 'active' },
  { name: 'Bloqueado', value: 'blocked' },
]

const mercadoPagoStatus = [
  { name: 'Pendiente', value: 'pending' },
  { name: 'Activa', value: 'authorized' },
  { name: 'Pausada', value: 'paused' },
  { name: 'Cancelada', value: 'cancelled' },
]

const paymentStatus = [
  { name: 'Pendiente de pago', value: 'pending' },
  { name: 'Pagado', value: 'paid' },
  { name: 'En revisión', value: 'review' },
  { name: 'Parcialmente pagado', value: 'partiallyPaid' },
]

const turnStates = [
  {
    state: 'init',
    color: 'chedoc-b',
    name: 'Programado',
  },
  {
    state: 'confirmed',
    color: 'chedoc-a',
    name: 'Confirmado',
  },
  {
    state: 'arrived',
    color: 'chedoc-d',
    name: 'En sala de espera',
  },
  {
    state: 'happening',
    color: 'chedoc-g',
    name: 'Está siendo atendido',
  },
  {
    state: 'active-video',
    color: 'chedoc-g',
    name: 'Video consulta activa',
  },
  {
    state: 'finished',
    color: 'chedoc-c',
    name: 'Finalizado',
  },
  {
    state: 'absent',
    color: 'chedoc-e',
    name: 'Ausente',
  },
  {
    state: 'cancelled',
    color: 'chedoc-e',
    name: 'Cancelado',
  },
  {
    state: 'pending',
    color: 'chedoc-b',
    name: 'Reserva en curso',
  },
]

const reminderDays = [
  { code: 0, name: `Mismo día ${i18next.t('turnLabel.sd', { ns: 'global' })} a primera hora del día` },
  { code: 1, name: `1 día (24hs) antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 2, name: `2 días (48hs) antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 3, name: `3 días (72hs) antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 4, name: `4 días antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 5, name: `5 días antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 6, name: `6 días antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 7, name: `7 días antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
]

const cancelTimes = [
  { code: 0, name: `5 minutos antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 2, name: `2 horas antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 4, name: `4 horas antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 12, name: `12 horas antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 24, name: `1 día (24hs) antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 48, name: `2 días (48hs) antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 72, name: `3 días (72hs) antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
]

const starts = [
  { code: 15, name: `15 minutos antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 30, name: `30 minutos antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 45, name: `45 minutos antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 60, name: `60 minutos antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 120, name: `2 horas antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 240, name: `4 horas antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 480, name: `8 horas antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 720, name: `12 horas antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 1440, name: `1 día (24hs) antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
  { code: 2880, name: `2 días (48hs) antes ${i18next.t('turnLabel.sd', { ns: 'global' })}` },
]

const limits = [
  { name: '7 días', value: 7 },
  { name: '15 días', value: 15 },
  { name: '30 días', value: 30 },
  { name: '60 días', value: 60 },
  { name: '90 días', value: 90 },
]

const patientLimits = [
  { name: `1 ${i18next.t('turnLabel.s', { ns: 'global' })} online`, value: 1 },
  { name: `2 ${i18next.t('turnLabel.p', { ns: 'global' })} online`, value: 2 },
  { name: `3 ${i18next.t('turnLabel.p', { ns: 'global' })} online`, value: 3 },
  { name: `4 ${i18next.t('turnLabel.p', { ns: 'global' })} online`, value: 4 },
  { name: `5 ${i18next.t('turnLabel.p', { ns: 'global' })} online`, value: 5 },
]

const timezones = [
  { code: 'Etc/GMT+12', name: '(GMT-12:00) International Date Line West' },
  { code: 'Pacific/Midway', name: '(GMT-11:00) Midway Island, Samoa' },
  { code: 'Pacific/Honolulu', name: '(GMT-10:00) Hawaii' },
  { code: 'US/Alaska', name: '(GMT-09:00) Alaska' },
  { code: 'America/Los_Angeles', name: '(GMT-08:00) Pacific Time (US & Canada)' },
  { code: 'America/Tijuana', name: '(GMT-08:00) Tijuana, Baja California' },
  { code: 'US/Arizona', name: '(GMT-07:00) Arizona' },
  { code: 'America/Chihuahua', name: '(GMT-07:00) Chihuahua, La Paz, Mazatlan' },
  { code: 'US/Mountain', name: '(GMT-07:00) Mountain Time (US & Canada)' },
  { code: 'America/Managua', name: '(GMT-06:00) Central America' },
  { code: 'US/Central', name: '(GMT-06:00) Central Time (US & Canada)' },
  { code: 'America/Mexico_City', name: '(GMT-06:00) Guadalajara, Mexico City, Monterrey' },
  { code: 'Canada/Saskatchewan', name: '(GMT-06:00) Saskatchewan' },
  { code: 'America/Bogota', name: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco' },
  { code: 'US/Eastern', name: '(GMT-05:00) Eastern Time (US & Canada)' },
  { code: 'US/East-Indiana', name: '(GMT-05:00) Indiana (East)' },
  { code: 'Canada/Atlantic', name: '(GMT-04:00) Atlantic Time (Canada)' },
  { code: 'America/Caracas', name: '(GMT-04:00) Caracas, La Paz' },
  { code: 'America/Manaus', name: '(GMT-04:00) Manaus' },
  { code: 'America/Santiago', name: '(GMT-04:00) Santiago' },
  { code: 'Canada/Newfoundland', name: '(GMT-03:30) Newfoundland' },
  { code: 'America/Sao_Paulo', name: '(GMT-03:00) Brasilia' },
  { code: 'America/Argentina/Buenos_Aires', name: '(GMT-03:00) Buenos Aires, Georgetown' },
  { code: 'America/Godthab', name: '(GMT-03:00) Greenland' },
  { code: 'America/Montevideo', name: '(GMT-03:00) Montevideo' },
  { code: 'America/Noronha', name: '(GMT-02:00) Mid-Atlantic' },
  { code: 'Atlantic/Cape_Verde', name: '(GMT-01:00) Cape Verde Is.' },
  { code: 'Atlantic/Azores', name: '(GMT-01:00) Azores' },
  { code: 'Africa/Casablanca', name: '(GMT+00:00) Casablanca, Monrovia, Reykjavik' },
  { code: 'Etc/Greenwich', name: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London' },
  { code: 'Europe/Amsterdam', name: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna' },
  { code: 'Europe/Belgrade', name: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague' },
  { code: 'Europe/Brussels', name: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris' },
  { code: 'Europe/Sarajevo', name: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb' },
  { code: 'Africa/Lagos', name: '(GMT+01:00) West Central Africa' },
  { code: 'Asia/Amman', name: '(GMT+02:00) Amman' },
  { code: 'Europe/Athens', name: '(GMT+02:00) Athens, Bucharest, Istanbul' },
  { code: 'Asia/Beirut', name: '(GMT+02:00) Beirut' },
  { code: 'Africa/Cairo', name: '(GMT+02:00) Cairo' },
  { code: 'Africa/Harare', name: '(GMT+02:00) Harare, Pretoria' },
  { code: 'Europe/Helsinki', name: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius' },
  { code: 'Asia/Jerusalem', name: '(GMT+02:00) Jerusalem' },
  { code: 'Europe/Minsk', name: '(GMT+02:00) Minsk' },
  { code: 'Africa/Windhoek', name: '(GMT+02:00) Windhoek' },
  { code: 'Asia/Kuwait', name: '(GMT+03:00) Kuwait, Riyadh, Baghdad' },
  { code: 'Europe/Moscow', name: '(GMT+03:00) Moscow, St. Petersburg, Volgograd' },
  { code: 'Africa/Nairobi', name: '(GMT+03:00) Nairobi' },
  { code: 'Asia/Tbilisi', name: '(GMT+03:00) Tbilisi' },
  { code: 'Asia/Tehran', name: '(GMT+03:30) Tehran' },
  { code: 'Asia/Muscat', name: '(GMT+04:00) Abu Dhabi, Muscat' },
  { code: 'Asia/Baku', name: '(GMT+04:00) Baku' },
  { code: 'Asia/Yerevan', name: '(GMT+04:00) Yerevan' },
  { code: 'Asia/Kabul', name: '(GMT+04:30) Kabul' },
  { code: 'Asia/Yekaterinburg', name: '(GMT+05:00) Yekaterinburg' },
  { code: 'Asia/Karachi', name: '(GMT+05:00) Islamabad, Karachi, Tashkent' },
  { code: 'Asia/Calcutta', name: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
  { code: 'Asia/Katmandu', name: '(GMT+05:45) Kathmandu' },
  { code: 'Asia/Almaty', name: '(GMT+06:00) Almaty, Novosibirsk' },
  { code: 'Asia/Dhaka', name: '(GMT+06:00) Astana, Dhaka' },
  { code: 'Asia/Rangoon', name: '(GMT+06:30) Yangon (Rangoon)' },
  { code: 'Asia/Bangkok', name: '(GMT+07:00) Bangkok, Hanoi, Jakarta' },
  { code: 'Asia/Krasnoyarsk', name: '(GMT+07:00) Krasnoyarsk' },
  { code: 'Asia/Hong_Kong', name: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi' },
  { code: 'Asia/Kuala_Lumpur', name: '(GMT+08:00) Kuala Lumpur, Singapore' },
  { code: 'Asia/Irkutsk', name: '(GMT+08:00) Irkutsk, Ulaan Bataar' },
  { code: 'Australia/Perth', name: '(GMT+08:00) Perth' },
  { code: 'Asia/Taipei', name: '(GMT+08:00) Taipei' },
  { code: 'Asia/Tokyo', name: '(GMT+09:00) Osaka, Sapporo, Tokyo' },
  { code: 'Asia/Seoul', name: '(GMT+09:00) Seoul' },
  { code: 'Asia/Yakutsk', name: '(GMT+09:00) Yakutsk' },
  { code: 'Australia/Adelaide', name: '(GMT+09:30) Adelaide' },
  { code: 'Australia/Darwin', name: '(GMT+09:30) Darwin' },
  { code: 'Australia/Brisbane', name: '(GMT+10:00) Brisbane' },
  { code: 'Australia/Canberra', name: '(GMT+10:00) Canberra, Melbourne, Sydney' },
  { code: 'Australia/Hobart', name: '(GMT+10:00) Hobart' },
  { code: 'Pacific/Guam', name: '(GMT+10:00) Guam, Port Moresby' },
  { code: 'Asia/Vladivostok', name: '(GMT+10:00) Vladivostok' },
  { code: 'Asia/Magadan', name: '(GMT+11:00) Magadan, Solomon Is., New Caledonia' },
  { code: 'Pacific/Auckland', name: '(GMT+12:00) Auckland, Wellington' },
  { code: 'Pacific/Fiji', name: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.' },
  { code: 'Pacific/Tongatapu', name: "(GMT+13:00) Nuku'alofa" },
]

const defaultServices = [
  { _id: 'consultation', name: 'Consulta' },
  { _id: 'firstConsultation', name: 'Primera consulta' },
]

const paymentType = [
  { value: 'percentage', name: 'Porcentaje de sus ganacias' },
  { value: 'hour', name: 'Precio fijo por mes' },
]

const serviceTypes = [
  { value: 'practice', name: 'Práctica' },
  { value: 'study', name: 'Estudio' },
  { value: 'video', name: 'Video consulta' },
]

const genders = [
  { value: 'female', name: 'Femenino' },
  { value: 'male', name: 'Masculino' },
  { value: 'other', name: 'Prefiero no decirlo' },
]

const birthDayList = [
  { value: '-', name: 'Todos' },
  { value: 'today', name: 'Cumplen hoy' },
  { value: 'week', name: 'Cumplen esta semana' },
  { value: 'month', name: 'Cumplen este mes' },
]

const typeOfEntries = [
  { value: '-', name: 'Ingresos y Egresos' },
  { value: 'income', name: 'Ingresos' },
  { value: 'outcome', name: 'Egresos' },
]

const turnStatesType = [
  { value: '-', name: 'Ver todos los estados' },
  { value: 'init', name: 'Programado' },
  { value: 'confirmed', name: 'Confirmado' },
  { value: 'finished', name: 'Finalizado' },
  { value: 'cancelled', name: 'Cancelado' },
  { value: 'pending', name: 'Reserva en curso' },
]

const provinces = [
  { name: 'Sin especificar' },
  { name: 'Entre Rios' },
  { name: 'Santiago del Estero' },
  { name: 'La rioja' },
  { name: 'Buenos Aires' },
  { name: 'Catamarca' },
  { name: 'Santa Fe' },
  { name: 'San Luis' },
  { name: 'Mendoza' },
  { name: 'Corrientes' },
  { name: 'Santa Cruz' },
  { name: 'Neuquen' },
  { name: 'Formosa' },
  { name: 'San Juan' },
  { name: 'Salta' },
  { name: 'Ciudad Autonoma de Bs.As.' },
  { name: 'Rio Negro' },
  { name: 'Chaco' },
  { name: 'La Pampa' },
  { name: 'Tierra del Fuego' },
  { name: 'Jujuy' },
  { name: 'Misiones' },
  { name: 'Cordoba' },
  { name: 'Tucuman' },
  { name: 'Chubut' },
]

export {
  billingConditions,
  birthDayList,
  cancelTimes,
  countryCodes,
  defaultServices,
  extrasAmount,
  genders,
  limits,
  mercadoPagoStatus,
  optionsSelectIsCalu,
  patientLimits,
  paymentMethods,
  paymentStatus,
  paymentType,
  provinces,
  reminderDays,
  scopeAdmin,
  serviceTypes,
  starts,
  subscriptionPaymentType,
  subscriptionStatus,
  tax,
  timezones,
  turnDurations,
  turnStates,
  turnStatesType,
  typeOfCategories,
  typeOfEntries,
  typeOfReferrer,
  weekDays,
  workTimeFrequencies,
}
