import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { scopeAdmin } from '@/helpers/constants.js'
import { updateNotification } from '@/store/notifications/actions'
import { selectedNotification } from '@/store/notifications/actions'
import { getUserNotifications } from '@/store/notifications/actions'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import CircleIcon from '@mui/icons-material/Circle'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Button, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import s from './styles.module.scss'

const PatientsTable = ({ setOpen, setOpenAlertDelete, selectedValue }) => {
  const dispatch = useDispatch()
  const [rowId, setRowId] = useState('')
  const { notifications, paginationData, loadingNotifications } = useSelector((state) => state.notifications)

  const { limit, totalDocs, page } = paginationData || {}

  const handleEditButtonClick = async (item) => {
    await dispatch(selectedNotification(item))
    setOpen(true)
  }
  const handleDeleteButtonClick = async (item) => {
    await dispatch(selectedNotification(item))
    setOpenAlertDelete(true)
  }

  const removeHtmlTags = (message) => {
    const div = document.createElement('div')
    div.innerHTML = message
    return div.textContent || div.innerText || ''
  }

  const changeNotificationVisibility = (data) => {
    const auxData = {
      ...data,
      visible: !data.visible,
    }

    try {
      dispatch(updateNotification(auxData))
    } catch (error) {
      console.log(error)
    }
  }

  const getScopeAdminName = (scope) => {
    const auxScope = scopeAdmin.find((e) => e.value === scope)
    return auxScope ? auxScope.name : '-'
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'center',
    headerAlign: 'left',
  }

  const columns = [
    {
      field: 'visible',
      headerName: 'Visible',
      minWidth: 80,
      maxWidth: 100,
      ...stylesColumns,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CircleIcon onClick={() => changeNotificationVisibility(params?.row?.raw)} />
        </div>
      ),
    },

    {
      field: 'scope',
      headerName: 'Alcance',
      minWidth: 350,
      maxWidth: 380,
      ...stylesColumns,
    },
    {
      field: 'message',
      headerName: 'Mensaje',
      ...stylesColumns,
    },
    {
      field: 'creationDate',
      headerName: 'Fecha de creación',
      minWidth: 110,
      maxWidth: 150,
      ...stylesColumns,
      renderCell: (params) => <div style={{ textAlign: 'center' }}>{params.value}</div>,
    },

    {
      field: 'raw',
      headerName: '',
      minWidth: 150,
      maxWidth: 180,
      ...stylesColumns,
      renderCell: (params) => (
        <>
          <Tooltip title="Editar" placement="top">
            <Button
              onClick={() => handleEditButtonClick(params?.value)}
              className={rowId === params.value._id ? s.rowHoverEditButton : s.rowButton}
              tabIndex={params.hasFocus ? 0 : -1}
            >
              <EditOutlinedIcon />
            </Button>
          </Tooltip>
        </>
      ),
    },

    {
      field: 'delete',
      headerName: '',
      minWidth: 150,
      maxWidth: 180,
      ...stylesColumns,
      renderCell: (params) => (
        <>
          <Tooltip title="eliminar" placement="top">
            <Button
              onClick={() => handleDeleteButtonClick(params.value)}
              className={rowId === params.value._id ? s.rowHoverDeleteButton : s.rowButton}
              tabIndex={params.hasFocus ? 0 : -1}
            >
              <DeleteOutlineIcon />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ]

  const rows = notifications?.map((entry) => {
    return {
      id: entry?._id,
      visible: entry?.visible,
      scope: getScopeAdminName(entry?.scope),
      message: entry?.message ? removeHtmlTags(entry?.message) : '-',
      creationDate: toCustomTz(entry?.creationDate, undefined, true, 'DD/MM/YYYY'),
      raw: entry,
      delete: entry,
    }
  })

  const handleRowOver = (e) => {
    const rowId = e.currentTarget.dataset.id
    const row = rows.find((el) => el.id === rowId)
    setRowId(row.id)
  }

  const handlePaginationChange = ({ page }) => {
    const auxParams = {
      role: 'admin',
      scope: selectedValue,
      page: page + 1,
    }
    dispatch(getUserNotifications(auxParams))
  }
  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          background: '#fff',
        },
        '& .greenColor': {
          color: '#1ab395',
          fontWeight: 800,
        },
        '& .grayColor': {
          color: 'gray',
          fontWeight: 800,
        },
      }}
    >
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        rows={rows || []}
        rowCount={totalDocs || 0}
        paginationMode="server"
        columns={columns}
        pagination
        loading={loadingNotifications}
        disableRowSelectionOnClick={true}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        onPaginationModelChange={handlePaginationChange}
        slotProps={{
          pagination: {
            labelRowsPerPage: 'Entradas por página:',
            labelDisplayedRows: () =>
              `${(page - 1) * limit + 1} - ${page * limit > totalDocs ? totalDocs : page * limit} de ${totalDocs}`,
          },
          row: {
            onMouseEnter: handleRowOver,
          },
        }}
        sx={{
          fontSize: '13px',
          background: '#fff',
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell ': {
            display: 'flex',
            justifyContent: 'flex-start',
          },
        }}
        getCellClassName={(params) => {
          if (!params.row.visible && params.field === 'visible') {
            return 'grayColor'
          }
          if (params.row.visible && params.field === 'visible') {
            return 'greenColor'
          }
        }}
        getRowClassName={(params) => (params.row.highlight ? s.highlightRow : '')}
        rowHeight={45}
        autoHeight={true}
      />
    </Box>
  )
}

export default PatientsTable
