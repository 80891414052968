import i18next from '@/configs/i18next'
import { paymentMethods } from '@/helpers/constants'
import { baseURL } from '@/helpers/env'
import { getSingleTemplateTreatment } from '@/services/treatments'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { showToast } from '@/utils/toast'

import dayjs from 'dayjs'
import { capitalizeFirstLetters } from '../capitalizeFirstLetters'
import { handleSetCountryCode } from '../handleSetCountryCode'

export const sendWhatsappToRemindTurn = (selectedTurn, centre, isWappConnected) => {
  const turnDate = dayjs(selectedTurn?.startHour).utc().format()
  const turnDateTime = dayjs(selectedTurn?.startHour).utc().format('HH:mm')
  const patient = selectedTurn?.patientCentre
  const countryCode = handleSetCountryCode(patient?.country || centre?.country || import.meta.env.REACT_APP_COUNTRY)
  const shouldSend = patient?.mobile
  const now = dayjs().utc('Z').format()
  const isToday = dayjs(turnDate).isSame(now, 'day')
  const tomorrow = dayjs(turnDate).add(1, 'day').isTomorrow()
  const isOver = dayjs(turnDate).isBefore(dayjs(now))
  const turnLabel = i18next.t('turnLabel.s', { ns: 'global' })
  const hasMercadoPagoPayment = selectedTurn?.paymentHistory?.find((p) => p.method === 'MercadoPago')

  if (!shouldSend) {
    showToast('El paciente no tiene número cargado', 'error', 3000)
    return
  }

  if (isOver) {
    showToast(`La fecha de ${turnLabel} ya pasó`, 'error', 3000)
    return
  }

  const number = countryCode + patient?.mobile
  let url = 'https://api.whatsapp.com/send?phone=' + number
  let when
  const patientName = capitalizeFirstLetters(patient.firstname)
  const doctorCentre = selectedTurn?.doctorCentre
  const doctorLastname = capitalizeFirstLetters(selectedTurn?.doctorCentre?.lastname)
  const doctorFirstname = capitalizeFirstLetters(selectedTurn?.doctorCentre?.firstname)
  const doctorFullName = `${doctorLastname} ${doctorFirstname}`
  const centreAddress = capitalizeFirstLetters(centre?.street)
  const service = selectedTurn?.service
  const DayOfWeek = dayjs(turnDate).utc().format('dddd')
  const day = dayjs(turnDate).utc().format('D')
  const month = dayjs(turnDate).utc().format('MMMM')
  const date = `${DayOfWeek} ${day} de ${month}`

  switch (true) {
    case isToday:
      when = 'hoy'
      break
    case tomorrow:
      when = 'mañana'
      break
    default:
      when = 'el día'
      break
  }

  let messageText = `*Recordatorio de ${turnLabel}* `

  messageText += `%0a%0a 🗓 Hola ${patientName}, no olvides que ${when} ${date} a las ${turnDateTime}hs tenés ${turnLabel}`

  if (doctorCentre) {
    messageText += ` con ${doctorFullName}`
  }
  messageText += '.'

  if (centreAddress) {
    messageText += `%0a📍 Dirección: ${centreAddress}.`
  }

  if (service && service?.indications && service?.indications?.length) {
    messageText += '%0a%0aTe recordamos las siguientes indicaciones para tu ' + turnLabel + ':'

    service?.indications?.forEach(function (indication) {
      messageText += '%0a   - ' + indication
    })
  }

  if (centre?.centreConfiguration && centre?.centreConfiguration?.extraWhatsAppReminderField) {
    const reminderField = centre.centreConfiguration.extraWhatsAppReminderField
    const encodedReminderField = encodeURIComponent(reminderField)
    const decodedReminderField = decodeURIComponent(encodedReminderField)
    messageText += `%0a%0a ${decodedReminderField}`
  }

  if (selectedTurn?.paymentLink && !hasMercadoPagoPayment) {
    messageText += `%0a%0a💳 Podés pagar tu ${turnLabel} con MercadoPago: ${selectedTurn.paymentLink}`
  }

  if (!selectedTurn?.paymentLink && selectedTurn?.service?.paymentLink) {
    messageText += '%0a%0a💳 Podés pagar ahora utilizando el siguiente enlace: ' + selectedTurn.service.paymentLink
  }

  if (!centre?.noEmailConfirmButton) {
    messageText += `%0a%0a✅ Clic para *Confirmar ${turnLabel}*: ${baseURL}turnos/confirmar/${selectedTurn._id}`
  }
  if (!centre?.noEmailModifyButton) {
    messageText += `%0a%0a🌀 Clic para *Reprogramar ${turnLabel}*: ${baseURL}turnos/definir-horario/${selectedTurn._id}`
  }
  if (!centre?.noEmailCancelButton) {
    messageText += `%0a%0a🔴 Clic para *Cancelar ${turnLabel}*: ${baseURL}turnos/cancelar/${selectedTurn._id}`
  }

  if (centre?.noEmailConfirmButton || centre?.noEmailModifyButton || centre?.noEmailCancelButton) {
    messageText += `%0a%0a _*¡IMPORTANTE! Si no podés acceder a los enlaces, agendá este número para que se habiliten.*_`
  }

  if (isWappConnected) {
    messageText = messageText.replaceAll('%0a', '\r\n')
    return messageText
  } else {
    messageText = messageText.replaceAll('&', '%26')
  }

  url = url.concat('&text=').concat(messageText)

  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.click()
  return a
}

export const sendWhatsappTurnDetail = async (selectedTurn, centre, isWappConnected) => {
  const patient = selectedTurn.patientCentre
  const countryCode = handleSetCountryCode(patient?.country || centre?.country || import.meta.env.REACT_APP_COUNTRY)
  const country = centre?.country || import.meta.env.REACT_APP_COUNTRY
  const shouldSend = selectedTurn?.patientCentre?.mobile
  const centreCond = centre.showEmailTurnPaymentInfo || centre.turnSurvey
  const centreName = capitalizeFirstLetters(centre.name)
  const turnDate = dayjs(selectedTurn.startHour).utc().format()
  const turnDateTime = dayjs(selectedTurn.startHour).utc().format('HH:mm')
  const DayOfWeek = dayjs(turnDate).utc().format('dddd')
  const day = dayjs(turnDate).utc().format('D')
  const month = dayjs(turnDate).utc().format('MMMM')
  const date = `${DayOfWeek} ${day} de ${month}`
  const turnLabel = i18next.t('turnLabel.s', { ns: 'global' })
  const doctorCentre = selectedTurn?.doctorCentre
  const doctorLastname = capitalizeFirstLetters(selectedTurn?.doctorCentre?.lastname)
  const doctorFirstname = capitalizeFirstLetters(selectedTurn?.doctorCentre?.firstname)
  const doctorFullName = `${doctorLastname} ${doctorFirstname}`

  if (!shouldSend) {
    showToast('El paciente no tiene número cargado', 'error', 3000)
    return
  }
  if (!centreCond) {
    showToast('Debes tener encuesta cargada o habilitar el envío de detalle de pagos', 'warning', 3000)
    return
  }

  const number = countryCode + patient.mobile
  let url = `https://api.whatsapp.com/send?phone=${number}`
  let messageText = ''
  if (centre.turnSurvey) {
    messageText += `%0a*Encuesta de satisfacción* ✨%0a`
    messageText += `Nos gustaría conocer más sobre tu experiencia en ${centreName}`
    messageText += '. ¿Te gustaría ayudarnos? Sólo te tomará unos minutos y nos ayudará a ser mejores. %0a'
    messageText += centre.turnSurvey
    messageText += '%0a_________%0a%0a'
  }

  if (centre?.centreConfiguration?.showWhatsAppTurnPaymentInfo) {
    if (selectedTurn?.treatment) {
      const treatment = await getSingleTemplateTreatment(selectedTurn.treatment._id)
      const totalSessions = treatment.turnsCount
      const completedSessions = treatment.turns.filter((turn) => turn.state === 'finished').length

      const cancelledSessions = treatment.turns.filter((turn) => turn.state === 'cancelled').length
      const pendingSessions = treatment.turns.length - completedSessions - cancelledSessions
      const unassignedSessions = treatment.turnsCount - treatment.turns.length + cancelledSessions

      messageText += `%0aHola ${capitalizeFirstLetters(
        patient.firstname,
      )}, te enviamos información sobre tu ${turnLabel}`
      if (doctorCentre) {
        messageText += ' con ' + doctorFullName
      }
      messageText += ` del día ${date} a las ${turnDateTime}hs`

      messageText += '%0a%0a*Detalle del tratamiento* 🧾'

      messageText += `%0a- Tratamiento: *${treatment.name.toUpperCase()}*.`

      if (treatment?.price) {
        messageText += `%0a- Pagado: *${handleSetMoneySign(country)} ${
          treatment.isPaid ? treatment.price : treatment.paidPrice
        }/ ${handleSetMoneySign(country)} ${treatment.price}*`
      }
      messageText += `%0a- ${totalSessions} sesiones (${completedSessions} completadas, ${pendingSessions} pendientes, ${unassignedSessions} faltan asignar)`

      if (treatment?.turns?.length > 0) {
        messageText += '%0a%0a'

        treatment?.turns
          ?.filter((turn) => turn.state !== 'cancelled')
          ?.reverse()
          ?.forEach((turn, index) => {
            messageText += `- *SESIÓN ${treatment.turns.length - index} de ${treatment.turnsCount}*`
            messageText += ' (' + (turn.state === 'finished' ? 'Completada' : 'Pendiente') + ')'
            messageText += '%0a'

            if (turn.doctorCentre) {
              messageText += `${turn.doctorCentre.lastname.toUpperCase()}, ${turn.doctorCentre.firstname.toUpperCase()}`
            }

            if (turn.machine) {
              messageText += turn.machine.name.toUpperCase()
            }
            let treatmentDate = dayjs(turn.startHour).utc().format()
            let treatmentDateTime = dayjs(turn.startHour).utc().format('HH:mm')
            let treatmentDayOfWeek = dayjs(treatmentDate).utc().format('dddd')
            let treatmentDay = dayjs(treatmentDate).utc().format('D')
            let treatmentMonth = dayjs(treatmentDate).utc().format('MMMM')
            let date = `${treatmentDayOfWeek} ${treatmentDay} de ${treatmentMonth} `

            messageText += ' _' + (turn.service ? turn.service.name : 'Consulta') + '_'
            messageText += ` - ${date} a las ${treatmentDateTime}hs`
            messageText += `%0a Pagado en esta sesión *${handleSetMoneySign(country)} ${turn?.totalPayments || 0}*.`
            messageText += '%0a%0a'
          })
      } else {
        messageText += `%0a%0a_No hay sesiones asignadas aún._`
      }
    } else {
      messageText += `*Detalle de ${turnLabel}* 🧾`
      messageText += `%0aHola ${capitalizeFirstLetters(
        patient.firstname,
      )} te enviamos información sobre tu ${turnLabel}`
      if (doctorCentre) {
        messageText += ' con ' + doctorFullName
      }
      messageText += ` del día ${date} a las ${turnDateTime}hs`

      if (selectedTurn.amount !== null) {
        messageText += `%0a%0a- Total a pagar: *${handleSetMoneySign(country)}` + selectedTurn.amount + '*.'
      }
      if (selectedTurn.totalPayments !== null) {
        messageText += `%0a- Monto pagado: *${handleSetMoneySign(country)}` + selectedTurn.totalPayments + '*.'
      }

      if (selectedTurn.paymentHistory && selectedTurn.paymentHistory.length) {
        let debt = selectedTurn.amount || 0
        messageText += '%0a%0aDetalle de pagos:'

        selectedTurn.paymentHistory.forEach((item) => {
          debt -= item.amount
          messageText +=
            '%0a- ' +
            date +
            ' (_' +
            convertValueToName(paymentMethods, item.method) +
            '_):' +
            handleSetMoneySign(country) +
            item.amount
        })
        messageText += `%0a- *Deuda:* *${handleSetMoneySign(country)}` + debt + '*.'
      }
    }
  }
  messageText = messageText.replaceAll('&', '%26')

  if (isWappConnected) {
    return decodeURIComponent(messageText)
  }
  url = url.concat('&text=').concat(messageText)

  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.click()
  return a
}

export const sendWhatsappQuickRegister = (selectedTurn, centre, isWappConnected) => {
  const patient = selectedTurn?.patientCentre
  const shouldSend = patient?.mobile
  const patientName = capitalizeFirstLetters(patient?.firstname)
  const countryCode = handleSetCountryCode(patient?.country || centre?.country || import.meta.env.REACT_APP_COUNTRY)
  const centreName = capitalizeFirstLetters(centre.name)
  const turnLabel = i18next.t('turnLabel.p', { ns: 'global' })

  if (!shouldSend) {
    showToast('El paciente no tiene número cargado', 'error', 3000)
    return
  }

  const number = countryCode + patient?.mobile
  let url = 'https://api.whatsapp.com/send?phone=' + number

  let messageText = `*Registro rápido en Calu* 🚀`

  messageText += `%0a%0aHola ${patientName}, desde ${centreName} queremos invitarte a unirte a Calu para que puedas reservar ${turnLabel} de manera online. %0a%0a¡Es muy sencillo! Hacé clic en el siguiente enlace para crear tu cuenta: ${baseURL}registro`

  messageText = messageText.replaceAll('&', '%26')

  if (isWappConnected) {
    return decodeURIComponent(messageText)
  }
  url = url.concat('&text=').concat(messageText)

  const a = document.createElement('a')
  a.setAttribute('href', url.concat('&text=').concat(messageText))
  a.setAttribute('target', '_blank')
  a.click()
  return a
}

export const sendWhatsappEmpty = (selectedTurn, centre) => {
  const patient = selectedTurn?.patientCentre || selectedTurn
  const countryCode = handleSetCountryCode(patient?.country || centre?.country || import.meta.env.REACT_APP_COUNTRY)
  const shouldSend = patient?.mobile
  if (!shouldSend) {
    showToast('El paciente no tiene número cargado', 'error', 3000)
    return
  }

  const number = patient?.mobile || selectedTurn?.mobile
  let url = 'https://api.whatsapp.com/send?phone=' + countryCode + number

  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.click()
  return a
}

export const sendBirthdayMessage = (patientData, centreDetail, isWappConnected) => {
  const countryCode = handleSetCountryCode(patientData?.country || import.meta.env.REACT_APP_COUNTRY)
  const patientNumber = patientData?.mobile
  if (!patientNumber) {
    showToast('El paciente no tiene número cargado', 'error', 3000)
    return
  }

  let url = 'https://api.whatsapp.com/send?phone=' + countryCode + patientNumber
  let messageText = `Hola ${capitalizeFirstLetters(patientData?.firstname)}, desde ${capitalizeFirstLetters(
    centreDetail?.name,
  )} te deseamos un feliz cumpleaños🎉`

  messageText = messageText.replaceAll('&', '%26')

  if (isWappConnected) {
    return decodeURIComponent(messageText)
  }

  url = url.concat('&text=').concat(messageText)

  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.click()
  return a
}

export const sendWhatsappSetTurnHour = (selectedTurn, centre, isWappConnected) => {
  const { patientCentre } = selectedTurn
  const shouldSend = patientCentre?.mobile
  if (!shouldSend) {
    showToast('El paciente no tiene número cargado', 'error', 3000)
    return
  }
  const countryCode = handleSetCountryCode(patientCentre.country || centre.country || import.meta.env.REACT_APP_COUNTRY)
  const turnLabel = i18next.t('turnLabel.s', { ns: 'global' })
  const number = countryCode + patientCentre.mobile
  const patientName = capitalizeFirstLetters(patientCentre.firstname)
  const service = selectedTurn.service
  let url = 'https://api.whatsapp.com/send?phone=' + number

  let messageText = `%0a%0aHola ${patientName}! Ingresá en el siguiente link para seleccionar día y horario para tu proximo ${turnLabel}`

  if (service && service.name) {
    messageText += '%0a%0a Servicio: ' + service.name
  }

  messageText += `%0a%0a👉 ${baseURL}turnos/definir-horario/${selectedTurn._id}`

  messageText += `%0a%0a _*¡IMPORTANTE! Si no podés acceder al enlace, agenda este número para que se habilite.*_`

  messageText = messageText.replaceAll('&', '%26')

  if (isWappConnected) {
    return decodeURIComponent(messageText)
  }

  url = url.concat('&text=').concat(messageText)

  const a = document.createElement('a')
  a.setAttribute('href', url.concat('&text=').concat(messageText))
  a.setAttribute('target', '_blank')
  a.click()
  return a
}

export const sendWhatsappTurnCancelled = (selectedTurn, centre, isWappConnected) => {
  const patient = selectedTurn.patientCentre
  const shouldSend = patient?.mobile
  if (!shouldSend) {
    showToast('El paciente no tiene número cargado', 'error', 3000)
    return
  }

  const countryCode = handleSetCountryCode(patient?.country || centre?.country || import.meta.env.REACT_APP_COUNTRY)
  const turnDate = dayjs(selectedTurn.startHour).utc().format()
  const turnDateTime = dayjs(selectedTurn.startHour).utc().format('HH:mm')
  const number = countryCode + selectedTurn.patientCentre.mobile
  let url = 'https://api.whatsapp.com/send?phone=' + number
  const turnLabel = i18next.t('turnLabel.s', { ns: 'global' })
  const patientFirstname = capitalizeFirstLetters(patient.firstname)
  const patientLastname = capitalizeFirstLetters(patient.lastname)
  const doctorCentre = selectedTurn?.doctorCentre
  const doctorLastname = capitalizeFirstLetters(selectedTurn?.doctorCentre?.lastname)
  const doctorFirstname = capitalizeFirstLetters(selectedTurn?.doctorCentre?.firstname)
  const doctorFullName = `${doctorLastname} ${doctorFirstname}`
  const DayOfWeek = dayjs(turnDate).utc().format('dddd')
  const day = dayjs(turnDate).utc().format('D')
  const month = dayjs(turnDate).utc().format('MMMM')
  const date = `${DayOfWeek} ${day} de ${month}`

  let messageText = `*Aviso de cancelación de ${turnLabel}* ❌ `

  messageText += `%0a%0aHola ${patientFirstname} ${patientLastname}, lamentablemente tu ${turnLabel}`

  if (doctorCentre) {
    messageText += ' con ' + doctorFullName
  }
  messageText += ` del dia ${date} a las ${turnDateTime}hs. fue cancelada.`

  if (centre.visible) {
    messageText += `%0a%0a🗓 Comunicate al ${centre.phones[0]} para reprogramar o podés agendar otra ${turnLabel} desde ${baseURL}centro/${centre._id}`
  } else if (centre.phones && centre.phones.length) {
    messageText += `%0a%0a🗓 Comunicate al ${centre.phones[0]} para reprogramar.`
  }

  messageText = messageText.replaceAll('&', '%26')

  if (isWappConnected) {
    return decodeURIComponent(messageText)
  }

  url = url.concat('&text=').concat(messageText)

  const a = document.createElement('a')
  a.setAttribute('href', url.concat('&text=').concat(messageText))
  a.setAttribute('target', '_blank')
  a.click()
  return a
}

export const sendWhatsappRescheduleMessage = (selectedTurn, centre, isWappConnected) => {
  const patient = selectedTurn?.patientCentre
  const shouldSend = patient?.mobile
  const turnDate = dayjs(selectedTurn.startHour).utc().format()
  const turnDateTime = dayjs(selectedTurn.startHour).utc().format('HH:mm')
  const now = dayjs().utc('Z').format()
  const isOver = dayjs(turnDate).isBefore(dayjs(now))
  const turnLabel = i18next.t('turnLabel.s', { ns: 'global' })

  if (!shouldSend) {
    showToast('El paciente no tiene número cargado', 'error', 3000)
    return
  }

  if (isOver) {
    showToast(`La fecha de ${turnLabel} ya pasó`, 'error', 3000)
    return
  }
  const countryCode = handleSetCountryCode(patient?.country || centre?.country || import.meta.env.REACT_APP_COUNTRY)
  const number = countryCode + selectedTurn.patientCentre.mobile
  let url = 'https://api.whatsapp.com/send?phone=' + number
  const patientFirstName = capitalizeFirstLetters(patient.firstname)
  const patientLastname = capitalizeFirstLetters(patient.lastname)
  const doctorCentre = selectedTurn?.doctorCentre
  const doctorLastname = capitalizeFirstLetters(selectedTurn?.doctorCentre?.lastname)
  const doctorFirstname = capitalizeFirstLetters(selectedTurn?.doctorCentre?.firstname)
  const doctorFullName = `${doctorLastname} ${doctorFirstname}`
  const centreAddress = capitalizeFirstLetters(centre.street)
  const service = selectedTurn.service
  const DayOfWeek = dayjs(turnDate).utc().format('dddd')
  const day = dayjs(turnDate).utc().format('D')
  const month = dayjs(turnDate).utc().format('MMMM')
  const date = `${DayOfWeek} ${day} de ${month}`

  let messageText = `*Reprogramación de ${turnLabel}* 🗓`

  //self.turnLabel.sr
  const turnLabelSr = i18next.t('turnLabel.sr', { ns: 'global' })
  messageText += `%0a%0aHola ${patientFirstName}, tu ${turnLabel} está ${turnLabelSr} para el día ${date} a las ${turnDateTime} hs`

  messageText += doctorCentre ? `, con ${doctorFullName}.` : '.'

  if (centreAddress) {
    messageText += `%0a📍 Dirección: ${centreAddress}.`
  }

  messageText += `%0a%0a👤 Datos personales: ${patientLastname}, ${patientFirstName}`

  messageText += patient.dni && patient.dni.length < 15 ? ` (${patient.dni}).` : '.'

  // Add extra WhatsApp reminder field
  if (centre.centreConfiguration && centre.centreConfiguration.extraWhatsAppReminderField) {
    messageText += `%0a%0a${centre.centreConfiguration.extraWhatsAppReminderField}`
  }

  messageText += '.'

  if (service && service.indications && service.indications.length) {
    messageText += '%0a%0aTe recordamos las siguientes indicaciones para tu ' + turnLabel + ':'

    service.indications.forEach(function (indication) {
      messageText += '%0a   - ' + indication
    })
  }

  if (selectedTurn.service && selectedTurn.service.paymentLink) {
    messageText += '%0a%0a💳 Podés pagar ahora utilizando el siguiente enlace: ' + selectedTurn.service.paymentLink
  }

  if (!centre.noEmailCancelButton) {
    messageText += `%0a%0a🔴 Clic para *Cancelar ${turnLabel}*: ${baseURL}turnos/cancelar/${selectedTurn._id}`
  }
  if (centre.noEmailConfirmButton || centre.noEmailModifyButton || centre.noEmailCancelButton) {
    messageText += `%0a%0a _*¡IMPORTANTE! Si no podés acceder a los enlaces, agenda este número para que se habiliten.*_`
  }

  messageText = messageText.replaceAll('&', '%26')
  if (isWappConnected) {
    return decodeURIComponent(messageText)
  }
  url = url.concat('&text=').concat(messageText)

  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.click()
  return a
}

export const sendWhatsappTurnCreation = (selectedTurn, centre, isWappConnected) => {
  const patient = selectedTurn?.patientCentre
  const shouldSend = patient?.mobile
  const now = dayjs().utc('Z').format()
  const turnDate = dayjs(selectedTurn.startHour).utc().format()
  const turnDateTime = dayjs(selectedTurn.startHour).utc().format('HH:mm')
  const isOver = dayjs(turnDate).isBefore(dayjs(now))
  const turnLabel = i18next.t('turnLabel.s', { ns: 'global' })
  const hasMercadoPagoPayment = selectedTurn?.paymentHistory?.find((p) => p.method === 'MercadoPago')

  if (!shouldSend) {
    showToast('El paciente no tiene número cargado', 'error', 3000)
    return
  }

  if (isOver) {
    showToast(`La fecha de ${turnLabel} ya pasó`, 'error', 3000)
    return
  }

  const countryCode = handleSetCountryCode(patient?.country || centre?.country || import.meta.env.REACT_APP_COUNTRY)
  const number = countryCode + selectedTurn.patientCentre.mobile
  let url = 'https://api.whatsapp.com/send?phone=' + number
  const patientFirstname = capitalizeFirstLetters(patient.firstname)
  const patientLastname = capitalizeFirstLetters(patient.lastname)
  const doctorCentre = selectedTurn?.doctorCentre
  const doctorLastname = capitalizeFirstLetters(selectedTurn?.doctorCentre?.lastname)
  const doctorFirstname = capitalizeFirstLetters(selectedTurn?.doctorCentre?.firstname)
  const doctorFullName = `${doctorLastname} ${doctorFirstname}`
  const centreAddress = capitalizeFirstLetters(centre.street)
  const service = selectedTurn?.service
  const DayOfWeek = dayjs(turnDate).utc().format('dddd')
  const day = dayjs(turnDate).utc().format('D')
  const month = dayjs(turnDate).utc().format('MMMM')
  const date = `${DayOfWeek} ${day} de ${month}`

  let messageText = `*Reserva de ${turnLabel}* 🗓`

  messageText += `%0a%0aHola ${patientFirstname}, tu ${turnLabel} está reservada para el día ${date} a las ${turnDateTime} hs`

  messageText += doctorCentre ? `, con ${doctorFullName}.` : '.'

  if (centreAddress) {
    messageText += `%0a📍 Dirección: ${centreAddress}.`
  }

  messageText += `%0a%0a👤 Datos personales: ${patientLastname}, ${patientFirstname}`

  messageText += patient.dni && patient.dni.length < 15 ? ` (${patient.dni}).` : '.'

  if (service && service.indications && service.indications.length) {
    messageText += '%0a%0aTe recordamos las siguientes indicaciones para tu ' + turnLabel + ':'

    service.indications.forEach(function (indication) {
      messageText += '%0a   - ' + indication
    })
  }

  // Add extra WhatsApp reminder field
  if (centre.centreConfiguration && centre.centreConfiguration.extraWhatsAppReminderField) {
    messageText += `%0a%0a${centre.centreConfiguration.extraWhatsAppReminderField}`
  }

  if (selectedTurn?.paymentLink && !hasMercadoPagoPayment) {
    messageText += `%0a%0a💳 Podés pagar tu ${turnLabel} con MercadoPago: ${selectedTurn.paymentLink}`
  }

  if (!selectedTurn?.paymentLink && selectedTurn?.service?.paymentLink) {
    messageText += '%0a%0a💳 Podés pagar ahora utilizando el siguiente enlace: ' + selectedTurn.service.paymentLink
  }

  if (!centre?.noEmailConfirmButton) {
    messageText += `%0a%0a✅ Clic para *Confirmar ${turnLabel}*: ${baseURL}turnos/confirmar/${selectedTurn._id}`
  }

  if (!centre.noEmailCancelButton) {
    messageText += `%0a%0a🔴 Clic para *Cancelar ${turnLabel}*: ${baseURL}turnos/cancelar/${selectedTurn._id}`
  }

  if (centre.noEmailConfirmButton || centre.noEmailModifyButton || centre.noEmailCancelButton) {
    messageText += `%0a%0a _*¡IMPORTANTE! Si no podés acceder a los enlaces, agenda este número para que se habiliten.*_`
  }

  if (isWappConnected) {
    messageText = messageText.replaceAll('%0a', '\r\n')
    return messageText
  } else {
    messageText = messageText.replaceAll('&', '%26')
  }

  url = url.concat('&text=').concat(messageText)

  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.click()
  return a
}

export const sendReportPrescriptionLink = (patientCentre, link, isWappConnected) => {
  const shouldSend = patientCentre?.mobile
  if (!shouldSend) {
    showToast('El paciente no tiene número cargado', 'error', 3000)
    return
  }
  const countryCode = handleSetCountryCode(patientCentre.country || import.meta.env.REACT_APP_COUNTRY)
  const number = countryCode + patientCentre.mobile
  const patientName = capitalizeFirstLetters(patientCentre.firstname)

  let url = 'https://api.whatsapp.com/send?phone=' + number

  let messageText = `Hola ${patientName} hacé clic en el siguiente link para visualizar la receta digital 👉 ${link} `

  if (isWappConnected) {
    return decodeURIComponent(messageText)
  }

  url = url.concat('&text=').concat(messageText)

  const a = document.createElement('a')
  a.setAttribute('href', url.concat('&text=').concat(messageText))
  a.setAttribute('target', '_blank')
  a.click()
  return
}

export const generateWhatsAppNotification = (selectedTurn, centre, isReschedule) => {
  if (centre.centreConfiguration) {
    return
  }

  if (selectedTurn.state === 'init' && centre.centreConfiguration.shouldSendNotificationOnTurnCreate && !isReschedule) {
    sendWhatsappTurnCreation(selectedTurn, centre)
  }

  if (
    ['init', 'confirmed'].includes(selectedTurn.state) &&
    centre.centreConfiguration.shouldSendNotificationOnTurnModify &&
    isReschedule
  ) {
    sendWhatsappRescheduleMessage(selectedTurn, centre)
  }

  if (selectedTurn.state === 'finished' && centre.centreConfiguration.shouldSendNotificationOnTurnFinish) {
    sendWhatsappTurnDetail(selectedTurn, centre)
  }

  if (
    selectedTurn.state === 'cancelled' &&
    selectedTurn.cancelledInfo !== 'patient' &&
    centre.centreConfiguration.shouldSendNotificationOnTurnCancel
  ) {
    sendWhatsappTurnCancelled(selectedTurn, centre)
  }
}
