const faqList = [
  {
    topic: 'Configuración inicial',
    subTopics: [
      {
        title: 'Roles en calu',
        content: `Administrador: es el único que puede gestionar todas las funciones de la plataforma y configurar las preferencias del centro. 
        Crear los perfiles de profesionales y asistentes. Editar agendas. Cargar pacientes, enviar recordatorios y acceso a la gestión de ingresos y egresos.
        
        Profesional: debe contar con un correo diferente al rol del administrador. Editar su agenda, crear turnos; y sobre todo editar las historias clínicas de sus pacientes/clientes.
        
        Asistente: editar agendas y pacientes, enviar recordatorios. Tendrá acceso parcial a la plataforma en caso que el administrador lo permita pueda hacer gestión de la finanzas y visualizar las historias clínicas, no editarlas.`,
      },
      {
        title: '¿Cómo creo una agenda?',
        content:
          'Para crear una agenda seleccioná el botón de “Crear agenda” en el extremo inferior izquierdo. Completá los pasos necesarios para configurarla: nombre, profesional y servicio. Listo! Ya tenés tu agenda creada',
      },
      {
        title: '¿Cómo creo un servicio?',
        content:
          'Para crear un servicio dirigite a la agenda en cuestión en la que desees incorporar un nuevo servicio > Hacé click en el icono con forma de lápiz > Dirigite a la pestaña “Horarios de atención” > Hacé click en el icono con forma de lápiz a la derecha del día a modificar > Hacé click en "Servicios" > En el desplegable hacé click en "Crear servicio" > Colocá el nombre, duración y precio del servicio > “Guardar”. Luego, hace nuevamente clic en “Servicios”> Tildá este nuevo servicio para que esté disponible en esta franja horaria > “Actualizar” y listo!',
      },
      {
        title: '¿Cómo cargo un profesional?',
        content:
          'Ingresa desde el usuario administrador y dirigite a Configuración > “Usuarios”. Desde allí seleccioná el botón “Crear nuevo profesional” , cargá los datos necesarios y guarda. Es importante que el correo no haya sido utilizado antes en una cuenta de Calu ya que es un dato único. Al correo cargado le llegará una invitación de Calu para que configure su usuario y contraseña, así podrá entrar a tu centro con su propia cuenta.',
      },
      {
        title: '¿Cómo crear un usuario asistente?',
        content:
          'Para crear un usuario asistente dirigirte a Configuración > Usuarios y seleccioná el botón azul “Crear asistente” completa los datos obligatorios y listo! Al correo cargado le llegará una invitación de Calu para que configure su usuario y contraseña, así podrá entrar a tu centro con su propia cuenta. Es importante que el correo no haya sido utilizado antes en una cuenta de Calu ya que es un dato único.',
      },
      {
        title: '¿Qué hago si no recibo la invitación para registrarme?',
        content:
          'Primero, verifica que el centro donde trabajas utilice Calu y te haya enviado la invitación para terminar de registrarte y gestionar tu usuario de Calu. Segundo, en tu casilla de correo revisa la sección de “Spam”. Tercero, si ninguna de estas opciones soluciona tu problema, podés contactarte con el equipo de Soporte y te ayudarán a resolverlo.',
      },
      {
        title: '¿Cómo cambio la contraseña?',
        content:
          'Para cambiar la contraseña hace clic en el nombre del centro > Clic en "Cambiar contraseña" > Colocá la contraseña actual > Colocá la contraseña nueva > Clic en “Enviar”  y listo!',
      },
      {
        title: '¿Cómo modifico el nombre de mi centro?',
        content:
          'Para editar el nombre de tu centro, dirigite a la Configuración > Configuración general > General > en el campo “Nombre” indicas el que desees y guardas.',
      },
      {
        title: '¿En dónde cargo la dirección de mi centro?',
        content:
          'Para agregar la dirección de tu centro, dirigite a la Configuración > Configuración general > General > completas el campo “Dirección” y guardas.',
      },
    ],
  },

  {
    topic: 'Configuración de agenda',
    subTopics: [
      {
        title:
          'Agregué un profesional pero no aparece en la agenda / Un profesional de mi centro no puede ver su agenda',
        content:
          'Para que el profesional tenga acceso a la agenda, tiene que estar asociado a la misma. Para eso dirigite a la pestaña "Agenda", busca la agenda en la que desees incorporar un nuevo profesional  y selecciona el icono con forma de lápiz. Luego, en la pestaña “Horarios de atención” seleccioná el lápiz de edición que aparece en el horario que desees cargar el nuevo profesional. En la nueva ventana que se abre hace click en el desplegable que indica “Profesional(s)”, tildá uno o más profesionales que desees que se encuentren disponibles en esa franja horaria de la agenda y seleccioná  "Actualizar”.',
      },
      {
        title: '¿Cómo cargo los horarios en mi agenda?',
        content:
          'Para crear un horario debes dirigirte a “Agenda" > Hacé clic en el icono con forma de lápiz> Dirigite a la pestaña que indica "Horarios Laborales" en la parte superior de la ventana que se abre> Hace clic en "Crear horario laboral"> Selecciona la frecuencia, el día de la semana, los horarios, los profesionales y tildar los servicios que deseas> Hace click en "Agregar" y listo!',
      },
      {
        title: `Crear un horario del tipo 'DÍA UNICO'`,
        content:
          'Para incorporar una franja horaria especial por un único día dirigite a Agenda> Hace clic en el icono con forma de lápiz> Dirigite a la pestaña "Horarios Laborales" > Hacé clic en "Crear Horario laboral"> Selecciona la frecuencia “Único día” y el día en cuestión> Completa los datos de la franja de atención, servicios y el profesional > Hace clic en “Guardar”. Tene en cuenta que no debe superponerse con otro horario ya creado.',
      },
      {
        title: '¿Cómo bloqueo un horario en mi agenda?',
        content:
          'Para cargar un horario no laboral, dirigite a “Agenda”> Hace clic en el icono con forma de lápiz > En la solapa “Bloqueo de calendario” destildar la casilla que indica "Todo el dia" > Seleccionar el horario no laboral > Hacer click en “Agregar” > Verificar que los horarios tomados sean los correctos porque los turnos tomados se cancelaran > Hacer clic en “Confirmar”.',
      },
      {
        title: '¿Cómo cargo un dia no laboral?',
        content:
          'Para cargar un dia no laboral dirígete a “Agenda” > Hace clic en el icono con forma de lápiz > En la solapa “Bloqueo de calendario”, seleccionar desde y hasta qué día desees > Hacer click en “Agregar” > clic en “Confirmar”.',
      },
      {
        title: 'Creé un servicio y no me aparece al agendar una cita',
        content:
          'Al crear un servicio es necesario asignarlo a la(s) agenda(s), en cada horario de atención, para que se puedan reservar citas. Sino sabes cómo hacerlo te dejamos el paso a paso con imagenes.',
      },
      {
        title: '¿Se puede ver una cita eliminada?',
        content:
          'Si, debes descargar el turnero. Desde la vista "Agenda" dirigite a la esquina superior derecha y hace click en el logo que tiene una flecha hacia abajo que indica "Descarga planilla de turnos" > En la ventana que se abre selecciona desde y hasta que día deseas que se establezca la información > Clic en "Descargar” > Se descargará un archivo de Excel en donde encontrarás toda la información, así como la previa o eliminada, la cual estará marcada como tal, a modo de salvaguarda de datos.',
      },
      {
        title: '¿Qué significa el símbolo del estetoscopio en la cita?',
        content:
          'Si en la cita del paciente aparece el símbolo del estetoscopio significa que el turno fue asignado por un profesional de tu centro.',
      },
      {
        title: '¿Qué significa el símbolo de una computadora en la cita?',
        content:
          'Si tenes la web de turnos online activa, significa que el paciente tomó el turno a través de la página de citas de tu centro.',
      },
    ],
  },

  {
    topic: 'Recordatorio por Whatsapp',
    subTopics: [
      {
        title: '¿Cómo envío recordatorios de citas?',
        content:
          'Primero, asegúrate de tener el dispositivo móvil desde el que deseas enviar los recordatorios de WhatsApp a mano. Dirígete a la sección de Recordatorios en Calu y selecciona Conexión. Ahí verás un código QR en la pantalla. Escanea el código QR desde la cámara de tu dispositivo móvil, y tu WhatsApp quedará enlazado a Calu para enviar recordatorios automáticos.',
      },
      {
        title: '¿Cómo se modifica el mensaje recordatorio?',
        content:
          'Para personalizar los mensajes recordatorios dirígete a Configuración > Configuración general > Recordatorios > desliza hasta el subtitulo “WhatsApp” y completa el campo “...” con lo que desees y guardá.',
      },
      {
        title: 'No quiero que los pacientes reprogramen sus turnos',
        content:
          'Debes desactivar el link de “reprogramar” que se visualiza en el mensaje recordatorio. Para eso dirígete a Configuración > Configuración general > Recordatorios > en “Opciones generales” desactiva la opción: “Mostrar botón "Reprogramar cita" en notificaciones y emails:” y guardá.',
      },
    ],
  },
  {
    topic: 'Sitio web personalizado - plan Business',
    subTopics: [
      {
        title: '¿Cómo activo la web para que los pacientes puedan tomar turnos?',
        content:
          'Para activar la web de turnos online dirigite a la pestaña "Configuración" > "Configuración general" > "Citas online", luego de "https://citas.calu.app/" indicá una palabra o frase de fantasía de tu preferencia para que se visualice en el link, por ultimo hace clic en “Guardar”. Vas a poder compartirlo haciendo clic en "Copiar" o "Ver en QR".',
      },
      {
        title: 'Quiero que los pacientes/clientes tomen hasta solo 2 turnos por la web',
        content:
          'Para determinar la cantidad máxima de citas por pacientes/cliente configurar eso debes dirigrite a Configuración > Configuración general > Citas online > en el campo “Cantidad máxima de citas online activos por paciente” selecciona del desplegable “2 citas online” y guardá',
      },
    ],
  },
  {
    topic: 'Historial de información',
    subTopics: [
      {
        title: '¿Cómo creo una ficha de historia clínica?',
        content:
          'Para utilizar las historias clínicas primero debes cargar tu matrícula y la firma. En segundo lugar crear una ficha de historia clínica: hace clic en "Configuración" > "Historias clínicas" >  "Fichas" > selecciona "Crear tipo de ficha", completa un nombre identificatorio y selecciona todos los distintos campos que desees que esta plantilla posea además de los tres que vienen por defecto (Fecha, asunto y observaciones). Por último selecciona “Guardar” y listo, ya podes utilizarla esta ficha en todos los pacientes.',
      },
      {
        title: '¿Cómo utilizo las historias clínicas',
        content:
          'Hace clic sobre la cita del paciente > Historia clínica > clic en “Ingresar nueva ficha” > selecciona la ficha previamente creada > clic en el botón “Crear ficha” > selecciona historia clínica que desees utilizar > completa los campos > desliza hacia abajo > podrás adjuntar archivos y hasta 4 imágenes, si deseas. Al guardarla como “borrador”  podrás modificarla cuando desees. Si la guardas como “original” se volverá inalterable, pero te permitirá descargarla y compartirla.',
      },
      {
        title: '¿Cómo uso las sesiones?',
        content:
          'Primero debes crearlas: dirigite a “Configuración” > “Sesiones” > Hace click en el botón azul de la izquierda que indica “Nueva Plantilla” > Rellena los casilleros > Hace clic en "Guardar". Para utilizar una sesión debes seleccionar una cita > "Sesiones" > Hace clic en "Nuevo grupo de sesiones" > Selecciona la plantilla creada previamente > "Guardar" > Se creará una sesión que se visualizará como “Activa” > Hace clic en el mismo > Luego hace clic en “Guardar este turno como sesión de grupo de sesiones”.',
      },
      {
        title: 'Recetas digitales',
        content:
          'Para utilizar las recetas digitales primero debes cargar tu matrícula y la firma. En segundo lugar debes solicitar a Soporte que te habiliten tu usuario. Luego, selecciona la cita del paciente > Historia clínica > Recetas > nueva receta > buscar el medicamento > cantidad > diagnóstico > click en Generar receta. Una vez creada podrás compartirla al paciente por WhatsApp o por correo. Además, a través del link podrás descargarla.',
      },
    ],
  },
  {
    topic: 'Registro de pagos - plan Business',
    subTopics: [
      {
        title: '¿Cómo incorporo un pago?',
        content:
          'Con el plan Business tenes acceso a la gestión de caja. Para incorporar un pago dirigite a Gestión > selecciona el botón azul “Crear entrada” > indica el tipo de ingreso o egreso, completá el campo de “Descripción”, el “Monto a pagar” y el “Monto pagado” > Guardar.',
      },
      {
        title: '¿Cómo creo un proveedor?',
        content:
          'Dirigete a la solapa de Gestión > Proveedores > selecciona el botón azul “Crear proveedor” > completa los campos y guarda. Luego, lo podrás agregar a tus nuevos ingresos/egresos de dinero.',
      },
    ],
  },
]

export default faqList
