export const handleHasHCAccess = (usersProfileConnected, allSecretaries) => {
  let hasHCAccess = false

  if (usersProfileConnected?.isCentre) {
    hasHCAccess = true
  }
  if (usersProfileConnected?.isDoctor) {
    hasHCAccess = true
  }
  if (usersProfileConnected?.isSecretary) {
    let secretaryId = usersProfileConnected.entityInLocalStorage.secretaryCentreId
    let found = allSecretaries.find((secretary) => {
      return secretary._id === secretaryId
    })
    if (found?.hcAccess) {
      hasHCAccess = true
    }
  }

  return hasHCAccess
}
