import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postSendText } from '@/services/wapp'
import { sendReportPrescriptionByEmail } from '@/store/prescriptions/actions'
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { sendReportPrescriptionLink } from '@/utils/functions/whatsappMessages'
import { showToast } from '@/utils/toast'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ShareIcon from '@mui/icons-material/Share'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Grid, IconButton, Tooltip } from '@mui/material'

import s from '../../../styles.module.scss'

const SharePdfModule = ({ detailPrescription }) => {
  const dispatch = useDispatch()
  const { selectedTurn } = useSelector((state) => state.turns)
  const { patientSelected } = useSelector((state) => state.patientCentres)
  const [sentEmail, setSentEmail] = useState(false)
  const [sentWapp, setSentWapp] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [centerPhoneNotLinked, setCenterPhoneNotLinked] = useState(null)

  const patientCentre = selectedTurn?.patientCentre || patientSelected
  const countryCode = handleSetCountryCode(patientCentre?.country || import.meta.env.REACT_APP_COUNTRY)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected
  const deviceId = entityInLocalStorage?._id

  const handleSendReportPrescriptionByEmail = () => {
    if (!patientCentre?.email) return showToast('El paciente no tiene cargado un correo electronico', 'warning')
    let dataToSend = {
      data: {
        generatedFileUrl: detailPrescription?.generatedFileUrl,
        patientFullName: detailPrescription?.patientFullName,
      },
      to: patientCentre?.email,
    }

    dispatch(sendReportPrescriptionByEmail(dataToSend))
    setSentEmail(true)
  }

  const handleSendReportPrescriptionByWapp = async () => {
    if (!patientCentre?.mobile) {
      return showToast('El paciente no tiene número cargado', 'warning', 3000)
    }

    setIsSending(true)

    const link = detailPrescription?.generatedFileUrl
    const text = await sendReportPrescriptionLink(patientCentre, link, true)

    if (text) {
      const auxMessageData = {
        text: text,
        number: `${countryCode}${patientCentre.mobile}`,
      }
      try {
        await postSendText(deviceId, auxMessageData)
        showToast('Receta enviada correctamente', 'success')
        setSentWapp(true)
      } catch (e) {
        await sendReportPrescriptionLink(patientCentre, link, false)
        setCenterPhoneNotLinked('El envío por WhatsApp falló porque el centro no tiene un celular asociado')
      } finally {
        setIsSending(false)
      }
    }
  }

  return (
    <Grid container>
      <div className={s.divider}></div>
      <Grid item xs={9}>
        <div className={s.containerHeader}>
          <h4>Enlace de la receta para compartir</h4>
          <a href={detailPrescription?.generatedFileUrl} target="_blank" rel="noreferrer">
            {detailPrescription?.generatedFileUrl}
          </a>
        </div>
      </Grid>

      <Grid item xs={3} className={s.shareContainer}>
        <div className={s.shareItem}>
          <div>
            <ShareIcon />
          </div>
          <div>Compartir por</div>
        </div>

        <div className={s.buttonContainer}>
          <div>
            <Tooltip title="Whatsapp" placement="top">
              <IconButton className={s.paddingButton} disabled={isSending || sentWapp}>
                <WhatsAppIcon
                  className={sentWapp ? s.afterSend : s.wappIcon}
                  onClick={handleSendReportPrescriptionByWapp}
                />
              </IconButton>
            </Tooltip>
          </div>
          <div className={s.containerEmailButton}>
            <Tooltip title="Email" placement="top">
              <IconButton
                className={s.paddingButton}
                onClick={handleSendReportPrescriptionByEmail}
                disabled={isSending || sentEmail}
              >
                <MailOutlineIcon className={sentEmail ? s.afterSend : ''} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Grid>
      {centerPhoneNotLinked && (
        <Grid item xs={12} className={s.phoneNotLinkedErrorContainer}>
          <div className={s.phoneNotLinkedErrorText}>{centerPhoneNotLinked}</div>
        </Grid>
      )}
    </Grid>
  )
}

export default SharePdfModule
