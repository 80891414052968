import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'medications',
  initialState: {
    allMedications: [],
    selectedMedication: null,
    pageInfo: {
      cantidadMaxResultadosXPagina: 20,
      cantidadPaginas: 0,
      numeroPagina: 0,
      tieneMasResultados: false,
    },
  },
  reducers: {
    FETCH_MEDICATIONS: (state, { payload }) => {
      state.allMedications = payload.medicamentos
      state.pageInfo = payload.pageInfo
    },
    MEDICATION_SELECTED: (state, { payload }) => {
      state.selectedMedication = payload
    },
    RESET_MEDICATION_SELECTED: (state) => {
      state.selectedMedication = null
    },
    RESET_MEDICATIONS: (state) => {
      state.allMedications = []
      state.pageInfo = {
        cantidadMaxResultadosXPagina: 20,
        cantidadPaginas: 0,
        numeroPagina: 0,
        tieneMasResultados: false,
      }
    },
  },
})

export const { FETCH_MEDICATIONS, MEDICATION_SELECTED, RESET_MEDICATION_SELECTED, RESET_MEDICATIONS } = slice.actions
export default slice.reducer
