import * as React from 'react'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import MedicationIcon from '@mui/icons-material/Medication'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import s from './styles.module.scss'

export default function MedicationList({ item, onDelete, isDoneState }) {
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <List
      sx={{ width: '100%', bgcolor: '#e0e0e0', marginBottom: '10px' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <MedicationIcon />
        </ListItemIcon>
        <ListItemText primary={item.nombreProducto} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ bgcolor: '#e0e0e0' }} component="div" disablePadding className={s.listItem}>
          <ListItemText primary={`Nombre de droga: ${item.nombreDroga}`} />
          <ListItemText primary={`Presentación: ${item.presentacion}`} />
          <ListItemText primary={`Cantidad: ${item.cantidad}`} />
          <ListItemText primary={`Observaciones: ${item.observaciones}`} />

          <div className={s.buttonContainer}>
            <Button variant="outlined" color="secondary" onClick={() => onDelete(item.regNo)} disabled={isDoneState}>
              Eliminar
            </Button>
          </div>
        </List>
      </Collapse>
    </List>
  )
}
