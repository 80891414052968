import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchMedications = async (params) => {
  const route = `api/prescriptions/medication-search/${params.search}?page=${params?.page || 1}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { fetchMedications }
