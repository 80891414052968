import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { CaluFormAutoComplete } from '@/components/formComponents/customFields/CaluFormAutoComplete'
import LoadingSaveButton from '@/components/LoadingSaveButton'
import { getMedications } from '@/store/medications/actions'
import { deletePrescriptionAdded } from '@/store/prescriptions/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { showToast } from '@/utils/toast'
import { LoadingButton } from '@mui/lab'
import { Button, Grid } from '@mui/material'

import MedicationList from './MedicationsList'
import SharePdfModule from './SharePdfModule'

import s from '../../../styles.module.scss'

export const FormContentPrescriptions = ({
  submitLoading = false,
  detailPrescription,
  isNewPrescription,
  medications,
  setMedications,
  doctorHasPrescriptionReqs,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const { field: medicationField } = useController({ name: 'medicationData' })
  const { field: medicationQuantityField } = useController({ name: 'medicationData[cantidad]' })
  const { field: medicationObservationsField } = useController({ name: 'medicationData[observaciones]' })

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { allMedications, pageInfo } = useSelector((state) => state.medications)

  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  const isNewPrescriptionModule = !detailPrescription?.medication ? true : false
  const isDoneState = detailPrescription?.state === 'done'
  const isDraftState = detailPrescription?.state === 'draft'

  const quantityField = isNewPrescriptionModule ? 'medicationData[cantidad]' : 'quantity'
  const observationsField = isNewPrescriptionModule ? 'medicationData[observaciones]' : 'indications'

  const defaultMedicationDataValues = {
    observaciones: '',
    diagnostico: '',
    presentacion: '',
    cantidad: '',
  }

  useEffect(() => {
    if (medicationField?.value?.nombreProducto) {
      medicationField.onChange(medicationField?.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicationField?.value?.nombreProducto])

  const handleMedication = () => {
    if (!medicationField?.value?.nombreProducto) return showToast(`Debe seleccionar un medicamento`, 'error')

    const auxMedication = {
      ...medicationField?.value,
      observaciones: medicationObservationsField?.value,
      cantidad: parseInt(medicationQuantityField?.value),
    }

    if (!auxMedication?.cantidad) return showToast(`Debe especificar una cantidad`, 'error')

    setMedications([auxMedication, ...medications])
    medicationField.onChange(defaultMedicationDataValues)
    medicationQuantityField.onChange('')
    medicationObservationsField.onChange('')
  }

  const onDelete = (item) => {
    let auxMedication = medications?.filter((e) => e.regNo !== item)
    setMedications(auxMedication)
  }

  return (
    <Grid container className={s.formContainer}>
      <Grid item container>
        <Grid item xs={6} md={4} className={s.itemDate}>
          <small className={s.identifier}>{`Fecha: ${toCustomTz(
            detailPrescription?.creationDate || new Date(),
            undefined,
            false,
            'DD/MM/YYYY',
          )} `}</small>
        </Grid>
      </Grid>

      <Grid item xs={12} className={s.infoPatient}>
        <p>
          <b>{t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.Patient`)}</b>
          <span>: {detailPrescription?.patientFullName?.toUpperCase()}</span> (DNI: {detailPrescription?.patientDni})
        </p>
        <p>
          <b>{`${t('insuranceLabel.S')}`}:</b>
          <span> {detailPrescription?.patientInsurance} </span>
          {detailPrescription?.patientInsurancePlan && `(Plan: ${detailPrescription?.patientInsurancePlan})`}{' '}
          {detailPrescription?.patientPolicyNumber && `Nº Afiliado: ${detailPrescription?.patientPolicyNumber}`}
        </p>
      </Grid>

      <Grid item xs={12}>
        {!isDoneState && isNewPrescriptionModule && doctorHasPrescriptionReqs && (
          <CaluFormAutoComplete
            name="medicationData"
            stateredux={allMedications}
            functiontodispatch={getMedications}
            inputlabel={`Buscar medicamento`}
            seeMoreButton={true}
            pageInfo={pageInfo}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        {!isNewPrescriptionModule && (
          <CustomField
            label="medicamento"
            name={'medication'}
            type="textarea"
            disabled={isDoneState || !doctorHasPrescriptionReqs}
            fullWidth
          />
        )}
      </Grid>

      {(!isDoneState || (!isNewPrescriptionModule && isDoneState)) && (
        <Grid item xs={12}>
          <CustomField
            label="Cantidad *"
            name={quantityField}
            type="textarea"
            disabled={isDoneState || !doctorHasPrescriptionReqs}
            fullWidth
          />
        </Grid>
      )}

      {!isDoneState && (
        <Grid item xs={12}>
          <CustomField
            label={'Observaciones del medicamento'}
            name={observationsField}
            type="textarea"
            disabled={isDoneState || !doctorHasPrescriptionReqs}
            fullWidth
            multiline
          />
        </Grid>
      )}

      {!isDoneState && isNewPrescriptionModule && doctorHasPrescriptionReqs && (
        <Grid container className={s.addMedicationBtm}>
          <Grid item xs={3}>
            <Button onClick={handleMedication} className={s.addBtm}>
              Agregar medicamento
            </Button>
          </Grid>
        </Grid>
      )}

      {isNewPrescriptionModule && (
        <Grid item xs={12}>
          {medications?.map((e, i) => (
            <MedicationList item={e} index={i} onDelete={onDelete} isDoneState={isDoneState} />
          ))}
        </Grid>
      )}

      <Grid item xs={12}>
        <CustomField
          label="Diagnóstico general *"
          name="diagnosis"
          type="textarea"
          disabled={isDoneState || !doctorHasPrescriptionReqs}
          fullWidth
          multiline
        />
      </Grid>

      <Grid xs={12} item container spacing={1} className={s.mainContainerBtns}>
        <Grid item xs={12} md={2}>
          {isDraftState && doctorHasPrescriptionReqs && (
            <>
              <Button
                disabled={isDoneState}
                className={isDoneState ? s.disabledDelete : s.delete}
                onClick={() => setOpenAlertDelete(!openAlertDelete)}
              >
                Eliminar
              </Button>
              <AlertDialogSlide
                title="Eliminar receta"
                open={openAlertDelete}
                setOpen={setOpenAlertDelete}
                textContent={'¿Realmente quiere eliminar el borrador? No se podrá restaurar'}
                textConfirmationButton={'Eliminar'}
                onClose={() => dispatch(deletePrescriptionAdded(detailPrescription?._id))}
              />
            </>
          )}
        </Grid>

        <Grid item xs={12} md={10} container spacing={1} display="flex" justifyContent="flex-end">
          <Grid item xs={12} md={2}>
            {!isNewPrescription && isNewPrescriptionModule && doctorHasPrescriptionReqs && (
              <LoadingButton
                disabled={submitLoading}
                loading={submitLoading}
                variant="contained"
                disableElevation
                className={s.copy}
                type="submit"
                name="copia"
              >
                Crear copia
              </LoadingButton>
            )}
          </Grid>

          {(isNewPrescription || isDraftState) && isNewPrescriptionModule && doctorHasPrescriptionReqs && (
            <>
              <Grid item xs={12} md={3}>
                <LoadingSaveButton
                  isLoading={submitLoading}
                  textButton={'Generar receta'}
                  className={s.saveOriginal}
                  type="submit"
                  name="original"
                  variant="contained"
                  loadingContainer={s.setButtons}
                  disabled={!medications?.length}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <LoadingSaveButton
                  isLoading={submitLoading}
                  textButton={'Guardar borrador'}
                  className={s.save}
                  type="submit"
                  name="borrador"
                  variant="contained"
                  loadingContainer={s.setButtons}
                  disabled={!medications?.length}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      {isDoneState && (
        <Grid item xs={12}>
          <SharePdfModule detailPrescription={detailPrescription} />
        </Grid>
      )}
    </Grid>
  )
}
