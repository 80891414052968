import React, { useState } from 'react'
import { RiFileExcel2Line } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import ButtonsPagination from '@/components/ButtonsPaginations'
import useValidateURLId from '@/hooks/useValidateURLId'
import { LOADING, REMOVE_TREATMENT_DEBT } from '@/store/debts'
import { getTreatmentDebts } from '@/store/debts/actions'
import { updatePatientTreatment } from '@/store/treatments/actions'
import { xlsxFromJson } from '@/utils/excel'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { Box, Button, Chip, IconButton, Tooltip } from '@mui/material'
import {
  DataGrid,
  gridFilteredSortedRowIdsSelector,
  GridToolbarContainer,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
} from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import dayjs from 'dayjs'
import { adapterToExcel } from '../excelDataAdapter'

import s from '../../../../styles.module.scss'

const TableTreatmentsDebts = ({ patientValue, startDate, finishDate }) => {
  const centreID = useValidateURLId()
  const dispatch = useDispatch()

  const [deleteDebtAlert, setDeleteDebtAlert] = useState(false)

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const { treatmentsDebts, paginationDataTreatmentsDebts } = useSelector((state) => state.debts)

  const handleIsPaid = async (data) => {
    let auxTreatment = JSON.parse(JSON.stringify(data))
    auxTreatment.isPaid = true
    await dispatch(updatePatientTreatment(auxTreatment))
    dispatch(REMOVE_TREATMENT_DEBT(auxTreatment))
  }

  const handleChangePage = (params) => async (dispatch) => {
    dispatch(LOADING(true))
    await dispatch(getTreatmentDebts(params))
    dispatch(LOADING(false))
  }

  const handleChangePaginationNumber = async (page) => {
    let params = {
      centre: centreID,
      start: startDate,
      finish: finishDate,
      patientCentre: !patientValue ? null : patientValue?._id,
      page: page,
    }

    await dispatch(handleChangePage(params))
  }

  const showStateTreatment = (treatment) => {
    let turns = treatment?.turns?.filter((turn) => turn?.state !== 'cancelled')?.length
    let turnsCount = treatment?.turnsCount

    if (treatment?.state === 'init' && turns < turnsCount) return 'ACTIVO'
    if (treatment?.state === 'init' && turns === turnsCount) return 'FINALIZADO'
    if (treatment?.state === 'finished' && turns < turnsCount) return 'FINALIZADO INCOMPLETO'
  }

  const showTreatmentSessionsAvailable = (treatment) => {
    let pending = 0
    let completed = 0
    treatment?.turns?.map((e) => (e.state === 'init' ? pending++ : e.state === 'finished' && completed++))
    let restAsign = treatment?.turnsCount - pending - completed

    return `Total ${treatment.turnsCount} (${completed} compl, ${pending} pend, falta asignar: ${restAsign})`
  }

  const getChipProps = (params) => {
    return {
      label: params,
    }
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'patient',
      headerName: 'Paciente',
      minWidth: 200,
      ...stylesColumns,
    },
    {
      field: 'description',
      headerName: 'Descripción',
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            <Chip
              variant="filled"
              size="small"
              sx={{ backgroundColor: showStateTreatment(params?.row?.raw) === 'ACTIVO' ? '#63d2bc' : '#d3d3d3' }}
              {...getChipProps(showStateTreatment(params?.row?.raw))}
            />
            <span> {params?.row?.raw?.name?.toUpperCase()}</span>
          </div>
        )
      },
      ...stylesColumns,
    },
    {
      field: 'payment',
      headerName: 'Pagado',
      minWidth: 100,
      maxWidth: 110,
      ...stylesColumns,
    },
    {
      field: 'debt',
      headerName: 'Deuda',
      minWidth: 100,
      maxWidth: 110,
      ...stylesColumns,
    },
    {
      field: 'date',
      headerName: 'Fecha creación',
      minWidth: 120,
      maxWidth: 130,
      ...stylesColumns,
    },
    {
      field: 'sesions',
      headerName: 'Sesiones',
      minWidth: 300,
      ...stylesColumns,
    },
    {
      field: 'raw',
      headerName: '',
      minWidth: 130,
      ...stylesColumns,
      renderCell: (params) => (
        <div className={s.containerButtonsTurnsDebts}>
          <Button
            onClick={() => setDeleteDebtAlert(!deleteDebtAlert)}
            className={s.buttonTurnsDebts}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            Todo está pago
          </Button>
          <AlertDialogSlide
            open={deleteDebtAlert}
            setOpen={setDeleteDebtAlert}
            title="Marcar tratamiento como pago"
            textContent={
              'Al marcar al tratamiento como pago aparecerá que se pagó el total del importe del tratamiento.'
            }
            textBackButton="Cancelar"
            textConfirmationButton={'Confirmar'}
            onClose={() => handleIsPaid(params.value)}
          />
        </div>
      ),
    },
  ]

  const rows = treatmentsDebts?.map((entry) => {
    return {
      id: entry?._id,
      patient: `${entry?.patientCentre?.lastname?.toUpperCase()} ${entry?.patientCentre?.firstname?.toUpperCase()}`,
      description: '',
      payment: `${handleSetMoneySign(country)} ${entry?.paidPrice}`,
      debt: `${handleSetMoneySign(country)} ${entry?.price - entry?.paidPrice}`,
      date: toCustomTz(entry?.creationDate, undefined, false, 'YYYY-MM-DD') || '-',
      sesions: `${showTreatmentSessionsAvailable(entry)}`,
      raw: entry,
    }
  })

  const downloadTreatments = async (treatmentsDebts) => {
    const fileProps = {
      fileName: `Deuda-tratamientos_${dayjs().format('DD-MM-YYYY')}`,
    }

    const auxTreatment = treatmentsDebts.map((debt) => {
      return adapterToExcel(debt)
    })
    xlsxFromJson(auxTreatment, fileProps)
  }

  const getJson = (apiRef) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef)
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef)

    // Format the data. Here we only keep the value
    const data = filteredSortedRowIds.map((id) => {
      const row = {}
      visibleColumnsField.forEach((field) => {
        row[field] = apiRef.current.getCellParams(id, field).value
      })
      return row
    })

    return JSON.parse(JSON.stringify(data, null, 2))
  }

  function CustomToolbar(props) {
    const apiRef = useGridApiContext()
    return (
      <GridToolbarContainer className={s.contToolbar} {...props}>
        <Tooltip title="Descargar planilla">
          <IconButton
            className={s.printerBtn}
            onClick={() => {
              const jsonString = getJson(apiRef)
              downloadTreatments(jsonString)
            }}
          >
            <RiFileExcel2Line />
          </IconButton>
        </Tooltip>
      </GridToolbarContainer>
    )
  }

  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
      }}
    >
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        slots={{
          toolbar: CustomToolbar,
        }}
        rows={rows || []}
        columns={columns}
        hideFooter={true}
        disableRowSelectionOnClick={true}
        sx={{ fontSize: '13px' }}
        rowHeight={45}
        autoHeight={true}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row?.id}
      />
      {treatmentsDebts?.length >= 1 && (
        <ButtonsPagination
          paginationData={paginationDataTreatmentsDebts}
          handleChangePaginationNumber={handleChangePaginationNumber}
        />
      )}
    </Box>
  )
}

export default TableTreatmentsDebts
