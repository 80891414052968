import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { postNewPrescriptions, updatePrescription } from '@/store/prescriptions/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import dayjs from 'dayjs'
import { formDefaultValues } from './formConfig'
import { FormContentPrescriptions } from './formContentPrescriptions'
import { formValidationSchema } from './formValidation'

import s from '../../styles.module.scss'

const PrescriptionForm = ({
  detailPrescription,
  centreDetail,
  isNewPrescription,
  setExpanded,
  doctorHasPrescriptionReqs,
}) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [medications, setMedications] = useState([])

  useEffect(() => {
    setMedications(detailPrescription?.medicationData || [])
  }, [detailPrescription])

  const handleSubmit = async (data, e) => {
    if (!e) return
    setIsLoading(true)

    let auxData = {
      ...data,
      medicationData: medications,
    }

    delete auxData.indications
    delete auxData.quantity

    let buttonClicked = e?.nativeEvent?.submitter?.name

    auxData.centre = centreDetail._id
    auxData.date = detailPrescription?.creationDate || dayjs(new Date()).utc().format('YYYY-MM-DDT03:00:00[Z]')

    if (buttonClicked === 'original') {
      auxData.state = 'done'
    }
    if (buttonClicked === 'borrador') {
      auxData.state = 'draft'
    }

    if (buttonClicked === 'copia') {
      auxData.state = 'draft'
      delete auxData._id
      delete auxData.date
      delete auxData.creationDate
      auxData.date = dayjs(new Date()).utc().format('YYYY-MM-DDT03:00:00[Z]')
      auxData.creationDate = data.date
    }

    try {
      if (isNewPrescription) {
        await dispatch(postNewPrescriptions(auxData))
        setExpanded(false)
        e.target.reset()
      } else if (buttonClicked === 'copia') {
        await dispatch(postNewPrescriptions(auxData))
      } else {
        await dispatch(updatePrescription(auxData))
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data, e) => handleSubmit(data, e)}
        defaultValues={{
          ...formDefaultValues,
          ...detailPrescription,
        }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContentPrescriptions
          submitLoading={isLoading}
          detailPrescription={detailPrescription}
          isNewPrescription={isNewPrescription}
          medications={medications}
          setMedications={setMedications}
          doctorHasPrescriptionReqs={doctorHasPrescriptionReqs}
        />
      </ContainerForm>
    </div>
  )
}

export default PrescriptionForm
