import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useDebounce from '@/hooks/useDebounce'
import { RESET_MEDICATIONS } from '@/store/medications/index.js'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Button, CircularProgress, Grid, TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

import PropTypes from 'prop-types'
import { AutoCompleteStyles } from './styles'

import s from './styles.module.scss'

const CaluRawAutoComplete = ({ functiontodispatch, isform, stateredux, seeMoreButton, pageInfo, ...rest }) => {
  const dispatch = useDispatch()
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)

  const DELAY_TIME = 600
  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)

  useEffect(() => {
    searchEntity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inputDebounceValue])

  const searchEntity = async () => {
    let params = {
      search: inputDebounceValue,
    }
    if (rest?.centre) {
      params.centre = rest.centre
    }

    if (rest?.name === 'medicationData' && params?.search?.length < 3) {
      dispatch(RESET_MEDICATIONS())
      return
    }

    setLoading(true)
    await dispatch(functiontodispatch(params))
    setLoading(false)
  }

  const filterOptions = (options, inputValue) => {
    if (rest?.name === 'users' && inputValue?.inputValue) {
      return options?.filter((option) => {
        const searchText = inputValue?.inputValue?.toLowerCase()?.trim()
        return (
          option.email?.toLowerCase().includes(searchText) ||
          option.name?.toLowerCase().includes(searchText) ||
          (option.lastname &&
            option.firstname &&
            `${option.lastname} ${option.firstname}`.toLowerCase().includes(searchText)) ||
          option.dni?.toLowerCase().includes(searchText)
        )
      })
    }
    if (rest?.name === 'insurance') {
      return options?.filter((option) => {
        const searchText = inputValue?.inputValue?.toLowerCase()?.trim()
        return option.name?.toLowerCase().includes(searchText)
      })
    }

    return options
  }

  const renderOptions = (option) => {
    if (rest.name === 'medicationData' && !!option?.nombreProducto) {
      const auxOption = Array.isArray(option) ? option[0] : option
      return `${capitalizeFirstLetters(auxOption?.nombreProducto)} (${auxOption?.presentacion})`
    }
    if (option?.name) return option.name
    if (option?.lastname)
      return `${capitalizeFirstLetters(option.lastname)}, ${capitalizeFirstLetters(option.firstname)} (${option?._id})`
    return ''
  }

  const handleGetMoreOptions = async (direction, pageNumber) => {
    setLoadingMore(true)
    let updateCurrentPage = direction === 'prev' ? pageNumber - 1 : pageNumber + 1
    let params = {
      search: inputDebounceValue,
      page: updateCurrentPage,
    }
    await dispatch(functiontodispatch(params))
    setLoadingMore(false)
  }

  const showPagination = () => {
    return (
      <Grid container>
        <Grid item xs={6}>
          <Button
            onClick={() => handleGetMoreOptions('prev', pageInfo?.numeroPagina)}
            disabled={pageInfo?.numeroPagina === 1}
          >
            {loadingMore ? 'Cargando...' : ' < Anterior'}
          </Button>
        </Grid>
        <Grid item xs={6} className={s.nextButton}>
          <Button
            onClick={() => handleGetMoreOptions('next', pageInfo?.numeroPagina)}
            disabled={!pageInfo?.tieneMasResultados}
          >
            {loadingMore ? 'Cargando...' : 'Siguiente >'}
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {isform && <div className={s.titleInput}>{rest.inputlabel}</div>}

      <AutoCompleteStyles
        {...rest}
        onChange={(event, newValue) => {
          rest.onChange(newValue)
          setSearchText('')
        }}
        groupBy={() => (rest?.name === 'insurance' ? 'Buscar obra social...' : '')}
        autoSelect={rest?.name === 'insurance'}
        className={rest.className || ''}
        id="checkboxes-tags-demo"
        multiple={rest?.multiple}
        loading={loading || loadingMore}
        loadingText="Cargando..."
        noOptionsText={!loading && !stateredux?.length ? 'Sin opciones disponibles' : false}
        options={
          !loading && !loadingMore
            ? [...stateredux, ...(seeMoreButton && !!stateredux.length ? [{ isSeeMoreButton: true }] : [])]
            : []
        }
        disableCloseOnSelect={rest?.multiple || seeMoreButton ? true : false}
        getOptionLabel={(option) => (option?.isSeeMoreButton ? '' : renderOptions(option))}
        renderOption={(props, option, { selected }) => {
          if (option?.isSeeMoreButton && !!stateredux?.length) {
            return showPagination()
          }

          return (
            <li {...props} key={option?._id}>
              {rest.multiple ? (
                <>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {renderOptions(option)}
                </>
              ) : (
                <>{renderOptions(option)}</>
              )}
            </li>
          )
        }}
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, value) => option?._id === value?._id}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={
              isform
                ? {
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: '2px solid #0F1333',
                      '&:hover fieldset': { borderColor: '#0f1333' },
                    },
                  }
                : {}
            }
            variant={isform ? 'outlined' : 'standard'}
            onChange={(e) => setSearchText(e.target.value)}
            label={!isform ? rest.inputlabel : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && !loadingMore ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  )
}

export default CaluRawAutoComplete

CaluRawAutoComplete.propTypes = {
  stateredux: PropTypes.array.isRequired,
  inputlabel: PropTypes.string.isRequired,
  functiontodispatch: PropTypes.func.isRequired,
  className: PropTypes.string,
  centre: PropTypes.string,
  multiple: PropTypes.bool,
  seeMoreButton: PropTypes.bool,
  isform: PropTypes.string, //si es form, le da estilo calu
  onChange: PropTypes.func,
}
