import React from 'react'
import { Link } from 'react-router-dom'
import CaluFormInput from '@/components/formComponents/customFields/CaluFormInput'
import CaluButton from '@/components/styledComponents/CaluButton'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, errorInLogin }) => {
  const containsHTML = /<\/?[a-z][\s\S]*>/i.test(errorInLogin)

  return (
    <div className={s.cardContainer}>
      <div className={s.loginTitleContainer}>
        <div className={s.loginTitle}>Inicia sesión en Calu</div>
      </div>

      <div className={s.formContainer}>
        <div className={s.inputWidth}>
          <CaluFormInput labelText="Email" type="text" name="email" autoFocus enterToSubmit />
          <CaluFormInput labelText="Contraseña" type="password" name="password" enterToSubmit />
        </div>

        {errorInLogin && (
          <div className={s.errorLogin}>
            {containsHTML ? <span dangerouslySetInnerHTML={{ __html: errorInLogin }} /> : errorInLogin}
          </div>
        )}

        <CaluButton color="primary" type="submit" size="large" loading={isLoading}>
          Ingresar
        </CaluButton>

        <div className={s.registerDiv}>
          <div>
            ¿No tienes cuenta?{' '}
            <Link to={'/registro'} className={s.tagA}>
              Registrate Gratis
            </Link>
          </div>
          <div>
            <Link to={'/recuperar'} className={s.tagA}>
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
