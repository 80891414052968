import React from 'react'
import CaluButton from '@/components/styledComponents/CaluButton'
import CaluInput from '@/components/styledComponents/CaluInput'
import { CaluSelect } from '@/components/styledComponents/CaluSelect'
import { scopeAdmin } from '@/helpers/constants'
import { Grid } from '@mui/material'

import s from './styles.module.scss'

const NotificationSearchBar = ({
  addNewNotification,
  inputSearch,
  setInputSearch,
  selectedValue,
  setSelectedValue,
}) => {
  const handleChangeSelectedTag = (e) => {
    setSelectedValue(e.target.value)
  }

  return (
    <Grid container className={s.mainContainer}>
      <Grid item xs={12} lg={4}>
        <CaluInput
          id="search"
          type="text"
          autoComplete="off"
          labelText={`Buscar notificación`}
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
          className={s.searchBarContainer}
        />
      </Grid>

      <Grid container item xs={12} lg={4} className={s.selectContainer}>
        <Grid item xs={12}>
          <CaluSelect
            labelText="Filtrar notificaciones"
            value={selectedValue}
            onChange={handleChangeSelectedTag}
            items={scopeAdmin}
            className={s.inputsSelect}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} lg={4} className={s.containerButtonNewEntry}>
        <CaluButton color="primary" type="add" size="large" onClick={addNewNotification}>
          Crear notificación
        </CaluButton>
      </Grid>
    </Grid>
  )
}

export default NotificationSearchBar
