import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ClinicHistory from '@/components/ClinicHistory'
import TurnsHistory from '@/components/TurnsHistory'
import { directionOfAnimation } from '@/utils/functions/directionOfAnimation'
import { handleHasHCAccess } from '@/utils/functions/hasHCAccess'
import { hasValidDni } from '@/utils/functions/hasValidDni'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab, tabsClasses } from '@mui/material'

import TabResumen from './tabs/ResumeTurnTab'
import TurnDataForm from './tabs/TurnInfoTab'
import TreatmentTab from '../../../formCreateNewTurn/components/treatmentTab'

const DialogContentTurn = ({
  tabValue,
  setTabValue,
  setOpen,
  handleClose,
  buttonFormRef,
  showAllForm,
  isLoading,
  setIsLoading,
}) => {
  const { t } = useTranslation('global')

  const [prevValuePanel, setPrevValuePanel] = useState('1')

  const { selectedTurn } = useSelector((state) => state.turns)
  const patientCentresTurns = useSelector((state) => state.turns.patientCentresTurns)
  const centreConfiguration = useSelector((state) => state.centreConfigurations.centreConfigurationDetail)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { allSecretaries } = useSelector((state) => state.secretaryCentre)
  const optionalDni = centreConfiguration?.enableOptionalDniOnPatients

  const handleChange = (event, newValue) => {
    setPrevValuePanel(tabValue)
    setTabValue(newValue)
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Formulario actualización de turno"
            sx={{
              '& .MuiTab-root.Mui-selected': {
                color: '#009688',
              },
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            <Tab label={`${t('turnLabel.S')}`} value="1" />
            {!hasValidDni(optionalDni, selectedTurn?.patientCentre?.dni) &&
              handleHasHCAccess(usersProfileConnected, allSecretaries) && <Tab label="Historia clínica" value="2" />}
            {!hasValidDni(optionalDni, selectedTurn?.patientCentre?.dni) && <Tab label="Sesiones" value="3" />}
            {!hasValidDni(optionalDni, selectedTurn?.patientCentre?.dni) && (
              <Tab label={`Historial de ${t('turnLabel.p')}`} value="4" />
            )}
            <Tab label="Resumen" value="5" />
          </TabList>
        </Box>
        <TabPanel value="1" style={{ padding: '15px' }} className="animate__animated animate__fadeIn">
          <TurnDataForm
            buttonFormRef={buttonFormRef}
            showAllForm={showAllForm}
            handleClose={handleClose}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </TabPanel>
        <TabPanel value="2">
          <ClinicHistory
            isNewTurn={false}
            doctorCentreId={selectedTurn?.doctorCentre?._id}
            directionOfAnimation={directionOfAnimation(tabValue, prevValuePanel)}
          />
        </TabPanel>
        <TabPanel value="3">
          <TreatmentTab
            setOpenPopUp={setOpen}
            patientSelected={selectedTurn?.patientCentre}
            handleClose={handleClose}
            directionOfAnimation={directionOfAnimation(tabValue, prevValuePanel)}
          />
        </TabPanel>
        <TabPanel value="4">
          <TurnsHistory
            patientCentresTurns={patientCentresTurns}
            patientCentre={selectedTurn?.patientCentre}
            directionOfAnimation={directionOfAnimation(tabValue, prevValuePanel)}
          />
        </TabPanel>
        <TabPanel value="5">
          <TabResumen />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default DialogContentTurn
