import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '@/context/UserProvider'
import PopUpUnreadNotifications from '@/pages/AgendaPage/components/popUpUnreadNotifications/index'
import { saveFilteredUserNotifications } from '@/store/notifications/actions'

import dayjs from 'dayjs'

const diffDaysSinceCreationAccount = (currentDate, creationDate) => {
  return dayjs(currentDate).diff(dayjs(creationDate), 'day')
}

const UserNotifications = () => {
  const dispatch = useDispatch()
  const [availableToShowNotifications, setAvailableToShowNotifications] = useState(false)
  const [popUpUnreadNotifications, setPopUpUnreadNotifications] = useState(false)
  const [unreadNotifications, setUnreadNotifications] = useState([])

  const userNotifications = useSelector((state) => state.notifications.notifications)
  const userNotificationReads = useSelector((state) => state.notifications.notificationReads)
  const allNotifications = useSelector((state) => state.notifications.allNotifications)
  const allDoctorCentres = useSelector((state) => state.doctorCentres?.doctorCentres)
  const allMachines = useSelector((state) => state.machines?.allMachines)
  const { availableNotifications } = useSelector((state) => state.notifications)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isAdmin, isPatient } = usersProfileConnected
  const { user } = useUser()

  const accountCreationDate = !isPatient ? centreDetail?.creationDate : user?.creationDate
  const currentDate = dayjs()

  const hasEntities = allMachines?.length > 0 || allDoctorCentres?.length > 0
  const canSeeNotifications = isPatient || hasEntities

  const daysSinceCreationAccount = useMemo(() => {
    return diffDaysSinceCreationAccount(currentDate, accountCreationDate)
  }, [currentDate, accountCreationDate])

  const filteredUserNotifications = useMemo(() => {
    if (!userNotifications || !userNotificationReads) return []

    return userNotifications.map((n) => ({
      ...n,
      isRead: userNotificationReads.some((r) => r.notification._id === n._id),
    }))
  }, [userNotifications, userNotificationReads])

  const filteredUnreadNotifications = useMemo(() => {
    return allNotifications?.filter((notification) => !notification.isRead) || []
  }, [allNotifications])

  useEffect(() => {
    let TOTAL_DAYS = 2
    if (daysSinceCreationAccount > TOTAL_DAYS && canSeeNotifications) {
      setAvailableToShowNotifications(true)
    }
  }, [daysSinceCreationAccount, canSeeNotifications])

  useEffect(() => {
    if (availableToShowNotifications && filteredUserNotifications?.length > 0) {
      dispatch(saveFilteredUserNotifications(filteredUserNotifications))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableToShowNotifications, filteredUserNotifications])

  useEffect(() => {
    if (availableToShowNotifications && filteredUnreadNotifications.length > 0) {
      setUnreadNotifications(filteredUnreadNotifications)
      setPopUpUnreadNotifications(true)
    }
  }, [availableToShowNotifications, filteredUnreadNotifications])

  return (
    <>
      {popUpUnreadNotifications && unreadNotifications.length > 0 && availableNotifications && !isAdmin && (
        <PopUpUnreadNotifications
          open={popUpUnreadNotifications}
          setOpen={setPopUpUnreadNotifications}
          unreadNotifications={unreadNotifications}
        />
      )}
    </>
  )
}

export default UserNotifications
