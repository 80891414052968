import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiFileExcel2Line } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { PATIENT_CENTRE_SELECTED } from '@/store/patientCentres'
import { handlePaginationChangePatientCentres } from '@/store/patientCentres/actions'
import { createWappReminder, sendWappMessage } from '@/store/wapp/actions'
import { xlsxFromJson } from '@/utils/excel'
import { getAge } from '@/utils/functions/getAge'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { replaceHTMLTags } from '@/utils/functions/replaceHTMLTags'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { sendBirthdayMessage, sendWhatsappEmpty } from '@/utils/functions/whatsappMessages'
import { showToast } from '@/utils/toast'
import CakeIcon from '@mui/icons-material/Cake'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import {
  DataGrid,
  gridFilteredSortedRowIdsSelector,
  GridToolbarContainer,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
} from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import dayjs from 'dayjs'
import { downloadPatientsTableAdapter } from '../patientsSearchBar/downloadPatientsTableAdapter'

import s from '../../styles.module.scss'

const PatientsTable = ({ setOpen, searchText, selectedTag, selectedBirth }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const [rowId, setRowId] = useState('')
  const [isSendingWappMessage, setIsSendingWappMessage] = useState(null)
  const [selectedButton, setSelectedButton] = useState(null)
  const { status: wappStatus, status } = useSelector((state) => state.wapp)
  const { allTemplates } = useSelector((state) => state.wappMessageTemplates)

  const {
    patientCentres,
    paginationData,
    isLoading: patientCentresIsLoading,
  } = useSelector((state) => state?.patientCentres)

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const { limit, totalDocs, page } = paginationData || {}

  const handleTagsData = (arrayTags) => {
    let arrayTagsNames = arrayTags?.map((tag) => tag.name)
    return arrayTagsNames.join().replace(/,/g, ', ')
  }

  const handleSendWhatsApp = (data) => {
    sendWhatsappEmpty(data?.value, centreDetail)
  }

  const handleClick = (data) => {
    dispatch(PATIENT_CENTRE_SELECTED(data))
    setOpen(true)
  }

  const handleCheckBirthdate = (birth) => {
    if (!birth) return false
    const currentDate = dayjs().format('DD-MM')
    const birthdate = dayjs(birth).utc().format('DD-MM')

    if (currentDate === birthdate) return true
    return false
  }

  const handleCellClick = (params, event) => {
    if (
      event.target.parentElement.id === 'whatsAppButton' ||
      event.target.ariaLabel === 'whatsAppButton' ||
      params.field === 'birth'
    ) {
      return
    } else {
      handleClick(params?.row?.raw)
    }
  }

  const handleSendBirthdayMessage = async (patientData) => {
    setSelectedButton(patientData._id)
    if (wappStatus?.status === 'connected') {
      setIsSendingWappMessage(true)
      const isWappConnected = true
      const countryCode = handleSetCountryCode(
        patientData?.country || centreDetail?.country || import.meta.env.REACT_APP_COUNTRY,
      )
      let text = ''
      if (!!allTemplates?.length) {
        let validReplacements = {
          '[apellido]': patientData?.lastname.toUpperCase(),
          '[nombre]': patientData?.firstname.toUpperCase(),
          '[nombre-centro]': centreDetail?.name?.toUpperCase(),
        }
        let template = allTemplates[0]?.body

        Object.keys(validReplacements).forEach(function (r) {
          template = template?.replaceAll(r, validReplacements[r])
        })
        template = template?.replaceAll(/&nbsp;/g, '\n')
        text = replaceHTMLTags(template)
      } else {
        text = sendBirthdayMessage(patientData, centreDetail, isWappConnected)
      }

      const auxMessageData = {
        text: text,
        number: `${countryCode}${patientData.mobile}`,
      }

      try {
        const sentStatusInfo = await dispatch(sendWappMessage(centreDetail._id, auxMessageData))
        const attemptData = {
          centre: centreDetail._id,
          patientCentre: patientData._id,
          message: auxMessageData?.text,
          messageType: 'Saludo de cumpleaños',
          status: sentStatusInfo?.status === 1 ? 'sent' : 'error',
          mobile: `${countryCode}${patientData.mobile}`,
          senderNumber: status.phoneNumber,
        }
        await dispatch(createWappReminder(attemptData))
        showToast('Mensaje de cumpleaños enviado correctamente', 'success', 2000)
        setIsSendingWappMessage(false)
      } catch (e) {
        console.log(e)
      }
      return
    }
    sendBirthdayMessage(patientData, centreDetail)
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'center',
    headerAlign: 'left',
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      flex: 1,
    },
    {
      field: 'DI',
      headerName: 'DI',
      minWidth: 110,
      maxWidth: 150,
      ...stylesColumns,
    },
    {
      field: 'fullname',
      headerName: 'Apellido y Nombre',
      minWidth: 160,
      ...stylesColumns,
    },
    {
      field: 'mobile',
      headerName: 'Teléfono',
      minWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 180,
      maxWidth: 220,
      ...stylesColumns,
    },
    {
      field: 'birth',
      headerName: 'Fecha de nacimiento',
      minWidth: 130,
      renderCell: (params) => {
        if (handleCheckBirthdate(params?.row?.raw?.birth)) {
          return (
            <LoadingButton
              variant="contained"
              className={s.birthButton}
              startIcon={<CakeIcon style={{ color: 'black' }} />}
              id="basic-button"
              onClick={() => handleSendBirthdayMessage(params?.row?.raw)}
              disabled={params?.row?.raw?._id === selectedButton ? isSendingWappMessage : null}
              loading={params?.row?.raw?._id === selectedButton ? isSendingWappMessage : false}
            >
              {params.value}
            </LoadingButton>
          )
        } else {
          return params?.value
        }
      },
      ...stylesColumns,
    },
    {
      field: 'age',
      headerName: 'Edad',
      minWidth: 90,
      maxWidth: 130,
      sortComparator: (v1, v2) => {
        let auxV1 = v1.split(' ')[0]
        let auxV2 = v2.split(' ')[0]
        auxV1 = auxV1 === '-' ? 0 : Number(auxV1)
        auxV2 = auxV2 === '-' ? 0 : Number(auxV2)

        if (auxV1 > auxV2) {
          return 1
        } else {
          return -1
        }
      },
      ...stylesColumns,
    },
    {
      field: 'tag',
      headerName: 'Etiquetas',
      minWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'raw',
      headerName: '',
      minWidth: 150,
      maxWidth: 180,
      ...stylesColumns,
      renderCell: (params) => (
        <>
          <Tooltip title="Editar" placement="top">
            <Button
              onClick={() => handleClick(params?.value)}
              className={rowId === params.value._id ? s.rowHoverButton : s.rowButton}
              tabIndex={params.hasFocus ? 0 : -1}
            >
              <EditOutlinedIcon />
            </Button>
          </Tooltip>

          {!!params?.value?.mobile && (
            <Tooltip title="Enviar whatsApp" placement="top">
              <Button
                aria-label="whatsAppButton"
                id="whatsAppButton"
                onClick={() => handleSendWhatsApp(params)}
                className={rowId === params.value._id ? s.rowHoverButton : s.rowButton}
                tabIndex={params.hasFocus ? 0 : -1}
              >
                <WhatsAppIcon />
              </Button>
            </Tooltip>
          )}
        </>
      ),
    },
  ]

  const rows = patientCentres?.map((entry) => {
    return {
      id: entry?.id,
      DI: entry?.dni?.length === 15 ? '-' : entry?.dni,
      fullname: `${entry?.lastname?.toUpperCase()}, ${entry?.firstname?.toUpperCase()}`,
      mobile: entry?.mobile || '-',
      email: entry?.email || '-',
      birth: entry?.birth ? `${dayjs(entry?.birth).utc().format('YYYY-MM-DD')}` : '-',
      age: entry?.birth ? `${getAge(entry?.birth) !== 0 ? `${getAge(entry?.birth)} Años` : '-'}` : '-',
      tag: entry?.patientTags?.length === 0 ? '-' : handleTagsData(entry?.patientTags),
      raw: entry,
    }
  })

  const handleRowOver = (e) => {
    const rowId = e.currentTarget.dataset.id
    const row = rows.find((el) => el.id === rowId)
    setRowId(row.id)
  }

  const downloadTable = async (patientCentresList) => {
    let tabName = `Planilla_${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`)}`
    let startDate = dayjs(new Date()).startOf('day').format()
    const fileProps = {
      fileName: `${tabName}_${toCustomTz(startDate, undefined, false, 'DD-MM-YYYY')}`,
    }

    if (patientCentresList?.length === 0) return showToast('No hay items para imprimir !', 'warning')

    const buildExcelData = patientCentresList?.map((entry) => {
      return downloadPatientsTableAdapter(entry)
    })
    xlsxFromJson(buildExcelData, fileProps)
  }

  const getJson = (apiRef) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef)
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef)

    // Format the data. Here we only keep the value
    const data = filteredSortedRowIds.map((id) => {
      const row = {}
      visibleColumnsField.forEach((field) => {
        row[field] = apiRef.current.getCellParams(id, field).value
      })
      return row
    })

    return JSON.parse(JSON.stringify(data, null, 2))
  }

  function CustomToolbar(props) {
    const apiRef = useGridApiContext()
    return (
      <GridToolbarContainer className={s.contToolbar} {...props}>
        <Tooltip title="Descargar planilla">
          <IconButton
            className={s.printerBtn}
            onClick={() => {
              const jsonString = getJson(apiRef)
              downloadTable(jsonString)
            }}
          >
            <RiFileExcel2Line />
          </IconButton>
        </Tooltip>
      </GridToolbarContainer>
    )
  }

  const handlePaginationChange = ({ page, pageSize }) => {
    dispatch(handlePaginationChangePatientCentres(page + 1, pageSize, searchText, selectedTag, selectedBirth))
  }

  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          background: '#fff',
        },
      }}
    >
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        slots={{
          toolbar: CustomToolbar,
        }}
        rows={rows || []}
        rowCount={totalDocs || 0}
        loading={patientCentresIsLoading}
        paginationMode="server"
        columns={columns}
        pagination
        onCellClick={(params, event) => handleCellClick(params, event)}
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[30, 50, 100]}
        disableRowSelectionOnClick={true}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        slotProps={{
          pagination: {
            labelRowsPerPage: 'Entradas por página:',
            labelDisplayedRows: () =>
              `${(page - 1) * limit + 1} - ${page * limit > totalDocs ? totalDocs : page * limit} de ${totalDocs}`,
          },
          row: {
            onMouseEnter: handleRowOver,
          },
        }}
        sx={{
          fontSize: '13px',
          background: '#fff',
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell ': {
            display: 'flex',
            justifyContent: 'flex-start',
          },
        }}
        rowHeight={45}
        autoHeight={true}
      />
    </Box>
  )
}

export default PatientsTable
