import React, { useEffect, useState } from 'react'
import Layout1 from '@/layouts/Layout1'

import AcordionQuestions from './components/accordionQuestions'
import faqsData from './components/faqData.js'
import SearchQuestion from './components/searchQuestion'

import s from './styles.module.scss'

const FAQsPage = () => {
  const [input, setInput] = useState('')
  const [questionsFiltered, setQuestionsFiltered] = useState(faqsData)
  const [expanded, setExpanded] = useState({})

  const handleInputChange = (e) => {
    setInput(e.target.value)
  }

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded((prevState) => ({
      ...prevState,
      [panel]: isExpanded ? panel : false,
    }))
  }

  useEffect(() => {
    if (input.trim() === '') {
      setQuestionsFiltered(faqsData)
      setExpanded({})
    } else {
      const filteredQuestions = faqsData.filter((obj, index) => {
        const topicMatch = obj.topic
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            input
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase(),
          )

        const subTopicMatch = obj.subTopics.some((subTopic) =>
          subTopic.title
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(
              input
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase(),
            ),
        )

        return topicMatch || subTopicMatch
      })

      setQuestionsFiltered(filteredQuestions)

      const newExpanded = {}
      filteredQuestions.forEach((question, index) => {
        newExpanded[`panel${index}`] = `panel${index}`
        question.subTopics.forEach((subTopic, subIndex) => {
          if (subTopic.title.toLowerCase().includes(input.toLowerCase())) {
            newExpanded[`panel${index}-${subIndex}`] = `panel${index}-${subIndex}`
          }
        })
      })
      setExpanded(newExpanded)
    }
  }, [input])

  return (
    <Layout1 navbar>
      <div className={s.contentBlock}>
        <div className={s.header}>
          <div className={s.mainTitle}>Calu respuestas</div>
          <div className={s.subTitle}>
            Si todavía tienes dudas sobre cómo Calu puede ayudarte a impulsar tu trabajo, estamos para resolverlas.
          </div>
        </div>

        <SearchQuestion input={input} handleChange={handleInputChange} />
        <AcordionQuestions faqsData={questionsFiltered} expanded={expanded} onAccordionChange={handleAccordionChange} />
      </div>
    </Layout1>
  )
}

export default FAQsPage
