import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiFileExcel2Line } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import ButtonsPagination from '@/components/ButtonsPaginations'
import useValidateURLId from '@/hooks/useValidateURLId'
import { LOADING } from '@/store/debts'
import { getTurnsDebts } from '@/store/debts/actions'
import { updatePatientDebt } from '@/store/turns/actions'
import { xlsxFromJson } from '@/utils/excel'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import {
  DataGrid,
  gridFilteredSortedRowIdsSelector,
  GridToolbarContainer,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
} from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import dayjs from 'dayjs'
import PopUpPayment from './components/PopUpPayment'
import { adapterToExcel } from '../excelDataAdapter'

import s from '../../../../styles.module.scss'

const TableTurnsDebts = ({ patientValue, startDate, finishDate }) => {
  const centreID = useValidateURLId()
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()
  const { turnsDebts, paginationDataTurnsDebts } = useSelector((state) => state.debts)

  const [openPopUp, setOpenPopUp] = useState(false)
  const [selectedTurn, setSelectedTurn] = useState(null)

  const handleDataTurn = (data) => {
    let auxTurn = JSON.parse(JSON.stringify(data))
    setOpenPopUp(true)
    setSelectedTurn(auxTurn)
  }

  const handleClickAuthorization = (data) => {
    let auxTurn = JSON.parse(JSON.stringify(data))
    auxTurn.insuranceAuthorization = true
    dispatch(updatePatientDebt(auxTurn, true))
  }

  const handleChangePage = (params) => async (dispatch) => {
    dispatch(LOADING(true))
    await dispatch(getTurnsDebts(params))
    dispatch(LOADING(false))
  }

  const handleChangePaginationNumber = async (page) => {
    let params = {
      centre: centreID,
      start: startDate,
      finish: finishDate,
      patientCentre: !patientValue ? null : patientValue?._id,
      page: page,
    }

    await dispatch(handleChangePage(params))
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'patient',
      headerName: 'Paciente',
      minWidth: 200,
      ...stylesColumns,
    },
    {
      field: 'mobile',
      headerName: 'Teléfono',
      minWidth: 150,
      type: 'text',
      ...stylesColumns,
    },
    {
      field: 'date',
      headerName: `Fecha ${t('turnLabel.s')}`,
      minWidth: 150,
      ...stylesColumns,
    },
    {
      field: 'debt',
      headerName: 'Deuda',
      minWidth: 130,
      type: 'number',
      ...stylesColumns,
    },
    {
      field: 'raw',
      headerName: '',
      ...stylesColumns,
      renderCell: (params) => (
        <div className={`${s.containerButtonsTurnsDebts} ${s.rawContainer}`}>
          {!params?.value?.insuranceAuthorization && (
            <Button
              onClick={() => handleClickAuthorization(params.value)}
              className={s.buttonTurnsDebts}
              tabIndex={params.hasFocus ? 0 : -1}
            >
              Ya autorizó
            </Button>
          )}

          {params?.value?.debt > 0 && (
            <Button
              onClick={() => handleDataTurn(params.value)}
              className={s.buttonTurnsDebts}
              tabIndex={params.hasFocus ? 0 : -1}
            >
              Registrar pago
            </Button>
          )}
        </div>
      ),
    },
  ]

  const rows = turnsDebts?.map((entry) => {
    return {
      id: entry?._id,
      patient: `${entry?.patientCentre?.lastname?.toUpperCase()} ${entry?.patientCentre?.firstname?.toUpperCase()}`,
      mobile: entry?.patientCentre?.mobile || entry?.patientCentre?.telephone || '-',
      date: toCustomTz(entry?.startHour, undefined, false, 'YYYY-MM-DD') || '-',
      debt:
        entry?.debt > 0 && !entry?.insuranceAuthorization
          ? `${handleSetMoneySign(country)} ${entry?.debt} | Bono sin autorización`
          : entry?.debt === 0 && !entry?.insuranceAuthorization
            ? `Bono sin autorización`
            : `${handleSetMoneySign(country)} ${entry?.debt}`,
      raw: entry,
    }
  })

  const downloadTreatments = async (turnsDebts) => {
    const fileProps = {
      fileName: `Deuda-turnos_${dayjs().format('DD-MM-YYYY')}`,
    }

    const auxTurnsDebts = turnsDebts.map((debt) => {
      return adapterToExcel(debt)
    })
    xlsxFromJson(auxTurnsDebts, fileProps)
  }

  const getJson = (apiRef) => {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef)
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef)

    // Format the data. Here we only keep the value
    const data = filteredSortedRowIds.map((id) => {
      const row = {}
      visibleColumnsField.forEach((field) => {
        row[field] = apiRef.current.getCellParams(id, field).value
      })
      return row
    })

    return JSON.parse(JSON.stringify(data, null, 2))
  }

  function CustomToolbar(props) {
    const apiRef = useGridApiContext()
    return (
      <GridToolbarContainer className={s.contToolbar} {...props}>
        <Tooltip title="Descargar planilla">
          <IconButton
            className={s.printerBtn}
            onClick={() => {
              const jsonString = getJson(apiRef)
              downloadTreatments(jsonString)
            }}
          >
            <RiFileExcel2Line />
          </IconButton>
        </Tooltip>
      </GridToolbarContainer>
    )
  }

  const handleClosePopUp = () => {
    setOpenPopUp(false)
    setSelectedTurn(null)
  }

  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
      }}
    >
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        slots={{
          toolbar: CustomToolbar,
        }}
        rows={rows || []}
        columns={columns}
        hideFooter={true}
        disableRowSelectionOnClick={true}
        sx={{ fontSize: '13px' }}
        rowHeight={45}
        autoHeight={true}
        getRowId={(row) => row?.id}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      />
      {turnsDebts?.length >= 1 && (
        <ButtonsPagination
          paginationData={paginationDataTurnsDebts}
          handleChangePaginationNumber={handleChangePaginationNumber}
        />
      )}
      {openPopUp && <PopUpPayment open={openPopUp} onClose={handleClosePopUp} selectedTurn={selectedTurn} />}
    </Box>
  )
}

export default TableTurnsDebts
