import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { scroller } from 'react-scroll'
import ClinicHistory from '@/components/ClinicHistory'
import LoaderPercentage from '@/components/LoaderPercentage'
import TurnsHistory from '@/components/TurnsHistory'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { deviceDetector } from '@/helpers/deviceDetector'
import useDebounce from '@/hooks/useDebounce'
import useValidateURLId from '@/hooks/useValidateURLId.js'
import { fetchAbsentPatientTurns } from '@/services/turns'
import { resetSelectedDoctorCentre } from '@/store/doctorCentres/actions'
import { resetSelectedMachine } from '@/store/machines/actions'
import {
  clearPatientCentres,
  createPatientAndCreateNewTurn,
  getPatientsCentreByInputSearch,
  updatePatientAndCreateNewTurn,
} from '@/store/patientCentres/actions'
import { getAllPatientTags } from '@/store/patientTags/actions'
import { getPatientCentreTurns } from '@/store/turns/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { directionOfAnimation } from '@/utils/functions/directionOfAnimation/index.js'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { handleSetDniLabel } from '@/utils/functions/handleSetDniLabel/index.js'
import { handleWappReminder } from '@/utils/functions/handleWappReminders'
import { handleHasHCAccess } from '@/utils/functions/hasHCAccess'
import { toLocalTz } from '@/utils/functions/toLocalTz/index.js'
import { showToast } from '@/utils/toast'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  tabsClasses,
  TextField,
} from '@mui/material'

import dayjs from 'dayjs'
import TreatmentTab from './components/treatmentTab'
import AddPaymentForm from './components/turnTab/AddPaymentForm'
import InvoiceTurn from './components/turnTab/InvoiceTurn.jsx'
import PatientDataForm from './components/turnTab/PatientDataForm'
import PaymentDataForm from './components/turnTab/PaymentDataForm'
import ProductsForm from './components/turnTab/ProductsForm.jsx'
import SaveButtonTurn from './components/turnTab/SaveButtonTurn'
import TurnDataForm from './components/turnTab/TurnDataForm'
import TurnDialogHeader from '../components/TurnDialogHeader'

import s from './styles.module.scss'

const FormCreateNewTurn = ({ entity, openPopUp, setOpenPopUp, handleConfigDoctorCentresPanelClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const { socket } = useSocket()
  const [treatmentSection, setTreatmentSection] = useState(false)
  const { patientCentres } = useSelector((state) => state.patientCentres)
  const patientCentresTurns = useSelector((state) => state.turns.patientCentresTurns)
  const [patientSelected, setPatientSelected] = useState(null)
  const [patientCentresCopy, setPatientCentresCopy] = useState([])
  const [absentPercentage, setAbsentPercentage] = useState(null)
  const { doctorCentreSelected: doctorCentres, doctorCentres: allDoctorCentres } = useSelector(
    (state) => state.doctorCentres,
  )
  const { machineSelected } = useSelector((state) => state?.machines)
  const { user } = useUser()
  const centre = useSelector((state) => state.centres.centreProfile)
  const { status: wappStatus } = useSelector((state) => state.wapp)
  const centreId = useValidateURLId()
  let isMobile = deviceDetector()
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isDoctor } = usersProfileConnected
  const { allSecretaries } = useSelector((state) => state.secretaryCentre)

  //configuracion del popup
  const [tabValue, setTabValue] = useState('1')
  const [prevValuePanel, setPrevValuePanel] = useState('1')

  //sección datos del pago
  const [quantityExtraAmount, setQuantityExtraAmount] = useState(0)

  //sección agregar pago
  const [amount, setAmount] = useState(doctorCentres?.price || 0)
  const [extraAmount, setExtraAmount] = useState(null)
  const [addExtraAmount, setAddExtraAmount] = useState(0)
  const [debt, setDebt] = useState(doctorCentres?.price || 0)
  const [extraDebt, setExtraDebt] = useState(null)
  const [paymentDate, setPaymentDate] = useState(dayjs())
  const [paymentAmount, setPaymentAmount] = useState()
  const [paymentHistory, setPaymentHistory] = useState([])

  //seccion datos del turno
  const [startHourTurn, setStartHourTurn] = useState(0)
  const [startMinuteTurn, setStartMinuteTurn] = useState(0)
  const [durationEvent, setDurationEvent] = useState(0)
  const [durationTurn, setDurationTurn] = useState(0)
  const [selectedDateTurnInCalendar, setSelectedDateTurnInCalendar] = useState('')

  const patient = patientCentres?.filter((e) => e._id === patientSelected?._id) //filtra por id a patientCentres
  const descriptionElementRef = useRef(null)
  const refSubmitButton = useRef()
  const refSubmitButtonSendWhatsapp = useRef()
  const [searchText, setSearchText] = useState('')
  const [showAllForm, setShowAllForm] = useState(null)

  const [inputsInsurances, setInputsInsurances] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const DELAY_TIME = 300
  const MIN_CHARACTERS = 2
  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)
  const [service, setService] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedInsurance, setSelectedInsurance] = useState('-')

  const [checked, setChecked] = useState(false)
  const [weeksSelected, setWeeksSelected] = useState(0)
  const [creatingMultipleTurns, setCreatingMultipleTurns] = useState(false)
  const allTurns = useSelector((state) => state.turns.allTurns)

  const [patientValue, setPatientValue] = useState(null)
  const [patientValueInputLastname, setPatientValueInputLastname] = useState(null)
  const [copiedTurnValue, setCopiedTurnValue] = useState(null)
  const [copiedTurnSelected, setCopiedTurnSelected] = useState(null)

  const [disabledInputDni, setDisabledInputDni] = useState(false)
  const [disabledInputLastname, setDisabledInputLastname] = useState(false)
  const [productsForm, setProductsForm] = useState([])
  const [expandPopUp, setExpandPopUp] = useState(false)
  const now = dayjs().utc('Z').format()
  const isOver = dayjs(selectedDateTurnInCalendar || entity?.selectedDate).isBefore(dayjs(now))
  const country = centre?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm()

  const insurance = watch('insurance')
  const watchService = watch('service')
  const dataTurn = watch(getValues())
  const watchCountry = watch('country')

  const turnTreatmentFromAgenda = {
    infoToCreateTurn: dataTurn,
    centreId: centreId,
    durationTurn: durationTurn,
    patientSelected: patientSelected,
    entityId: entity,
  }

  useEffect(() => {
    if (patientSelected) {
      dispatch(getPatientCentreTurns(centreId, patientSelected?._id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSelected])

  useEffect(() => {
    if (!watchService?.duration) {
      setDurationTurn(entity?.lastDurationTurn)
      setDurationEvent(entity?.lastDurationTurn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  useEffect(() => {
    dispatch(getAllPatientTags(centreId))
  }, [centreId, dispatch])

  useEffect(() => {
    if (patientValue) {
      const filteredPatientCentre = patientCentres?.filter((e) => e._id === patientValue)[0]
      if (filteredPatientCentre) {
        setPatientSelected(filteredPatientCentre)
        setSearchText(filteredPatientCentre?.lastname)
      }
      setPatientValueInputLastname(null)
      setPatientCentresCopy([])
      setDisabledInputLastname(false)
      setDisabledInputDni(false)
      setCopiedTurnValue(null)
    }
    if (copiedTurnValue !== null) {
      const filteredTurn = allTurns.filter((e) => e._id === copiedTurnValue)[0]
      const auxCopiedTurn = {
        ...filteredTurn,
        state: 'init',
        paymentHistory: [],
        totalPayments: 0,
      }
      delete auxCopiedTurn.amount
      delete auxCopiedTurn.debt
      delete auxCopiedTurn.extraAmount
      setCopiedTurnSelected(auxCopiedTurn)
    }
  }, [patientValue, patientCentres, copiedTurnValue, allTurns])

  useEffect(() => {
    if (patientSelected) {
      setPaymentHistory([])
      Object.keys(patientSelected).forEach((k) => {
        if (patientSelected.dni.length === 15) {
          setValue('dni', '')
        }
        if (!patientSelected.birth) {
          setValue('birth', null)
        }

        if (patientSelected.birth) {
          setValue('birth', dayjs(toLocalTz(patientSelected.birth)))
        }
        if (patientSelected.dni.length === 15) {
          setValue('dni', '')
        }

        if (k === 'insurances') {
          if (patientSelected[k].length === 0) {
            setValue('insurance', { _id: '-', name: 'Particular' })
          } else {
            setValue('insurance', patientSelected[k][0])
          }
        }

        if (k === 'insurancePlan') {
          setValue('insurancePlan', patientSelected[k][0])
        }
        if (k === 'policyNumbers') {
          setValue('policyNumber', patientSelected[k][0])
        }
        if (k === 'patientTags') {
          setValue('patientTags', patientSelected[k])
        }

        setValue(k, patientSelected[k])

        if (!patientSelected.telephone) {
          setValue('telephone', '')
        }
      })
    } else {
      setValue('insurance', { _id: '-', name: 'Particular' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSelected, doctorCentres])

  useEffect(() => {
    if (copiedTurnSelected !== null) {
      const selectedTurn = { ...copiedTurnSelected } || {}
      Object.keys(selectedTurn).forEach((k) => {
        if (k === 'service' && !selectedTurn.machine) {
          if (selectedTurn[k] === null && selectedTurn.firstTime === false) {
            setValue('service', { _id: 'consultation', name: 'Consulta' })
            return
          }
          if (selectedTurn[k] === null && selectedTurn.firstTime === true) {
            setValue('service', { _id: 'firstConsultation', name: 'Primera consulta' })
            return
          }
          if (selectedTurn[k] !== null) {
            setValue('service', selectedTurn[k])
            return
          }
        }

        if (k === 'service' && selectedTurn.machine) {
          if (selectedTurn[k] === null) {
            setValue('service', { _id: null, name: '-' })
            return
          } else {
            setValue('service', selectedTurn[k])
            return
          }
        }

        if (k === 'insurance') {
          if (selectedTurn[k] === null) {
            setValue('insurance', '-')
            return
          } else {
            setValue('insurance', selectedTurn[k]._id)
            return
          }
        }

        if (selectedTurn.machine && selectedTurn.doctorCentre) {
          setValue('doctorCentre', selectedTurn.doctorCentre._id)
          return
        }
        if (selectedTurn.machine && !selectedTurn.doctorCentre) {
          setValue('doctorCentre', '-')
          return
        }
        if (k === 'patientCentre') {
          setValue('patientTags', selectedTurn[k].patientTags)
          return
        }

        if (k === 'patientCentre') {
          setValue('note', selectedTurn[k].note)
          return
        }
        setAmount(selectedTurn.amount || 0)
        setDebt(selectedTurn.debt || 0)
        setValue(k, selectedTurn[k])
      })

      if (selectedTurn?.patientCentre) {
        Object.keys(selectedTurn.patientCentre).forEach((k) => {
          if (k === 'patientTags') {
            setValue('patientTags', selectedTurn.patientCentre[k])
          }
          setValue(k, selectedTurn.patientCentre[k])
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copiedTurnSelected])

  useEffect(() => {
    if (copiedTurnSelected?.paymentHistory) {
      setPaymentHistory(JSON.parse(JSON.stringify(copiedTurnSelected?.paymentHistory)))
    }
  }, [copiedTurnSelected?.paymentHistory])

  useEffect(() => {
    if (insurance?._id === '-') {
      setInputsInsurances(null)
      return
    }

    setInputsInsurances(insurance)
    let patient
    let policyIndex

    if (patientSelected) {
      policyIndex = patientSelected.insurances.findIndex((e) => e._id === insurance?._id)
      patient = patientSelected
    }

    if (copiedTurnSelected) {
      policyIndex = copiedTurnSelected.patientCentre.insurances.findIndex((e) => e._id === insurance?._id)
      patient = copiedTurnSelected.patientCentre
    }

    if (patientSelected || copiedTurnSelected) {
      if (policyIndex !== -1) {
        setValue('policyNumber', patient.policyNumbers[policyIndex] || '')
        setValue('insurancePlan', patient.insurancePlan[policyIndex] || '')
      } else {
        setValue('insurancePlan', '')
        setValue('policyNumber', '')
      }
    }
  }, [insurance, patientSelected, setValue, copiedTurnSelected])

  useEffect(() => {
    if (patientSelected && centre?.showPatientAbsent) {
      getAbsentTurnsPercentage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSelected, centre])

  const searchingPatients = async () => {
    const params = {
      centre: centreId,
      search: inputDebounceValue,
    }
    setLoading(true)
    await dispatch(getPatientsCentreByInputSearch(params))
    setLoading(false)
  }

  useEffect(() => {
    if (inputDebounceValue?.length > MIN_CHARACTERS) {
      searchingPatients()
    } else {
      dispatch(clearPatientCentres())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue])

  useEffect(() => {
    if (openPopUp) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [openPopUp])

  const onSubmit = async (dataForm, event) => {
    event.preventDefault()
    if (Object.entries(errors).length === 0) {
      setIsLoading(true)

      let newAmount = extraAmount !== null ? extraAmount : amount
      let newDebt = extraDebt !== null ? extraDebt : debt
      let auxNewTurn
      let auxPatientCentreData
      let insuranceSelected
      let insurancePlan
      let policyNumbers
      let patient = patientSelected
      const turnCreationString = 'turnCreation'
      const undatedTurnString = 'undatedTurn'

      if (!dataForm?.insurance || dataForm?.insurance?._id === '-') dataForm.insurance = null

      if (!dataForm?.dni || dataForm.dni === '') dataForm.dni = crypto.randomUUID().replaceAll('-', '').slice(0, 15)
      dataForm.birth = dataForm.birth
        ? dayjs(dataForm.birth).isValid()
          ? dayjs(dataForm.birth).toISOString()
          : ''
        : ''
      dataForm.consultationDuration = durationTurn || 30

      if (entity.selectedDate) {
        let startHour = selectedDateTurnInCalendar ? selectedDateTurnInCalendar : entity.selectedDate // hora del comienzo del turno
        let hourTurn = startHourTurn || dayjs(startHour).utc().format('H')
        let minuteTurn = startMinuteTurn || dayjs(startHour).utc().format('mm')
        let newStartTurnHour = dayjs(startHour).utc().hour(hourTurn).minute(minuteTurn).format()
        let newFinishTurnHour = dayjs(newStartTurnHour).utc().add(durationTurn, 'm').format()

        dataForm.startHour = newStartTurnHour
        dataForm.finishHour = newFinishTurnHour
      }

      //pagos realizados
      if (quantityExtraAmount > 0) {
        dataForm.extraAmount = {
          description: dataForm.reasonExtraAmount || '',
          type: dataForm.typeExtraAmount || null,
          value: quantityExtraAmount,
        }
      }

      if (machineSelected) {
        if (dataForm.doctorCentre === '-') {
          dataForm.doctorCentre = null
        }
      }

      if (doctorCentres) {
        if (dataForm?.service?._id === 'consultation') {
          dataForm.firstTime = false
          dataForm.service = null
        }
        if (dataForm?.service?._id === 'firstConsultation') {
          dataForm.firstTime = true
          dataForm.service = null
        }
      }

      if (!doctorCentres) {
        if (dataForm?.service?._id === null || !dataForm.service) {
          dataForm.service = null
        }
      }

      if (paymentHistory?.length > 0) {
        let payment = paymentHistory.map((e) => e.amount).reduce((a, p) => a + p)
        newDebt = newDebt - payment
      }

      if (copiedTurnSelected) patient = copiedTurnSelected.patientCentre

      if (patient) {
        insuranceSelected = [...patient.insurances]
        insurancePlan = [...patient.insurancePlan]
        policyNumbers = [...patient.policyNumbers]
        if (dataForm?.insurance && dataForm?.insurance?._id !== '-') {
          if (patient?.insurances?.length > 0) {
            const insuranceIndex = insuranceSelected?.findIndex(
              (insurance) => dataForm?.insurance?._id === insurance?._id,
            )
            if (insuranceIndex === -1) {
              insuranceSelected.push(dataForm.insurance)
              insurancePlan.push(dataForm.insurancePlan)
              policyNumbers.push(dataForm.policyNumber)
            } else {
              insurancePlan[insuranceIndex] = dataForm.insurancePlan
              policyNumbers[insuranceIndex] = dataForm.policyNumber
            }
          } else {
            insuranceSelected = [dataForm.insurance]
            insurancePlan = [dataForm.insurancePlan]
            policyNumbers = [dataForm.policyNumber]
          }
        } else {
          insuranceSelected = [...patient.insurances]
          insurancePlan = [...patient.insurancePlan]
          policyNumbers = [...patient.policyNumbers]
        }
      }
      if (!patient) {
        if (dataForm?.insurance && dataForm?.insurance?._id === '-') {
          insuranceSelected = []
          policyNumbers = []
          insurancePlan = []
        } else {
          insuranceSelected = [dataForm.insurance]
          insurancePlan = [dataForm.insurancePlan]
          policyNumbers = [dataForm.policyNumber]
        }
      }

      auxPatientCentreData = {
        dni: dataForm.dni,
        centre: centreId,
        lastname: dataForm.lastname,
        firstname: dataForm.firstname,
        email: dataForm.email,
        mobile: dataForm.mobile,
        telephone: dataForm.telephone,
        note: dataForm.note,
        country: watchCountry || country,
        gender: dataForm.gender,
        patientTags: dataForm.patientTags,
        address: dataForm.address,
        city: dataForm.city,
        birth: dataForm.birth,
        insurances: insuranceSelected,
        insurancePlan: insurancePlan,
        policyNumbers: policyNumbers,
        blockOnlineTurns: dataForm.blockOnlineTurns,
      }

      auxNewTurn = {
        amount: newAmount,
        centre: centreId,
        comment: dataForm.comment || '',
        consultationDuration: dataForm.consultationDuration,
        debt: newDebt,
        dni: dataForm?.dni,
        doctorCentre: doctorCentres
          ? doctorCentres
          : allDoctorCentres?.filter((doctor) => doctor._id === dataForm.doctorCentre)?.[0],
        extraFields: dataForm?.extraFields || [],
        extraAmount: dataForm?.extraAmount || [],
        extraServices: dataForm?.extraServices || [],
        firstTime: dataForm?.firstTime || false,
        insurance: dataForm?.insurance?._id === '-' ? null : dataForm.insurance,
        insuranceAuthorization: dataForm.insuranceAuthorization,
        insurancePrice: dataForm?.insurancePrice?._id || null,
        machine: dataForm.machine || null,
        patientCentre: auxPatientCentreData,
        paymentHistory: paymentHistory,
        paymentMethod: dataForm.method || 'ARScash',
        products: productsForm,
        referrer: dataForm?.referrer || '',
        service: dataForm.service,
        startHour: dataForm?.startHour || null,
        finishHour: dataForm?.finishHour || null,
        userActivation: dataForm.userActivation,
        invoice: dataForm.invoice,
      }

      if (!doctorCentres && machineSelected) auxNewTurn.machine = machineSelected
      if (patient) {
        auxPatientCentreData._id = patient._id
        auxPatientCentreData.id = patient._id
      }

      if (!entity.selectedDate) {
        if (user?.user?.doctorCentres?.includes(entity.id)) {
          return (auxNewTurn.origin = 'fastCreateDoctor')
        }
        auxNewTurn.origin = 'fastCreateCentre'
      }

      if (isDoctor) {
        auxNewTurn.origin = 'doctor'
      }

      if (patient) {
        try {
          if (checked && weeksSelected > 0) {
            const currentStartHour = auxNewTurn.startHour
            const currentFinishHour = auxNewTurn.finishHour
            concurrentTurnsCreation(currentStartHour, currentFinishHour, auxNewTurn, auxPatientCentreData)
          } else {
            const createdTurn = await dispatch(updatePatientAndCreateNewTurn(auxPatientCentreData, auxNewTurn))

            if (!createdTurn) {
              setIsLoading(false)
              showToast(`Error al crear ${t('turnLabel.sl')}`, 'error')
              return
            }

            const patientMobile = createdTurn?.patientCentre?.mobile
            let auxData = {
              selectedTurn: { ...createdTurn, centre: centre },
              dispatch: dispatch,
              wappStatus: wappStatus,
            }

            socket.emit('calendar_new_turn', `${centreId}_calendar`, createdTurn)
            if (!createdTurn?.startHour) {
              auxData.state = undatedTurnString
              handleWappReminder(auxData)
            } else {
              if (centre?.centreConfiguration?.shouldSendNotificationOnTurnCreate && !isOver && patientMobile) {
                auxData.state = turnCreationString
                await handleWappReminder(auxData)
              }
            }

            setIsLoading(false)
            handleClose()
          }
        } catch (e) {
          console.log(e)
          setIsLoading(false)
        }
      }

      if (!patient) {
        try {
          const createdTurn = await dispatch(createPatientAndCreateNewTurn(auxPatientCentreData, auxNewTurn))

          if (!createdTurn) {
            setIsLoading(false)
            showToast(`Error al crear ${t('turnLabel.sl')}`, 'error')
            return
          }

          const patientMobile = createdTurn?.patientCentre?.mobile
          let auxData = {
            selectedTurn: { ...createdTurn, centre: centre },
            dispatch: dispatch,
            wappStatus: wappStatus,
          }
          socket.emit('calendar_new_turn', `${centreId}_calendar`, createdTurn)

          if (!createdTurn?.startHour) {
            auxData.state = undatedTurnString
            handleWappReminder(auxData)
          } else {
            if (centre?.centreConfiguration?.shouldSendNotificationOnTurnCreate && !isOver && patientMobile) {
              auxData.state = turnCreationString
              await handleWappReminder(auxData)
            }
          }

          setIsLoading(false)
          handleClose()
        } catch (e) {
          console.log(e)
        }
      }
    }
  }

  const concurrentTurnsCreation = async (currentStartHour, currentFinishHour, auxNewTurn, auxPatientCentreData) => {
    for (var i = 0; i <= weeksSelected; i++) {
      auxNewTurn.startHour = dayjs(currentStartHour)
        .utc()
        .add(i * 7, 'd')
        .format()
      auxNewTurn.finishHour = dayjs(currentFinishHour)
        .utc()
        .add(i * 7, 'd')
        .format()
      try {
        setCreatingMultipleTurns(true)
        const multipleTurnsCreated = true
        const turnCreated = await dispatch(
          updatePatientAndCreateNewTurn(auxPatientCentreData, auxNewTurn, multipleTurnsCreated),
        )
        socket.emit('calendar_new_turn', `${centreId}_calendar`, turnCreated)
        setCreatingMultipleTurns(false)
        if (i === weeksSelected) {
          handleClose()

          showToast(`${t('turnLabel.Pa')} correctamente`, 'success', 5000)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleClose = async () => {
    if (doctorCentres) {
      dispatch(resetSelectedDoctorCentre())
      dispatch(clearPatientCentres())
    } else {
      dispatch(resetSelectedMachine())
    }
    setOpenPopUp(false)
  }

  const handleTabChange = (event, newValue) => {
    setPrevValuePanel(tabValue)
    setTabValue(newValue)
  }

  const handlePatientValue = (event, patient, reason) => {
    if (reason === 'clear') {
      reset()
      setPatientValue(null)
      return
    }
    setPatientValue(patient._id)
  }

  const handleChange = (event) => {
    setSearchText(event.target.value)
  }

  const scrollToSection = async () => {
    setShowAllForm(true)
    if (showAllForm) {
      scroller.scrollTo('div1', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      })
    }
  }

  const getOptionLabel = (option) => {
    if (searchText?.length > 0) {
      const dniLabel = handleSetDniLabel(option?.country || country)
      let DNI
      if (option?.dni?.length < 15) {
        DNI = `(${dniLabel}: ${option.dni})`
      } else {
        DNI = ''
      }

      return `${option?.lastname?.toUpperCase()}, ${option?.firstname?.toUpperCase()} ${DNI}`
    }
    return ''
  }

  const renderOptions = (props, option) => {
    if (option) {
      let DNI
      const dniLabel = handleSetDniLabel(option?.country || country)
      if (option?.dni?.length < 15) {
        DNI = `(${dniLabel}: ${option.dni})`
      } else {
        DNI = ''
      }

      return (
        <li {...props} key={option._id} className={s.listOptions}>
          {`${option?.lastname?.toUpperCase()}, ${capitalizeFirstLetters(option?.firstname)} ${DNI}`}
        </li>
      )
    }
  }

  const getAbsentTurnsPercentage = async () => {
    const params = {
      centre: centreId,
      patientCentre: patientSelected?._id,
    }
    const absentPercentage = await fetchAbsentPatientTurns(params)
    return setAbsentPercentage(absentPercentage)
  }

  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={openPopUp}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      scroll={isMobile ? 'body' : 'paper'}
      disableEnforceFocus
      fullScreen={isMobile || expandPopUp}
    >
      <DialogTitle className={s.dialogTitle}>
        <TurnDialogHeader
          control={control}
          selectedTurn={null}
          durationTurn={durationTurn}
          setDurationTurn={setDurationTurn}
          startHourTurn={startHourTurn}
          setStartHourTurn={setStartHourTurn}
          startMinuteTurn={startMinuteTurn}
          setStartMinuteTurn={setStartMinuteTurn}
          tabValue={tabValue}
          selectedDate={selectedDateTurnInCalendar || entity.selectedDate}
          setSelectedDate={setSelectedDateTurnInCalendar}
          handleClose={handleClose}
          reset={reset}
          handleConfigDoctorCentresPanelClose={handleConfigDoctorCentresPanelClose}
          expandPopUp={expandPopUp}
          setExpandPopUp={setExpandPopUp}
          entity={entity}
          machineSelected={machineSelected}
          doctorCentres={doctorCentres}
        />
      </DialogTitle>

      <DialogContent dividers className={s.dialogTitle}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="Formulario creación de turno"
                sx={{
                  '& .MuiTab-root.Mui-selected': {
                    color: '#009688',
                  },
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                }}
              >
                <Tab label={`${t('turnLabel.S')}`} value="1" style={{ color: '#009688' }} />
                {patientSelected &&
                  patientSelected.dni.length < 15 &&
                  handleHasHCAccess(usersProfileConnected, allSecretaries) && (
                    <Tab label="Historia clinica" value="2" />
                  )}
                {patientSelected && patientSelected.dni.length < 15 && (
                  <Tab label="Sesiones" value="3" onClick={() => setTreatmentSection(true)} />
                )}
                {patientSelected && patientSelected.dni.length < 15 && (
                  <Tab label={`Historial de ${t('turnLabel.s')}`} value="4" />
                )}
              </TabList>
            </Box>

            <TabPanel value="1" style={{ padding: '15px' }} className="animate__animated animate__fadeIn">
              <form className={s.containerForm} onSubmit={handleSubmit(onSubmit)} noValidate>
                <Autocomplete
                  loading={loading}
                  loadingText="Buscando..."
                  size="small"
                  id="combo-box-demo"
                  freeSolo
                  onChange={handlePatientValue}
                  options={patientCentres}
                  getOptionLabel={(option) => getOptionLabel(option)}
                  isOptionEqualToValue={(option) => option._id}
                  renderOption={(props, option) => renderOptions(props, option)}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      autoFocus
                      label={`Buscar ${t(`subCategoryLabel.${getCentreSubCategory(centre?.category)}.patient`)}`}
                      placeholder="Ingrese por lo menos 3 caracteres para buscar"
                      InputLabelProps={{ style: { fontSize: 14, height: '20px' } }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: loading ? <CircularProgress className={s.circularProgress} /> : null,
                        type: 'search',
                        style: { height: '35px', fontSize: 14 },
                      }}
                      value={searchText}
                      onChange={handleChange}
                    />
                  )}
                />

                <br />
                <PatientDataForm
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  patientSelected={patientSelected}
                  showAllForm={showAllForm}
                  setPatientSelected={setPatientSelected}
                  watch={watch}
                  searchByInput={searchText}
                  setSearchByInput={setSearchText}
                  reset={reset}
                  setPatientCentreValue={setPatientValue}
                  patientCentreValue={patientValue}
                  setDisabledInputLastname={setDisabledInputLastname}
                  setDisabledInputDni={setDisabledInputDni}
                  disabledInputLastname={disabledInputLastname}
                  disabledInputDni={disabledInputDni}
                  patientCentresCopy={patientCentresCopy}
                  setPatientCentresCopy={setPatientCentresCopy}
                  setPatientValueInputLastname={setPatientValueInputLastname}
                  patientValueInputLastname={patientValueInputLastname}
                  absentPercentage={absentPercentage}
                />
                <br />

                <TurnDataForm
                  showAllForm={showAllForm}
                  watch={watch}
                  patient={patient}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  amount={amount}
                  setAmount={setAmount}
                  setDebt={setDebt}
                  selectedInsurance={selectedInsurance}
                  setSelectedInsurance={setSelectedInsurance}
                  inputsInsurances={inputsInsurances}
                  setService={setService}
                  service={service}
                  setChecked={setChecked}
                  checked={checked}
                  weeksSelected={weeksSelected}
                  setWeeksSelected={setWeeksSelected}
                  durationTurn={durationTurn}
                  setDurationTurn={setDurationTurn}
                  durationEvent={durationEvent}
                  selectedDate={entity.selectedDate}
                />
                <LoaderPercentage open={creatingMultipleTurns} setOpen={setCreatingMultipleTurns} />
                {/* <br /> */}

                {showAllForm && centre?.hasStockModule && (
                  <ProductsForm
                    showAllForm={showAllForm}
                    productsForm={productsForm}
                    setProductsForm={setProductsForm}
                    setAmount={setAmount}
                    amount={amount}
                  />
                )}

                {showAllForm && (
                  <PaymentDataForm
                    showAllForm={showAllForm}
                    watch={watch}
                    control={control}
                    amount={amount}
                    setAmount={setAmount}
                    extraAmount={extraAmount}
                    setExtraAmount={setExtraAmount}
                    debt={debt}
                    setDebt={setDebt}
                    quantityExtraAmount={quantityExtraAmount}
                    setQuantityExtraAmount={setQuantityExtraAmount}
                    setExtraDebt={setExtraDebt}
                    addExtraAmount={addExtraAmount}
                    setAddExtraAmount={setAddExtraAmount}
                    paymentHistory={paymentHistory}
                    setValue={setValue}
                  />
                )}

                {showAllForm && <InvoiceTurn control={control} />}

                {showAllForm && (
                  <AddPaymentForm
                    control={control}
                    getValues={getValues}
                    paymentDate={paymentDate}
                    setPaymentDate={setPaymentDate}
                    paymentAmount={paymentAmount}
                    setPaymentAmount={setPaymentAmount}
                    paymentHistory={paymentHistory}
                    setPaymentHistory={setPaymentHistory}
                  />
                )}

                <Button type="submit" hidden={true} ref={refSubmitButton}></Button>
                <Button type="submit" hidden={true} ref={refSubmitButtonSendWhatsapp}></Button>
              </form>
            </TabPanel>

            <TabPanel value="2">
              <ClinicHistory
                isNewTurn={true}
                doctorCentreId={''}
                directionOfAnimation={directionOfAnimation(tabValue, prevValuePanel)}
              />
            </TabPanel>
            <TabPanel value="3">
              {treatmentSection && (
                <TreatmentTab
                  turnData={turnTreatmentFromAgenda}
                  setOpenPopUp={setOpenPopUp}
                  treatmentSection={treatmentSection}
                  setTreatmentSection={setTreatmentSection}
                  patientSelected={patientSelected}
                  directionOfAnimation={directionOfAnimation(tabValue, prevValuePanel)}
                />
              )}
            </TabPanel>
            <TabPanel value="4">
              <TurnsHistory
                patientCentresTurns={patientCentresTurns}
                directionOfAnimation={directionOfAnimation(tabValue, prevValuePanel)}
                patientCentre={patientSelected}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </DialogContent>

      {tabValue === '1' && (
        <DialogActions className={`${s.buttonContainer} animate__animated animate__fadeIn`}>
          <SaveButtonTurn
            watch={watch}
            isLoading={isLoading}
            patientSelected={patientSelected}
            entity={entity}
            scrollToSection={scrollToSection}
            refSubmitButtonSendWhatsapp={refSubmitButtonSendWhatsapp}
            refSubmitButton={refSubmitButton}
            setCopiedTurnValue={setCopiedTurnValue}
            showAllForm={showAllForm}
          />
        </DialogActions>
      )}
    </Dialog>
  )
}

export default FormCreateNewTurn
