import React from 'react'
import { Link } from 'react-router-dom'
import FaqLogo from '@/assets/images/faq-logo.png'
import { IconButton, Tooltip } from '@mui/material'

import s from './styles.module.scss'

const FAQ = () => {
  return (
    <Tooltip title="Preguntas frecuentes">
      <Link to="/preguntas-frecuentes">
        <IconButton className={s.iconButton}>
          <img src={FaqLogo} alt="Descripción" className={s.fqLogo} />
        </IconButton>
      </Link>
    </Tooltip>
  )
}

export default FAQ
