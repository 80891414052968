import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { resetSelectedNotification } from '@/store/notifications/actions'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormAdminNotifications from '../formAdminNotifications'

const PopUpAdminNotifications = ({ open, setOpen }) => {
  const dispatch = useDispatch()
  const { notificationSelected } = useSelector((state) => state.notifications)

  const handleClose = () => {
    setOpen(false)
    dispatch(resetSelectedNotification())
  }

  return (
    <div>
      <Dialog
        disableEscapeKeyDown={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        scroll="paper"
        disableEnforceFocus
      >
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={notificationSelected ? 'Edición de notificación' : 'Nueva notificación'}
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <FormAdminNotifications formValues={notificationSelected} onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpAdminNotifications
