import React, { useState } from 'react'
import prescriptionLogo from '@/assets/images/prescription.png'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'

import PrescriptionForm from './components/prescriptionForm'

import s from './styles.module.scss'

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#eee',
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'initial',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor: 'white',
}))

export default function PrescriptionItem({
  detailPrescription,
  index,
  centreDetail,
  isNewPrescription,
  doctorHasPrescriptionReqs,
}) {
  const [expanded, setExpanded] = useState('')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className={s.summary}>
          {isNewPrescription && <img src={prescriptionLogo} alt="Prescription" />}

          {isNewPrescription ? (
            <p className={s.newPrescription}>Nueva receta</p>
          ) : (
            <div className={s.detailItem}>
              <span className={s[detailPrescription?.state === 'draft' ? 'draft' : 'done']}>
                {detailPrescription?.state === 'draft' ? 'Borrador' : 'Generada'}
              </span>
              <span className={s.dataItem}>
                <b>Receta</b> - {toCustomTz(detailPrescription?.creationDate, undefined, false, 'DD/MM/YYYY')}{' '}
                <i>
                  Creada por:{' '}
                  {detailPrescription?.creatorFullName?.toUpperCase() ||
                    detailPrescription?.doctorFullName?.toUpperCase()}
                </i>
              </span>
            </div>
          )}
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <PrescriptionForm
          detailPrescription={detailPrescription}
          centreDetail={centreDetail}
          isNewPrescription={isNewPrescription}
          setExpanded={setExpanded}
          doctorHasPrescriptionReqs={doctorHasPrescriptionReqs}
        />
      </AccordionDetails>
    </Accordion>
  )
}
