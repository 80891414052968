import { Controller, useController } from 'react-hook-form'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Autocomplete, Checkbox, TextField } from '@mui/material'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

export const AutoCompleteField = ({
  className,
  disabled,
  fullWidth,
  label,
  name,
  multiple,
  options,
  setSearchText,
  country = null,
}) => {
  const icon = <CheckBoxOutlineBlank fontSize="small" />
  const checkedIcon = <CheckBox fontSize="small" />

  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error },
  } = useController({ name })

  const auxCountry = country ? country : import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const showOptionLabel = (option) => {
    if (option?.lastname)
      return `${capitalizeFirstLetters(option?.lastname)} ${capitalizeFirstLetters(option?.firstname)}`

    let label = ''
    if (option?.name) label += capitalizeFirstLetters(option.name) + ' - '
    if (option?.code) label += `Codigo ${option.code} - `
    if (option?.description) label += `Descripción ${option.description} - `
    if (option?.duration) label += `Duracion: ${option.duration} - `
    if (option?.price) label += `Precio: ${handleSetMoneySign(auxCountry)}${option.price} - `

    if (label.endsWith(' - ')) {
      label = label.slice(0, -3)
    }

    return label
  }

  return (
    <Controller
      name={name}
      defaultValue={[]}
      render={() => (
        <Autocomplete
          className={className}
          disableCloseOnSelect={multiple}
          disabled={disabled}
          fullWidth={fullWidth}
          getOptionLabel={(option) => showOptionLabel(option)}
          isOptionEqualToValue={(option, value) =>
            option?._id ? option._id === value._id : option.value ? option.value === value.value : option === value
          }
          multiple={multiple}
          freeSolo
          onBlur={onBlur}
          onChange={(event, newValue) => onChange(newValue)}
          value={value}
          options={options}
          ref={ref}
          renderOption={(props, option, { selected }) => (
            <>
              <li {...props} key={option?._id}>
                {multiple && !option.color && (
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                )}
                {multiple && option.color && (
                  <span style={{ background: option.color }} className={s.itemTagColor}></span>
                )}
                {showOptionLabel(option)}
              </li>
            </>
          )}
          renderInput={(params) => (
            <TextField
              onChange={(e) => setSearchText(e.target.value)}
              {...params}
              error={Boolean(error)}
              label={label || name.toUpperCase()}
              variant="standard"
            />
          )}
        />
      )}
    />
  )
}

AutoCompleteField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  setSearchText: PropTypes.func,
  country: PropTypes.string,
}

AutoCompleteField.defaultProps = {
  disabled: false,
  fullWidth: true,
  multiple: true,
  setSearchText: () => {},
}
