import React, { useState } from 'react'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import SupportAgent from '@mui/icons-material/Help'
import { IconButton, Tooltip } from '@mui/material'

import SupportPopUp from './supportPopUp'

import s from '../../styles.module.scss'

const HelpUser = () => {
  const [popUpInformation, setPopUpInformation] = useState(false)

  const showPopUpInformation = () => {
    mxp.track(mxp.events.navbar.supportClicked)
    setPopUpInformation(!popUpInformation)
  }

  return (
    <>
      <Tooltip title="Soporte">
        <IconButton className={s.iconButton} onClick={showPopUpInformation}>
          <SupportAgent />
        </IconButton>
      </Tooltip>

      <SupportPopUp open={popUpInformation} setOpen={setPopUpInformation} />
    </>
  )
}

export default HelpUser
