import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RiFileExcel2Line } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import CaluInput from '@/components/styledComponents/CaluInput'
import { resetSelectedInsurancePrice } from '@/store/insurancePrices/actions'
import { xlsxFromJson } from '@/utils/excel'
import { showToast } from '@/utils/toast'
import { Grid, IconButton, Tooltip } from '@mui/material'

import { downloadTableAdapter } from './downloadTableAdapter'

import s from './styles.module.scss'

const SearchBar = ({
  inputSearch,
  setInputSearch,
  prevState,
  setFilteredState,
  openPopUp = null,
  setOpenPopUp = null,
  indexTab,
}) => {
  const { t } = useTranslation('global')
  const dispatch = useDispatch()

  const addNewPlan = async () => {
    await dispatch(resetSelectedInsurancePrice())
    setOpenPopUp(!openPopUp)
  }

  useEffect(() => {
    let keyToFilter = prevState?.[0]?.name ? 'name' : prevState?.[0]?.lastname ? 'lastname' : 'insurance'
    let filteredData
    if (keyToFilter === 'insurance') {
      filteredData = prevState?.filter((data) =>
        data?.insurance?.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            inputSearch
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase(),
          ),
      )
    }

    if (keyToFilter !== 'insurance') {
      filteredData = prevState?.filter((data) =>
        data[keyToFilter]
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            inputSearch
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase(),
          ),
      )
    }

    setFilteredState(filteredData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevState, inputSearch])

  const downloadTable = async (indexTab, data) => {
    let auxData = [...data]
    let tabName = [`${t('insuranceLabel.frag')}`, 'profesionales', 'servicios']
    const fileProps = {
      fileName: `planilla_${tabName[indexTab]}`,
    }

    if (auxData?.length === 0) return showToast('No hay items para imprimir !', 'warning')

    const buildExcelData = auxData?.map((entry) => {
      return downloadTableAdapter(entry, indexTab)
    })
    xlsxFromJson(buildExcelData, fileProps)
  }

  return (
    <Grid
      container
      className={s.mainContainer}
      sx={{
        '& .MuiInput-underline:after': {
          borderBottomColor: '#4C5EFE', // Solid underline on focus
        },
      }}
    >
      <Grid item xs={12} md={6}>
        <CaluInput
          id="search"
          type="text"
          autoComplete="off"
          labelText="Buscar registro"
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
          className={s.searchBarContainer}
        />
      </Grid>

      <Grid item xs={12} md={6} className={s.contBtnAddAndExcel}>
        {openPopUp !== null && (
          <CaluButton color="primary" size="large" onClick={addNewPlan}>
            Nuevo registro
          </CaluButton>
        )}

        <Tooltip title="Descargar planilla">
          <IconButton className={s.printerBtn} onClick={() => downloadTable(indexTab, prevState)}>
            <RiFileExcel2Line />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default SearchBar
