/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaBookmark, FaRegBookmark } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import ButtonsPagination from '@/components/ButtonsPaginations'
import LoaderCircle from '@/components/LoaderCircle'
import SelectWithSearchByParams from '@/components/SelectWithSearchByParms'
import CaluButton from '@/components/styledComponents/CaluButton'
import { setAllClinicHistoriesController } from '@/controllers/clinicHistories'
import { getAllClinicHistories, postNewClinicHistory, updateClinicHistory } from '@/store/clinicHistory/actions'
import { getTemplatesHC, selectedTemplateHC as actionSelectedTemplateHC } from '@/store/clinicHistoryTemplate/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Autocomplete, Button, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material'

import dayjs from 'dayjs'
import AccordionItem from './components/accordionItem'
import downloadHC from './components/downloadHC'
import PopUpSharedHC from './components/popUpSharedHC'
import { getCentreSubCategory } from '../../../../utils/functions/getCentreSubCategory'

import s from './styles.module.scss'

const Evolutions = ({ allDoctorCentres, isNewTurn, entityId }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('global')

  const icon = <CheckBoxOutlineBlank fontSize="small" />
  const checkedIcon = <CheckBox fontSize="small" />

  const { allTemplatesHC, templateHCSelected } = useSelector((state) => state.templateHC)
  const { selectedTurn } = useSelector((state) => state.turns)
  const { patientSelected } = useSelector((state) => state.patientCentres)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { allClinicHistories, paginationData } = useSelector((state) => state?.clinicHistory)
  const { doctorCentres } = useSelector((state) => state?.doctorCentres)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isCentre, isDoctor, entityInLocalStorage } = usersProfileConnected

  const [isLoading, setIsLoading] = useState(false)
  const [collapseAll, setCollapseAll] = useState(true)
  const [newHCButton, setNewHCButton] = useState(false)
  const [selectedTemplateHC, setSelectedTemplateHC] = useState(allTemplatesHC?.[0]?._id || '')
  const [globalShareWithAll, setGlobalShareWithAll] = useState(false)
  const [sharedHC, setSharedHC] = useState([])
  const [openShareAllPopUp, setOpenShareAllPopUp] = useState(false)

  const allowEditHC = isCentre || isDoctor

  const patientCentre = selectedTurn ? selectedTurn.patientCentre : patientSelected

  const extraParams = isDoctor ? { doctorCentre: entityInLocalStorage?.doctorCentreId } : { centre: entityId }

  const collapseAccordion = () => {
    setCollapseAll(!collapseAll)
  }

  const handleChangeBookmark = (data) => {
    let changeBookmark = {
      ...data,
      isPinned: !data.isPinned,
    }
    dispatch(updateClinicHistory(changeBookmark))
  }

  const handleChangeHC = (e) => {
    setSelectedTemplateHC(e.target.value)
    dispatch(actionSelectedTemplateHC(e.target.value))
  }

  useEffect(() => {
    if (selectedTemplateHC) {
      dispatch(actionSelectedTemplateHC(selectedTemplateHC))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeGlobalShared = (e) => {
    setGlobalShareWithAll(e.target.checked)
  }

  const findInsuranceIndex = (insuranceId) => {
    let insuranceIndex = patientCentre?.insurances.findIndex((elem) => elem._id === insuranceId)
    return insuranceIndex
  }

  const handleDownloadHC = async () => {
    let centreSignImage = centreDetail?.signImage
    let centreLogoImg = centreDetail?.logo

    const removeDraftHC = allClinicHistories?.filter((hc) => !hc?.isDraft)

    downloadHC(removeDraftHC, patientCentre, centreLogoImg, centreSignImage)
  }

  const handlePostClinicHistory = () => {
    setIsLoading(true)

    let dataPost = {
      isDraft: true,
      patientCentre: patientCentre,
      centre: entityId,
      clinicHistoryFields: templateHCSelected.fields,
      visit: dayjs().utc().hour(0).minute(0).second(0).format(),
      insurance: selectedTurn
        ? selectedTurn?.insurance && {
            _id: selectedTurn?.insurance._id,
            name: selectedTurn?.insurance.name,
            policyNumber:
              selectedTurn?.patientCentre?.policyNumbers[findInsuranceIndex(selectedTurn?.insurance._id)] || '',
            insurancePlan:
              selectedTurn?.patientCentre?.insurancePlan[findInsuranceIndex(selectedTurn?.insurance._id)] || '',
          }
        : patientSelected?.insurances[0]
        ? {
            _id: patientSelected?.insurances[0]._id,
            name: patientSelected?.insurances[0].name,
            policyNumber: patientSelected?.policyNumbers[0],
            insurancePlan: patientSelected?.insurancePlan[0],
          }
        : '',
    }

    if (isDoctor) {
      dataPost = {
        ...dataPost,
        doctorCentre: doctorCentres?.[0],
      }
    }

    // doctor owner
    if (isDoctor && entityInLocalStorage.doctorCentreId === templateHCSelected?.doctorCentre) {
      dataPost = {
        ...dataPost,
        isOwner: true,
      }
    }

    // centre owner
    if (isCentre && !templateHCSelected?.doctorCentre && entityInLocalStorage._id === templateHCSelected?.centre?._id) {
      dataPost = {
        ...dataPost,
        isOwner: true,
      }
    }

    try {
      dispatch(postNewClinicHistory(dataPost))
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    } finally {
      setNewHCButton(false)
      setIsLoading(false)
    }
  }

  const handleChangePaginationNumber = async (page) => {
    const params = {
      centre: entityId,
      patientCentre: patientCentre?._id,
      page: page,
      limit: 10,
    }

    if (isDoctor) {
      params.doctorCentre = entityInLocalStorage?.doctorCentreId
    }

    setIsLoading(true)
    const fetchAllClinicHistories = await setAllClinicHistoriesController(params)
    await dispatch(getAllClinicHistories(fetchAllClinicHistories))
    setIsLoading(false)
  }

  return (
    <div className={`${s.mainContainer} animate__animated animate__fadeIn`}>
      {!isNewTurn && (
        <>
          {!newHCButton ? (
            <div className={s.containerBtn}>
              <CaluButton color="primary" size="medium" onClick={() => setNewHCButton(true)} disabled={!allowEditHC}>
                Ingresar nueva ficha
              </CaluButton>
            </div>
          ) : (
            <div>
              <p className={s.pTagNewHC}>
                Ingresar nueva ficha - <a onClick={() => setNewHCButton(false)}>Ocultar</a>
              </p>

              {allTemplatesHC?.length === 0 ? (
                <div className={s.emptyTemplates}>
                  <p>Aún no tiene tipos de fichas creados</p>
                  <a onClick={() => navigate(`/historias-clinicas/${entityId}`)}>
                    Debe crear sus fichas de historia clínica personalizada desde aquí
                  </a>
                </div>
              ) : (
                <>
                  <div>
                    <SelectWithSearchByParams
                      inputLabel={'Seleccione el tipo de ficha para esta visita'}
                      valueSelect={selectedTemplateHC}
                      onChange={handleChangeHC}
                      stateRedux={allTemplatesHC}
                      functionToDispatch={getTemplatesHC}
                      extraParams={extraParams}
                    />
                  </div>
                  <ul>
                    <li className={s.itemLI}>Fecha de visita</li>
                    <li className={s.itemLI}>Asunto</li>
                    {templateHCSelected?.fields.map((field) => (
                      <li className={s.itemLI} key={field?._id}>
                        <i>{field?.name}</i>
                      </li>
                    ))}
                    <li className={s.itemLI}>Observaciones</li>
                  </ul>
                  <div className={s.containerBtn}>
                    <CaluButton color="primary" size="small" loading={isLoading} onClick={handlePostClinicHistory}>
                      Crear ficha
                    </CaluButton>
                  </div>
                </>
              )}
            </div>
          )}

          <div className={s.divider}></div>
        </>
      )}

      {(!allClinicHistories || allClinicHistories?.length === 0) && (
        <p className={s.emptyHC}>
          Aún no hay historias clínicas cargadas para este{' '}
          {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}
        </p>
      )}

      {allClinicHistories?.length > 0 && (
        <>
          <div className={s.containerListHC}>
            <div className={s.btnShow}>
              <Button onClick={collapseAccordion}>{collapseAll ? 'Desplegar todas' : 'Colapsar todas'}</Button>
            </div>
            {!isLoading ? (
              <ul>
                {allClinicHistories?.map((hc, index = 1) => (
                  <div className={s.listItem} key={hc?._id}>
                    <i className={s.bookmark} onClick={() => handleChangeBookmark(hc)}>
                      {' '}
                      {hc?.isPinned ? <FaBookmark color="#F8AC59" /> : <FaRegBookmark color="#ccc" />}
                    </i>
                    <AccordionItem
                      detailHC={hc}
                      index={index}
                      collapseAll={collapseAll}
                      isNewTurn={isNewTurn}
                      allowEditHC={allowEditHC}
                    />
                  </div>
                ))}
              </ul>
            ) : (
              <LoaderCircle
                text={`Cargando datos del ${t(
                  `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`,
                )}...`}
              />
            )}
            <ButtonsPagination
              paginationData={paginationData}
              handleChangePaginationNumber={handleChangePaginationNumber}
            />
          </div>
        </>
      )}

      {!isNewTurn && (
        <>
          {allClinicHistories?.length > 1 && !usersProfileConnected.isSecretary && (
            <div className={s.containerFinalBlock}>
              <div className={s.firstContainer}>
                <b>Compartir todas las fichas</b>
                <i>Sólo se compartirán fichas que hayas creado</i>
              </div>

              <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                  <Autocomplete
                    multiple
                    disabled={globalShareWithAll}
                    disableCloseOnSelect
                    options={allDoctorCentres}
                    ListboxProps={{ style: { maxHeight: '15rem', fontSize: '14px' } }}
                    getOptionLabel={(option) => capitalizeFirstLetters(`${option.lastname}, ${option.firstname}`)}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option._id}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {capitalizeFirstLetters(`${option.lastname}, ${option.firstname}`)}
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label="Compartir todas las fichas con:" variant="standard" />
                    )}
                    onChange={(event, selectedValues) => {
                      setSharedHC(selectedValues)
                    }}
                  />
                </Grid>

                <Grid item md={4} xs={12} display="flex" justifyContent="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={globalShareWithAll}
                        onChange={handleChangeGlobalShared}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{
                          '&.Mui-checked': {
                            color: '#f8ac59',
                          },
                        }}
                      />
                    }
                    label="Marcar todos"
                  />
                </Grid>
              </Grid>
            </div>
          )}

          {allClinicHistories?.length > 0 && (
            <>
              <div className={s.lightDivider}></div>

              <div className={s.footerButtons}>
                <Button className={s.btnDownload} onClick={handleDownloadHC}>
                  Descargar todas
                </Button>
                {allClinicHistories?.length > 1 && (
                  <Button onClick={() => setOpenShareAllPopUp(true)} className={s.btnShare}>
                    Compartir todas
                  </Button>
                )}
                <PopUpSharedHC
                  openPopUp={openShareAllPopUp}
                  setOpenPopUp={setOpenShareAllPopUp}
                  doctorsToShareHC={sharedHC}
                  shareWithAll={globalShareWithAll}
                  globalShared={true}
                  allClinicHistories={allClinicHistories}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Evolutions
