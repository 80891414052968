import React, { useEffect, useState } from 'react'
import { RiFileExcel2Line } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import ButtonsPagination from '@/components/ButtonsPaginations'
import { setAllCashboxItemsCombinedController } from '@/controllers/cashboxItems'
import { paymentMethods } from '@/helpers/constants'
import useValidateURLId from '@/hooks/useValidateURLId'
import { cashLoadingAction, getAllCashboxItemsCombined, selectedCashboxItem } from '@/store/cashboxItems/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import convertCSVToExcelAndDownload from '@/utils/functions/convertCSVToExcelAndDownload'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { treatmentDebt } from '@/utils/functions/treatmentAmounts'
import { showToast } from '@/utils/toast'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import dayjs from 'dayjs'

import s from '../../styles.module.scss'

const ManagementTable = ({ billingTab, setOpenAddEntry, patientValue }) => {
  const centreID = useValidateURLId()
  const dispatch = useDispatch()

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const { cashboxEntries, lastPaymentSearch, paginationData, selectedServiceInFilter, selectedEntryType } = useSelector(
    (state) => state.cashboxItems,
  )

  const [auxCashboxEntries, setAuxCashboxEntries] = useState(cashboxEntries?.length ? [...cashboxEntries] : [])
  const [processing, setProcessing] = useState(false)

  const moneySign = handleSetMoneySign(country)

  const handleClick = (data) => {
    setOpenAddEntry(true)
    dispatch(selectedCashboxItem(data))
  }

  const handlePaymentMethods = (paymentHistory) => {
    if (paymentHistory?.length === 0) return '-'

    let arrayPaymentMethods = paymentHistory?.map((elem) => elem?.method)
    let setPaymentMethods = [...new Set(arrayPaymentMethods)]
    let changesNames = setPaymentMethods?.map((elem) => convertValueToName(paymentMethods, elem))
    return changesNames.join().replace(/,/g, ', ')
  }

  useEffect(() => {
    setAuxCashboxEntries([...cashboxEntries])
  }, [cashboxEntries])

  const handleChangePage = (params) => async (dispatch) => {
    dispatch(cashLoadingAction(true))

    const fetchAllCashboxItemsCombined = await setAllCashboxItemsCombinedController(params)
    dispatch(getAllCashboxItemsCombined(fetchAllCashboxItemsCombined))
    dispatch(cashLoadingAction(false))
  }

  const handleChangePaginationNumber = async (page) => {
    let params = {
      centre: centreID,
      start: lastPaymentSearch?.from,
      finish: lastPaymentSearch?.to,
      patientCentre: !patientValue ? null : patientValue?._id,
      service: selectedServiceInFilter === '-' ? null : selectedServiceInFilter,
      entryType: selectedEntryType === '-' ? null : selectedEntryType,
      limit: 50,
    }
    if (!billingTab) {
      params = {
        ...params,
        madePayments: true,
      }
    }

    params.page = page
    await dispatch(handleChangePage(params))
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  }

  const billingColumns = [
    { field: 'id', headerName: 'ID', width: 90, flex: 1 },
    {
      field: 'type',
      headerName: 'Tipo',
      minWidth: 70,
      maxWidth: 90,
      ...stylesColumns,
    },
    {
      field: 'date',
      headerName: 'Fecha',
      minWidth: 100,
      maxWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'hour',
      headerName: 'Hora',
      minWidth: 100,
      maxWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'patientOrProvider',
      headerName: 'Paciente/Proveedor',
      minWidth: 200,
      ...stylesColumns,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'description',
      headerName: 'Descripción',
      minWidth: 130,
      ...stylesColumns,
    },
    {
      field: 'amount',
      headerName: 'Monto a pagar',
      minWidth: 130,
      maxWidth: 150,
      ...stylesColumns,
    },
    {
      field: 'totalPayments',
      headerName: 'Monto pagado',
      minWidth: 130,
      maxWidth: 150,
      ...stylesColumns,
    },
    {
      field: 'debt',
      headerName: 'Deuda',
      minWidth: 100,
      maxWidth: 130,
      ...stylesColumns,
    },
    {
      field: 'category',
      headerName: 'Categoría',
      minWidth: 100,
      ...stylesColumns,
    },
    {
      field: 'invoice',
      headerName: 'Factura/Comprobante',
      minWidth: 100,
      maxWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'paymentMethod',
      headerName: 'Método de pago',
      minWidth: 150,
      maxWidth: 160,
      ...stylesColumns,
    },
    {
      field: 'origin',
      headerName: 'Origen',
      minWidth: 180,
      ...stylesColumns,
    },
    {
      field: 'raw',
      headerName: '',
      minWidth: 100,
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Editar">
          <Button onClick={() => handleClick(params.value)} className={s.rowButton} tabIndex={params.hasFocus ? 0 : -1}>
            <EditOutlinedIcon />
          </Button>
        </Tooltip>
      ),
    },
  ]

  const paymentsColumns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'type',
      headerName: 'Tipo',
      minWidth: 70,
      maxWidth: 90,
      ...stylesColumns,
    },
    {
      field: 'date',
      headerName: 'Fecha',
      minWidth: 100,
      maxWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'hour',
      headerName: 'Hora',
      minWidth: 100,
      maxWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'patientOrProvider',
      headerName: 'Paciente/Proveedor',
      minWidth: 200,
      ...stylesColumns,
    },
    {
      field: 'description',
      headerName: 'Descripción',
      minWidth: 180,
      ...stylesColumns,
    },
    {
      field: 'totalPayments',
      headerName: 'Monto pagado',
      minWidth: 130,
      maxWidth: 150,
      ...stylesColumns,
    },
    {
      field: 'paymentMethod',
      headerName: 'Medio de pago',
      minWidth: 130,
      maxWidth: 150,
      ...stylesColumns,
    },
    {
      field: 'category',
      headerName: 'Categoría',
      minWidth: 100,
      ...stylesColumns,
    },
    {
      field: 'invoice',
      headerName: 'Factura/Comprobante',
      minWidth: 100,
      maxWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'origin',
      headerName: 'Origen',
      minWidth: 200,
      ...stylesColumns,
    },
    {
      field: 'raw',
      headerName: '',
      minWidth: 100,
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Editar">
          <Button onClick={() => handleClick(params.value)} className={s.rowButton} tabIndex={params.hasFocus ? 0 : -1}>
            <EditOutlinedIcon />
          </Button>
        </Tooltip>
      ),
    },
  ]

  const rows = auxCashboxEntries?.map((entry) =>
    billingTab
      ? {
          id: entry?.id,
          type: entry?.type === 'income' ? 'Ingreso' : 'Egreso',
          date:
            entry?.raw?.docType === 'treatment'
              ? dayjs(entry?.raw?.creationDate).format('YYYY-MM-DD')
              : entry?.raw?.docType === 'turn'
                ? entry?.date
                : dayjs(entry?.raw?.date).format('YYYY-MM-DD'),
          hour:
            entry?.raw?.docType === 'treatment'
              ? dayjs(entry?.raw?.creationDate).format('HH:mm')
              : entry?.raw?.docType === 'turn'
                ? entry?.hour
                : dayjs(entry?.raw?.date).format('HH:mm'),
          patientOrProvider: entry?.patientOrProvider,
          description: entry?.raw?.docType === 'treatment' ? `Trat.: ${entry?.raw?.name}` : entry?.description,
          amount:
            entry?.raw?.docType === 'treatment'
              ? `${moneySign}${entry?.raw?.price || 0}`
              : `${moneySign}${entry?.amount || 0}`,
          totalPayments:
            entry?.raw?.docType === 'treatment'
              ? `${moneySign}${entry?.raw?.isPaid ? entry?.raw?.price : entry?.raw?.paidPrice}`
              : `${moneySign}${entry?.totalPayments}`,
          debt:
            billingTab && entry?.raw?.docType === 'treatment'
              ? treatmentDebt(moneySign, entry?.raw)
              : `${moneySign}${entry?.debt}`,
          category: entry?.category,
          invoice: entry?.raw?.treatment?._id
            ? entry?.raw?.treatment?.invoice
            : entry?.raw?.invoice
              ? entry?.raw?.invoice || '-'
              : '-',
          origin: entry?.raw?.state
            ? entry?.raw?.doctorCentre
              ? `${capitalizeFirstLetters(entry?.raw?.doctorCentre?.lastname)} ${capitalizeFirstLetters(
                  entry?.raw?.doctorCentre?.firstname,
                )}`
              : `${capitalizeFirstLetters(entry?.raw?.machine?.name)}`
            : '-',
          paymentMethod: entry?.raw?.paymentHistory ? handlePaymentMethods(entry?.raw?.paymentHistory) : '-',
          raw: entry?.raw,
        }
      : {
          id: entry?.id,
          type: entry?.type === 'income' ? 'Ingreso' : 'Egreso',
          date: entry?.date,
          hour: `${entry?.hour} hs`,
          patientOrProvider: entry?.patientOrProvider,
          description: entry?.description || '-',
          totalPayments: `${moneySign}${entry?.amount}`,
          paymentMethod: !billingTab && convertValueToName(paymentMethods, entry?.paymentMethod),
          category: entry?.category,
          invoice: entry?.raw?.treatment?._id
            ? entry?.raw?.treatment?.invoice
            : entry?.raw?.invoice
              ? entry?.raw?.invoice || '-'
              : '-',
          origin: entry?.raw?.state
            ? entry?.raw?.doctorCentre
              ? `${capitalizeFirstLetters(entry?.raw?.doctorCentre?.lastname)} ${capitalizeFirstLetters(
                  entry?.raw?.doctorCentre?.firstname,
                )}`
              : `${capitalizeFirstLetters(entry?.raw?.machine?.name)}`
            : '-',
          raw: entry?.raw,
        },
  )

  const downloadTable = async (billingTab, data) => {
    let tabName = billingTab ? 'fact' : 'pagos'

    const fileName = `${tabName}_${toCustomTz(lastPaymentSearch?.from, undefined, true, 'DD-MM-YYYY')}_${toCustomTz(
      lastPaymentSearch?.to,
      undefined,
      true,
      'DD-MM-YYYY',
    )}`

    if (!data) return showToast('No hay items para imprimir !', 'warning')

    convertCSVToExcelAndDownload(data, fileName)
  }

  const getCSV = async () => {
    try {
      let params = {
        centre: centreID,
        start: toCustomTz(lastPaymentSearch?.from, 'utc', true),
        finish: toCustomTz(lastPaymentSearch?.to, 'utc', true),
        patientCentre: !patientValue ? null : patientValue?._id,
        service: selectedServiceInFilter === '-' ? null : selectedServiceInFilter,
        entryType: selectedEntryType === '-' ? null : selectedEntryType,
        limit: 50,
        downloadData: true,
      }

      if (!billingTab) {
        params = {
          ...params,
          madePayments: true,
        }
      }

      const downloadAllData = await setAllCashboxItemsCombinedController(params)
      return downloadAllData
    } catch (error) {
      console.log(error)
    }
  }

  function CustomToolbar(props) {
    return (
      <GridToolbarContainer className={s.contToolbar} {...props}>
        <Tooltip title="Descargar planilla">
          <span>
            <IconButton
              className={s.printerBtn}
              onClick={async () => {
                if (processing) return

                setProcessing(true)
                const csvData = await getCSV()
                setProcessing(false)
                downloadTable(billingTab, csvData)
              }}
              disabled={processing}
            >
              <RiFileExcel2Line />
            </IconButton>
          </span>
        </Tooltip>
      </GridToolbarContainer>
    )
  }

  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '& .redColor': {
          color: '#e74c3c',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
      }}
    >
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
              category: false,
            },
          },
        }}
        slots={{
          toolbar: CustomToolbar,
        }}
        getRowClassName={(params) => (params.row.type === 'Ingreso' ? 'income' : 'outcome')}
        getCellClassName={(params) => {
          if (params.row.paymentMethod && params.row.type === 'Egreso' && params.field === 'totalPayments') {
            return 'redColor'
          }
          return params.row.type === 'Egreso' && params.field === 'amount' && 'redColor'
        }}
        rows={rows || []}
        columns={billingTab ? billingColumns : paymentsColumns}
        hideFooter={true}
        disableRowSelectionOnClick={true}
        sx={{ fontSize: '13px' }}
        rowHeight={45}
        autoHeight={true}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row?.id}
        onCellClick={(params) => handleClick(params?.row?.raw)}
      />
      {auxCashboxEntries?.length >= 1 && (
        <ButtonsPagination
          paginationData={paginationData}
          handleChangePaginationNumber={handleChangePaginationNumber}
        />
      )}
    </Box>
  )
}

export default ManagementTable
