export const formDefaultValues = {
  diagnosis: '',
  quantity: '',
  indications: '',
  medicationData: {
    nombreDroga: '',
    nombreProducto: '',
    observaciones: '',
    presentacion: '',
    cantidad: '',
  },
}
