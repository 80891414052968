import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import { useUser } from '@/context/UserProvider'
import { setAllClinicHistoriesController } from '@/controllers/clinicHistories'
import { setAllTemplatesHCController } from '@/controllers/clinicHistoryTemplate'
import useValidateURLId from '@/hooks/useValidateURLId'
import { getAllClinicHistories } from '@/store/clinicHistory/actions'
import { setAllTemplatesHC } from '@/store/clinicHistoryTemplate/actions'
import { directionOfAnimation } from '@/utils/functions/directionOfAnimation'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab, tabsClasses } from '@mui/material'

import ComparativeTables from './components/comparativeTables'
import Evolutions from './components/evolutions'
import MedicalInformation from './components/medicalInformation'
import Prescriptions from './components/prescriptions'

const ClinicHistory = ({ isNewTurn, doctorCentreId, directionOfAnimation: directionOfAnimationClassName = null }) => {
  const dispatch = useDispatch()
  const entityId = useValidateURLId()
  const { user } = useUser()
  const { t } = useTranslation('global')

  const [isLoading, setIsLoading] = useState(true)
  const [selectedDoctorCentre, setSelectedDoctorCentre] = useState({})
  const [tabValue, setTabValue] = useState('1')
  const [prevValuePanel, setPrevValuePanel] = useState('1')

  const { totalDoctorCentres } = useSelector((state) => state.doctorCentres)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { selectedTurn } = useSelector((state) => state.turns)
  const { patientSelected } = useSelector((state) => state.patientCentres)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isCentre, isDoctor, entityInLocalStorage } = usersProfileConnected

  const patientCentre = selectedTurn?.patientCentre || patientSelected

  const doctorHasPrescriptionReqs = isDoctor && selectedDoctorCentre?.isDoctorNumberValidated
  const centreHasPrescriptionReqs = !!(isCentre && centreDetail?.doctorNumber && centreDetail?.signImage)

  const handleSearchDoctorCentre = () => {
    let searchingDoctor = totalDoctorCentres.find((doctor) => doctor._id === doctorCentreId)
    setSelectedDoctorCentre(searchingDoctor)
  }

  const setInit = async (params) => {
    const fetchAllClinicHistories = await setAllClinicHistoriesController(params)
    dispatch(getAllClinicHistories(fetchAllClinicHistories))

    if (isDoctor || isCentre) {
      params.limit = 30
      const fetchAllTemplatesHC = await setAllTemplatesHCController(params)
      dispatch(setAllTemplatesHC(fetchAllTemplatesHC))
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (isDoctor) {
      let params = {
        centre: entityId,
        patientCentre: patientCentre?._id,
        doctorCentre: entityInLocalStorage?.doctorCentreId,
        limit: 10,
      }

      setInit(params)
    } else {
      let params = {
        centre: entityId,
        patientCentre: patientCentre?._id,
        limit: 10,
      }

      setInit(params)
    }
    handleSearchDoctorCentre()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch])

  if (isLoading) {
    return (
      <LoaderCircle
        text={`Cargando datos del ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}...`}
      />
    )
  }

  const handleChange = (event, newValue) => {
    setPrevValuePanel(tabValue)
    setTabValue(newValue)
  }

  return (
    <div className={directionOfAnimationClassName}>
      <Box sx={{ width: '100%' }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="Pestaña historias clínicas"
              sx={{
                '& .MuiTab-root.Mui-selected': {
                  color: '#009688',
                },
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
            >
              <Tab label="Evoluciones" value="1" />
              <Tab label="Información Médica" value="2" />
              <Tab label="Recetas" value="3" />
              <Tab label="Tablas comparativas" value="4" />
            </TabList>
          </Box>

          <TabPanel value="1">
            <Evolutions allDoctorCentres={totalDoctorCentres} isNewTurn={isNewTurn} entityId={entityId} />
          </TabPanel>
          <TabPanel value="2">
            <MedicalInformation directionOfAnimation={directionOfAnimation(tabValue, prevValuePanel)} />
          </TabPanel>
          <TabPanel value="3">
            <Prescriptions
              patientCentre={patientCentre}
              centreDetail={centreDetail}
              doctorCentre={selectedDoctorCentre}
              doctorHasPrescriptionReqs={doctorHasPrescriptionReqs}
              centreHasPrescriptionReqs={centreHasPrescriptionReqs}
              directionOfAnimation={directionOfAnimation(tabValue, prevValuePanel)}
            />
          </TabPanel>
          <TabPanel value="4">
            <ComparativeTables patientCentreId={patientCentre?._id} entityId={entityId} />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  )
}

export default ClinicHistory
