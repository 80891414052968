import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import NoCalendarsForToday from '@/components/NoCalendarsForToday'
import NoEntitiesMessage from '@/components/NoEntitiesMessage'
import { STEPS } from '@/helpers/tourSteps'
import useTour from '@/hooks/useTour'
import useValidateURLId from '@/hooks/useValidateURLId'
import { refreshMercadoPagoToken } from '@/services/centreMercadoPagoInfo'
import { setPageLoading } from '@/store/app/actions'
import { getCurrentcentreInsurances } from '@/store/centreInsurances/actions'
import { isLoadedCentreEntities } from '@/store/centres/actions'
import { selectedEntity, setCalendarEntities, setFilteredEntities } from '@/store/changeViewCalendar/actions'
import { getDoctorCentres } from '@/store/doctorCentres/actions'
import { getListInsurances, getListInsurancesPrice } from '@/store/insurances/actions'
import { getAllMachines } from '@/store/machines/actions'
import { fetchMercadoPagoSubscription } from '@/store/mercadoPago/actions'
import { getAllRooms } from '@/store/rooms/actions'
import { getSecretaryCentre } from '@/store/secretaryCentre/actions'
import { getAllServices } from '@/store/services/actions'
import { getSubscriptionPaymentsDebt } from '@/store/subscriptionPayments/actions'
import { getWeekDays } from '@/utils/functions/getWeekDays'
import { removeHolidays } from '@/utils/functions/removeHolidays'
import { Grid } from '@mui/material'

import dayjs from 'dayjs'
import NavBar from './components/AgendaNavBar'
import CreateNewAgenda from './components/createNewAgenda'
import CalendarBody from './components/DailyCalendar/DailyCalendarBody'
import CalendarHeader from './components/DailyCalendar/DailyCalendarHeader'
import { useCalendar } from './CalendarProvider'

import s from './styles.module.scss'

const Body = ({
  inputValue,
  inputOnChange,
  inputLabel,
  openToCreateNewAgenda,
  setOpenToCreateNewAgenda,
  setDataMercadoPagoSubscription,
  refCreateAgendaButton,
}) => {
  const dispatch = useDispatch()
  const { filteredEntityText, selectedDate } = useCalendar()
  const tour = useTour(STEPS, 'TOUR_KEY')
  const centreId = useValidateURLId()
  const { t } = useTranslation('global')

  const { doctorCentres, isLoaded: isDoctorCentresLoaded } = useSelector((state) => state.doctorCentres)
  const { detail } = useSelector((state) => state.centreMercadoPagoInfo)
  const { allMachines, isLoaded: isMachinesLoaded } = useSelector((state) => state.machines)
  const { centreProfile, isLoaded } = useSelector((state) => state.centres)
  const { dailyView, calendarEntities, filteredEntities, entitySelected } = useSelector(
    (state) => state.changeViewCalendar,
  )
  const { isLoading: loadingTurns } = useSelector((state) => state.turns)

  const setInit = async () => {
    const params = {
      centre: centreId,
    }

    dispatch(isLoadedCentreEntities(true))
    await dispatch(getDoctorCentres(centreId))
    await dispatch(getAllMachines(centreId))
    await dispatch(getAllRooms(centreId))
    await dispatch(getSecretaryCentre(centreId))
    await dispatch(getSubscriptionPaymentsDebt({ centreId: centreProfile?._id }))
    if (centreProfile?.centreConfiguration?.subscriptionPaymentType === 'mercadoPagoSubscription') {
      const subscriptionFetch = await dispatch(
        fetchMercadoPagoSubscription({
          centreId: centreProfile?._id,
          mercadoPagoPreapprovalPlanId: centreProfile?.centreConfiguration?.mercadoPagoPreapprovalPlanId,
        }),
      )

      if (subscriptionFetch) {
        setDataMercadoPagoSubscription(subscriptionFetch)
      }
    }
    await dispatch(getCurrentcentreInsurances(centreId))
    dispatch(getListInsurancesPrice(centreId))
    dispatch(getAllServices(params))
    dispatch(setPageLoading(false))
    dispatch(isLoadedCentreEntities(false))
  }

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (detail?.isExpired) {
      updateMercadoPagoToken(detail?.centre)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail])

  useEffect(() => {
    let agendaEntities = doctorCentres.concat(allMachines)
    let auxTodayEntities = viewTodayEntities(agendaEntities)?.filter((entity) => entity?.workTimes?.length > 0)

    if (auxTodayEntities !== calendarEntities) {
      dispatch(setCalendarEntities(auxTodayEntities))
    }

    if (!dailyView) {
      // Forzamos para actualizar la entidad seleccionada en vista semanal
      const auxEntity = auxTodayEntities?.filter((entity) => entity?._id === entitySelected?._id)[0]
      dispatch(selectedEntity(auxEntity))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMachines, doctorCentres, selectedDate, dailyView])

  useEffect(() => {
    if (isLoaded === false) {
      let currentDate = dayjs()
      let daysSinceCreationAccount = diffDaysSinceCreationAccount(currentDate, centreProfile?.creationDate)
      let TOTAL_DAYS = 2

      if (daysSinceCreationAccount < TOTAL_DAYS && allMachines?.length === 0) {
        setOpenToCreateNewAgenda(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded])

  useEffect(() => {
    const filteredEntities = calendarEntities?.filter((entity) =>
      (entity.lastname || entity.name)
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(filteredEntityText.toLowerCase()),
    )

    dispatch(setFilteredEntities(filteredEntities))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredEntityText, calendarEntities])

  const updateMercadoPagoToken = async (centreId) => {
    await refreshMercadoPagoToken(centreId)
  }

  const diffDaysSinceCreationAccount = (currentDate, creationDate) => {
    let current = dayjs(currentDate).format('YYYY-MM-DD')
    const creation = dayjs(creationDate).format('YYYY-MM-DD')
    const diff = dayjs(current).diff(creation, 'day')
    return diff
  }

  const generateSlotTimesPerEntity = (entity, entities, selectedDate) => {
    let selectedWeekDay = dayjs(selectedDate).get('day')
    let lastMinTime, lastMaxTime, lastTurnDuration
    let auxEntity

    entity.workTimes.forEach((workTime) => {
      let isValidUniqueWorkTime
      let workWeekDay
      let isTodayUniqueWorkTime

      if (workTime.weekDay) {
        workWeekDay = workTime.weekDay.code
      }

      if (workTime.frequency && workTime.frequency.startDay) {
        let auxUniqueDay = workTime.frequency.startDay
        auxUniqueDay = dayjs(workTime.frequency.startDay).utc().set('hour', 0).set('minutes', 0).format()

        isTodayUniqueWorkTime = dayjs(selectedDate).utc(true).isSame(auxUniqueDay, 'day')

        if (workTime.frequency.code === 2 && isTodayUniqueWorkTime) {
          //|| self.weekMode
          isValidUniqueWorkTime = true
        }
      }

      // set current iteration values
      let turnDuration = workTime.turnDuration || 30

      let minTime = dayjs(workTime.startTime).tz('America/Buenos_Aires').format('HH:mm')
      let maxTime = dayjs(workTime.finishTime).tz('America/Buenos_Aires').format('HH:mm')

      const isEntityEnabledThisDay = selectedWeekDay === workWeekDay || isValidUniqueWorkTime
      if (!isEntityEnabledThisDay) return

      if (lastTurnDuration) {
        if (lastTurnDuration < turnDuration) {
          turnDuration = lastTurnDuration
        }
      }
      // check lastMinTime to set lowest
      if (lastMinTime) {
        if (lastMinTime < minTime) {
          minTime = lastMinTime
        }
      }
      // check lastMaxTime to set highest
      if (lastMaxTime) {
        if (lastMaxTime > maxTime) {
          maxTime = lastMaxTime
        }
      }
      // save current values to compare in next iteration
      lastTurnDuration = turnDuration
      lastMinTime = minTime
      lastMaxTime = maxTime
      //Add prop to set slots times in calendar
      auxEntity = {
        ...entity,
        currentSlotTimes: {
          startTime: minTime,
          finishTime: maxTime,
          lastTurnDuration: lastTurnDuration,
        },
      }

      if (entities) {
        const indexElem = entities.findIndex((elem) => elem._id === auxEntity._id)

        if (indexElem === -1) {
          entities.push(auxEntity)
        } else {
          entities[indexElem] = auxEntity
        }
      }
    })
    return auxEntity
  }

  const viewTodayEntities = (agendaEntities) => {
    if (!dailyView) {
      // Para cada entidad tengo que generar los slot times de toda la semana, unificarlos en uno...y mandar ese nuevo
      const auxAgendas = agendaEntities?.map((entity) => {
        const weekDays = getWeekDays(selectedDate)
        const holidayDates = centreProfile?.holidays?.map((elem) => dayjs(elem).utc().format('YYYY-MM-DD'))
        const filterHolidays = removeHolidays(weekDays, holidayDates)

        let allSlotTimes = []
        filterHolidays?.forEach((day) => {
          const auxEntity = generateSlotTimesPerEntity(entity, null, day)
          if (auxEntity) {
            allSlotTimes.push(auxEntity?.currentSlotTimes)
          }
        })

        let auxSlotTimes = {
          startTime: allSlotTimes.sort((a, b) => (a?.startTime > b?.startTime ? 1 : -1))?.[0]?.startTime || '10:00',
          finishTime: allSlotTimes.sort((a, b) => (a?.finishTime < b?.finishTime ? 1 : -1))?.[0]?.finishTime || '11:00',
          lastTurnDuration:
            allSlotTimes.sort((a, b) => (a?.lastTurnDuration < b?.lastTurnDuration ? 1 : -1))?.[0]?.lastTurnDuration ||
            60,
        }

        return {
          ...entity,
          currentSlotTimes: auxSlotTimes,
        }
      })

      return auxAgendas
    }

    let auxTodayEntities = []
    agendaEntities.forEach((entity) => {
      generateSlotTimesPerEntity(entity, auxTodayEntities, selectedDate)
    })

    return auxTodayEntities
  }

  const showNoEntitiesMsg = () => {
    if (!isDoctorCentresLoaded && !isMachinesLoaded) return

    if (doctorCentres.length === 0 && allMachines.length === 0) {
      return <NoEntitiesMessage refCreateAgendaButton={refCreateAgendaButton} />
    }

    if (dailyView) {
      return <NoCalendarsForToday />
    }
  }

  return (
    <>
      <Grid container className={s.agendaContainer}>
        {(doctorCentres.length > 0 || allMachines.length > 0) && tour}

        <NavBar
          entity={filteredEntities}
          inputValue={inputValue}
          inputOnChange={inputOnChange}
          inputLabel={inputLabel}
        />

        {filteredEntities.length > 0 ? (
          dailyView ? (
            <Grid item sm={12} className={s.calendarsContainer}>
              {filteredEntities?.map((entity, i) => {
                return (
                  <Grid
                    container
                    key={entity._id}
                    data-entity={entity._id}
                    className={`${s.calendarContainer} ${filteredEntities.length === 1 ? s.bigCalendarContainer : ''}`}
                  >
                    <CalendarHeader entity={entity} selectedDate={selectedDate} />
                    <CalendarBody entity={entity} />
                  </Grid>
                )
              })}
            </Grid>
          ) : entitySelected ? (
            <Grid item sm={12} className={s.calendarContainerWeekly}>
              <Grid
                container
                key={entitySelected?._id}
                data-entity={entitySelected?._id}
                className={s.calendarContainer}
              >
                <CalendarHeader entity={entitySelected} selectedDate={selectedDate} />
                <CalendarBody entity={entitySelected} />
              </Grid>
            </Grid>
          ) : (
            <div>
              <NoCalendarsForToday />
            </div>
          )
        ) : (
          <></>
        )}

        {filteredEntities.length === 0 && showNoEntitiesMsg()}

        {loadingTurns && (
          <div className={s.loadingBox}>
            <LoaderCircle size={60} text={`Cargando ${t('turnLabel.p')}...`} />
          </div>
        )}
      </Grid>

      {openToCreateNewAgenda && <CreateNewAgenda open={openToCreateNewAgenda} setOpen={setOpenToCreateNewAgenda} />}
    </>
  )
}

export default Body
