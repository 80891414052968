import { useEffect, useRef, useState } from 'react'
import { useController, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CaluCard from '@/components/CaluCard'
import DetailServicesAndExtraServicesPrice from '@/components/DetailServicesAndExtraServicesPrice'
import { CaluFormAutoComplete } from '@/components/formComponents/customFields/CaluFormAutoComplete'
import CaluFormInput from '@/components/formComponents/customFields/CaluFormInput'
import CaluButton from '@/components/styledComponents/CaluButton'
import { getListInsurances } from '@/store/insurances/actions'
import {
  SELECTED_ONLINE_TURN_FINAL_AMOUNT,
  SELECTED_ONLINE_TURN_INSURANCE,
  SELECTED_ONLINE_TURN_PATIENT,
} from '@/store/turns'
import { getInsurancePrice } from '@/utils/functions/calculateFinalServicePrice'
import { hasRequirePayment } from '@/utils/functions/hasRequirePayment'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { Button, Grid } from '@mui/material'

import dayjs from 'dayjs'
import PopUpCardInsurance from '../../popUpCardInsurance'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false }) => {
  const { t } = useTranslation('global')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    entityData,
    turnData,
    day: selectedDay,
    centreTargetProfile,
    selectedService,
    extraServices,
    turnDetail,
    selectedInsurance,
  } = useSelector((state) => state.turns?.onLineTurns)

  const hasRequirePaymentToConfirmTurn =
    (selectedService?.requirePayment || hasRequirePayment(extraServices)) && turnDetail?.paymentLink

  const [openPopUpCardInsurance, setOpenPopUpCardInsurance] = useState(false)

  const centreInsurancesDetail = useSelector((state) => state?.centreInsurances?.centreInsurancesDetail)
  const { allInsurancePrices } = useSelector((state) => state.insurancePrices)
  const { centreTarget: centre } = useSelector((state) => state.centres)

  const { field: fieldInsurance } = useController({ name: 'insurance' })

  const country = centre?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase() || 'ARG'

  const centreInsurances =
    turnDetail?.machine && !turnDetail?.machine?.hideCentreInsurances
      ? centreInsurancesDetail
      : turnDetail?.doctorCentre && !turnDetail?.doctorCentre?.hideCentreInsurances
        ? centreInsurancesDetail
        : []

  const entitySelectedInsurances = turnDetail?.machine
    ? turnDetail?.machine?.insurances
    : turnDetail?.doctorCentre?.insurances

  const insuranceList = [
    { _id: '-', name: 'Particular' },
    ...(centreInsurances?.insurances?.length > 0 ? centreInsurances?.insurances : []),
    ...(entitySelectedInsurances && entitySelectedInsurances?.length > 0 ? entitySelectedInsurances : []),
  ]

  const uniqueInsuranceList = insuranceList.filter((insurance, index) => {
    return insuranceList.findIndex((i) => i._id === insurance._id) === index
  })

  const watchInsurance = useWatch({ name: 'insurance' })

  const handleFindInsuranceSelected = (insuranceId) => {
    let insuranceFound
    if (insuranceId === '-') {
      insuranceFound = { _id: '-', name: 'Particular' }
    } else {
      insuranceFound = uniqueInsuranceList?.find((insurance) => insurance?._id === insuranceId)
    }
    return insuranceFound
  }

  const insuranceFound = handleFindInsuranceSelected(selectedInsurance?._id || selectedInsurance)
  // Calculate total services prices
  const serviceInsurancePrice = getInsurancePrice(selectedService, watchInsurance?._id, allInsurancePrices)
  const finalMainServicePrice = serviceInsurancePrice?.price || selectedService?.price || 0
  const extraServicesPrice = extraServices?.reduce((a, b) => Number(a) + (Number(b?.price) || 0), 0)
  const totalServicesPrice = (finalMainServicePrice || 0) + extraServicesPrice
  const refSubmitButton = useRef()

  const formatSelectedDate = (day) => {
    const dayName = dayjs(day).format('dddd')
    const dayNumber = dayjs(day).format('D')
    const month = dayjs(day).format('MMMM')
    return `${dayName} ${dayNumber} de ${month}`
  }

  const handleDeletePatientCentreSelected = () => {
    dispatch(SELECTED_ONLINE_TURN_PATIENT(null))
    navigate(`/turnos/info-paciente/${entityData?.centre}`)
  }

  useEffect(() => {
    fieldInsurance.onChange(insuranceFound)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const totalAmountToPay = () => {
    if (selectedService?._id === 'firstConsultation') return entityData?.priceFirstTime || 0
    if (selectedService?._id === 'consultation') return entityData?.price || 0
    return totalServicesPrice
  }

  return (
    <Grid container className={s.bodyCard}>
      <Grid item xs={12} md={12} className={s.marginGrid}>
        <CaluFormAutoComplete
          isform={'true'}
          name="insurance"
          stateredux={uniqueInsuranceList}
          functiontodispatch={getListInsurances}
          inputlabel={`${t('insuranceLabel.S')}`}
          className={s.autoCompleteWidth}
        />
      </Grid>

      {watchInsurance?._id !== '-' && (
        <Grid item xs={12} className={s.marginGrid}>
          <CaluFormInput labelText="Número de afiliado" type="text" name="policyNumber" fullWidth />
        </Grid>
      )}
      <Grid item xs={12} md={12} className={s.marginGrid}>
        <CaluFormInput labelText="Comentarios para el centro de salud" name="comment" type="text" fullWidth />
      </Grid>
      <Grid item xs={12} className={s.containerButton}>
        <div>
          <CaluButton color="primary" inverted="true" size="large" onClick={handleDeletePatientCentreSelected}>
            Volver
          </CaluButton>
        </div>
        <CaluButton
          color="primary"
          size="large"
          loading={isLoading}
          onClick={async () => {
            await dispatch(SELECTED_ONLINE_TURN_INSURANCE(watchInsurance))
            await dispatch(SELECTED_ONLINE_TURN_FINAL_AMOUNT(totalServicesPrice))
            if (hasRequirePaymentToConfirmTurn) {
              refSubmitButton?.current?.click()
            } else {
              setOpenPopUpCardInsurance(true)
            }
            mxp.track(mxp.events.centreLanding.steps.turnDataDone)
          }}
        >
          {hasRequirePaymentToConfirmTurn ? 'Continuar' : 'Reservar'}
        </CaluButton>
      </Grid>

      <Button type="submit" hidden={true} ref={refSubmitButton}></Button>
      <PopUpCardInsurance
        open={openPopUpCardInsurance}
        setOpen={setOpenPopUpCardInsurance}
        selectedDay={formatSelectedDate(selectedDay)}
        turnData={turnData}
        entityData={entityData}
        centre={centreTargetProfile}
        refSubmitButton={refSubmitButton}
      />

      <Grid item xs={12} className={s.contCaluCard}>
        <CaluCard
          title={`Detalle total a pagar`}
          children={
            <DetailServicesAndExtraServicesPrice
              selectedService={selectedService}
              allInsurancePrices={allInsurancePrices}
              country={country}
              extraServices={extraServices}
              finalMainServicePrice={finalMainServicePrice}
              selectedInsurance={watchInsurance?._id}
              totalServicesPrice={totalAmountToPay()}
            />
          }
        />
      </Grid>
    </Grid>
  )
}
